import { LANGUAGES } from '@/constants';

export const languageOptions = [
  {
    key: LANGUAGES.DEFAULT,
    label: LANGUAGES.en.title,
  },
  {
    key: LANGUAGES.vi.value,
    label: LANGUAGES.vi.title,
  },
  {
    key: LANGUAGES.kr.value,
    label: LANGUAGES.kr.title,
  },
  {
    key: LANGUAGES.cn.value,
    label: LANGUAGES.cn.title,
  },
];

export const languageButtonOptions = { ghost: true };

export const languageDropdownOptions = { arrow: true };
