import {
  CLEAR_GET_GROUPS_OVERVIEW,
  CLEAR_GET_LIST_ALL_GROUP,
  CLEAR_GET_RETRIEVE_OF_GROUP,
  GET_GROUPS_OVERVIEW,
  GET_GROUPS_OVERVIEW_FAILED,
  GET_GROUPS_OVERVIEW_SUCCESS,
  GET_LIST_ALL_GROUP,
  GET_LIST_ALL_GROUP_FAILED,
  GET_LIST_ALL_GROUP_SUCCESS,
  GET_RETRIEVE_OF_GROUP,
  GET_RETRIEVE_OF_GROUP_FAILED,
  GET_RETRIEVE_OF_GROUP_SUCCESS,
} from './actionTypes';

export const initialState = {
  groupsOverview: {},
  getGroupsOverviewLoading: false,

  allGroups: {
    refs: [],
  },
  getListAllGroupsLoading: false,
  getRetrieveOfGroupLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // GET_RETRIEVE_OF_GROUP
    case GET_RETRIEVE_OF_GROUP:
      return {
        ...state,
        getRetrieveOfGroupLoading: true,
      };
    case GET_RETRIEVE_OF_GROUP_SUCCESS:
    case GET_RETRIEVE_OF_GROUP_FAILED:
    case CLEAR_GET_RETRIEVE_OF_GROUP:
      return {
        ...state,
        getRetrieveOfGroupLoading: false,
      };

    // GET_GROUPS_OVERVIEW
    case GET_GROUPS_OVERVIEW: {
      return {
        ...state,
        getGroupsOverviewLoading: true,
      };
    }

    case GET_GROUPS_OVERVIEW_SUCCESS: {
      return {
        ...state,
        groupsOverview: action.payload,
        getGroupsOverviewLoading: false,
      };
    }

    case GET_GROUPS_OVERVIEW_FAILED:
    case CLEAR_GET_GROUPS_OVERVIEW: {
      return {
        ...state,
        getGroupsOverviewLoading: false,
        groupsOverview: {},
      };
    }

    // GET_LIST_ALL_GROUP
    case GET_LIST_ALL_GROUP: {
      return {
        ...state,
        getListAllGroupsLoading: true,
      };
    }

    case GET_LIST_ALL_GROUP_SUCCESS: {
      const refs = [];

      (action.payload ?? []).forEach((group) => {
        refs.push(group.ref);
      });

      return {
        ...state,
        allGroups: {
          refs,
        },
        getListAllGroupsLoading: false,
      };
    }

    case GET_LIST_ALL_GROUP_FAILED:
    case CLEAR_GET_LIST_ALL_GROUP: {
      return {
        ...state,
        allGroups: {
          refs: [],
        },
        getListAllGroupsLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
