import { LANGUAGES, LOCAL_STORAGE_KEYS } from '@/constants';
import { existedVal } from '@/utils';

import { ACTIVE_VIEW_MODE_LANGUAGE, CLEAR_VIEW_MODE_LANGUAGE } from '../dataViewMode/actionTypes';

import {
  CHANGE_LANGUAGE,
  CHANGE_LANGUAGE_PENDING,
  CHANGE_LANGUAGE_SUCCESS,
  INIT_APP_SUCCESS,
  TOGGLE_DARK_MODE,
} from './actionTypes';

export const initialState = {
  isDarkMode: false,
  initApp: false,

  appLanguage: LANGUAGES.DEFAULT,
  changeLanguageLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_DARK_MODE: {
      const isDarkMode = existedVal(action.payload) ? action.payload : !state.isDarkMode;

      localStorage.setItem(
        LOCAL_STORAGE_KEYS.THEME,
        isDarkMode ? LOCAL_STORAGE_KEYS.DARK : LOCAL_STORAGE_KEYS.LIGHT,
      );

      return {
        ...state,
        isDarkMode,
      };
    }

    case INIT_APP_SUCCESS: {
      const { appLanguage } = action.payload;

      return {
        ...state,
        initApp: true,
        isDarkMode: localStorage.getItem(LOCAL_STORAGE_KEYS.THEME) === LOCAL_STORAGE_KEYS.DARK,
        appLanguage,
      };
    }

    case ACTIVE_VIEW_MODE_LANGUAGE:
      return {
        ...state,
        appLanguage: LANGUAGES.DEFAULT,
      };

    case CLEAR_VIEW_MODE_LANGUAGE: {
      const language = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_LANGUAGE);
      const appLanguage = existedVal(language) && language !== '' ? language : LANGUAGES.DEFAULT;

      return {
        ...state,
        appLanguage,
      };
    }

    case CHANGE_LANGUAGE: {
      return {
        ...state,
        changeLanguageLoading: true,
      };
    }

    case CHANGE_LANGUAGE_PENDING: {
      const appLanguage =
        existedVal(action.payload) && existedVal(LANGUAGES[action.payload])
          ? action.payload
          : LANGUAGES.DEFAULT;

      localStorage.setItem(LOCAL_STORAGE_KEYS.APP_LANGUAGE, appLanguage);

      return {
        ...state,
        appLanguage: action.payload,
      };
    }

    case CHANGE_LANGUAGE_SUCCESS: {
      return {
        ...state,
        changeLanguageLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};
