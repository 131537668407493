// GET_PLANTS_OVERVIEW
export const GET_PLANTS_OVERVIEW = 'GET_PLANTS_OVERVIEW';
export const GET_PLANTS_OVERVIEW_SUCCESS = 'GET_PLANTS_OVERVIEW_SUCCESS';
export const GET_PLANTS_OVERVIEW_FAILED = 'GET_PLANTS_OVERVIEW_FAILED';
export const CLEAR_GET_PLANTS_OVERVIEW = 'CLEAR_GET_PLANTS_OVERVIEW';

// GET_PLANT_DETAIL
export const GET_PLANT_DETAIL = 'GET_PLANT_DETAIL';
export const GET_PLANT_DETAIL_SUCCESS = 'GET_PLANT_DETAIL_SUCCESS';
export const GET_PLANT_DETAIL_FAILED = 'GET_PLANT_DETAIL_FAILED';
export const CLEAR_GET_PLANT_DETAIL = 'CLEAR_GET_PLANT_DETAIL';

// GET_LIST_OF_PLANT
export const GET_LIST_OF_PLANT = 'GET_LIST_OF_PLANT';
export const GET_LIST_OF_PLANT_SUCCESS = 'GET_LIST_OF_PLANT_SUCCESS';
export const GET_LIST_OF_PLANT_FAILED = 'GET_LIST_OF_PLANT_FAILED';
export const CLEAR_GET_LIST_OF_PLANT = 'CLEAR_GET_LIST_OF_PLANT';
