/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useMemo } from 'react';
import { Form, Input } from 'antd';
import PropTypes from 'prop-types';

import { classnames } from '@/utils';

import styles from './AppInput.module.scss';

function AppInput(props) {
  const {
    custom,
    label,
    name,
    placeholder,
    labelClass,
    inputClass,
    rules,
    labelOptions,
    inputOptions,
    disabled,
  } = props;

  const InputComponent = useMemo(() => (custom ? Input[custom] : Input), [custom]);

  return (
    <Form.Item
      label={label}
      name={name}
      rules={rules}
      className={classnames(styles.labelClass, labelClass)}
      {...labelOptions}>
      <InputComponent
        placeholder={placeholder}
        className={classnames(styles.inputClass, inputClass)}
        disabled={disabled}
        {...inputOptions}
      />
    </Form.Item>
  );
}

AppInput.propTypes = {
  custom: PropTypes.oneOf(['', 'Password', 'Search', 'TextArea']),
  label: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  placeholder: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  rules: PropTypes.instanceOf(Object),
  labelOptions: PropTypes.instanceOf(Object),
  inputOptions: PropTypes.instanceOf(Object),
  disabled: PropTypes.bool,
};

AppInput.defaultProps = {
  custom: '',
  label: '',
  name: '',
  placeholder: '',
  labelClass: '',
  inputClass: '',
  rules: null,
  labelOptions: {},
  inputOptions: {},
  disabled: false,
};

export default memo(AppInput);
