import { connect } from 'react-redux';

import SignInLayout from './SignInLayout';

const mapStateToProps = (state) => ({
  token: state.auth.token,
  initApp: state.app.initApp,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SignInLayout);
