import React, { useCallback } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DATE_QUERY_KEY, TODAY, TWELVE_MONTHS_AGO } from '@/constants';
import { t } from '@/languages';

function ColumnChartTitle({ type }) {
  const getTime = useCallback((time) => {
    const year = moment(time).format('YYYY');
    const monthFormat = moment(time).format('MMMM').toLocaleLowerCase();
    const month = t(`month.${monthFormat}`);
    return `${month}, ${year}`;
  }, []);

  switch (type) {
    case DATE_QUERY_KEY.DAYS_30:
      return <span>{t('ViewMode.chart.column_chart.month_chart')}</span>;
    case DATE_QUERY_KEY.DAYS_7:
      return <span>{t('ViewMode.chart.column_chart.week_chart')}</span>;
    case DATE_QUERY_KEY.DAYS_10:
      return <span>{t('ViewMode.chart.column_chart.ten_days_chart')}</span>;
    default:
      return (
        <span>
          {t('ViewMode.chart.column_chart.year_chart', {
            from: getTime(TWELVE_MONTHS_AGO),
            to: getTime(TODAY),
          })}
        </span>
      );
  }
}

ColumnChartTitle.propTypes = {
  type: PropTypes.string.isRequired,
};

// Ex: Make component rerender when language has changed
export default ColumnChartTitle;
