// GET_DATA_VIEW_MODE
export const GET_DATA_VIEW_MODE = 'GET_DATA_VIEW_MODE';
export const GET_DATA_VIEW_MODE_SUCCESS = 'GET_DATA_VIEW_MODE_SUCCESS';
export const GET_DATA_VIEW_MODE_FAILED = 'GET_DATA_VIEW_MODE_FAILED';
export const CLEAR_GET_DATA_VIEW_MODE = 'CLEAR_GET_DATA_VIEW_MODE';

// ACTIVE_VIEW_MODE_LANGUAGE
export const ACTIVE_VIEW_MODE_LANGUAGE = 'ACTIVE_VIEW_MODE_LANGUAGE';

// SET_VIEW_MODE_LANGUAGE
export const SET_VIEW_MODE_LANGUAGE = 'SET_VIEW_MODE_LANGUAGE';

// CLEAR_VIEW_MODE_LANGUAGE
export const CLEAR_VIEW_MODE_LANGUAGE = 'CLEAR_VIEW_MODE_LANGUAGE';

// SET_VIEW_MODE_REFS
export const SET_VIEW_MODE_REFS = 'SET_VIEW_MODE_REFS';
