import React, { memo } from 'react';
import { Button, Drawer } from 'antd';
import PropTypes from 'prop-types';

import AppButton from '@/components/app/AppButton';
// import AppDropdown from '@/components/app/AppDropdown';
import AppLanguages from '@/components/app/AppLanguages';
import AppLink from '@/components/app/AppLink';
import ViewModeLabel from '@/components/ViewModeLabel';

import { ReactComponent as CloseWithBGIcon } from '@/assets/icons/close-with-bg.svg';
import { ReactComponent as HamburgerIcon } from '@/assets/icons/hamburger.svg';
import { ReactComponent as LogOutIcon } from '@/assets/icons/log-out.svg';
import { USER_ROLES } from '@/constants';
import { t } from '@/languages';
import { classnames, emptyFn } from '@/utils';
import { useBoolean } from '@/utils/hooks';

import AppMenu from '../app/AppMenu';

import styles from './MobileNavigation.module.scss';

function MobileNavigation(props) {
  const { buttonViewModeLabel, viewModeRedirectUrl, actionSignOut, userRole } = props;

  const { value: open, setTrue: onShow, setFalse: onClose } = useBoolean(false);

  return (
    <>
      <Button type="primary" onClick={onShow} className={styles.Hamburger}>
        <HamburgerIcon />
      </Button>

      <Drawer
        className={styles.Component}
        placement="right"
        closable={false}
        onClose={onClose}
        width={320}
        open={open}
        key="right">
        <div className="d-flex justify-content-end mb-3">
          <AppButton onClick={onClose}>
            <CloseWithBGIcon />
          </AppButton>
        </div>

        {/* <AppDropdown
          label={<ViewModeLabel text={t('Layout.Header.btn.viewMode')} />}
          items={VIEW_MODE_OPTIONS}
          onClickItem={onClickViewSkyX}
          className={styles.Dropdown}
          overlayClassName={styles.ViewModeBtn}
        /> */}

        <AppLink to={viewModeRedirectUrl}>
          <AppButton className={classnames(styles.ViewSkyXBtn, 'ant-btn-primary')}>
            <ViewModeLabel text={buttonViewModeLabel} />
          </AppButton>
        </AppLink>

        <AppLanguages className="w-100 my-3" />

        <AppButton className={styles.LogOutBtn} onClick={actionSignOut}>
          {t('app.sign_out')}
          <LogOutIcon className={styles.LogOut} />
        </AppButton>

        {userRole !== USER_ROLES.CLIENT && <AppMenu />}
      </Drawer>
    </>
  );
}

MobileNavigation.propTypes = {
  actionSignOut: PropTypes.func,

  viewModeRedirectUrl: PropTypes.string,
  buttonViewModeLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),

  userRole: PropTypes.string,
};

MobileNavigation.defaultProps = {
  actionSignOut: emptyFn,

  viewModeRedirectUrl: '',
  buttonViewModeLabel: '',

  userRole: '',
};

export default memo(MobileNavigation);
