/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Carousel, Col, Form, Image, InputNumber, Layout, message, Row } from 'antd';
import PropTypes from 'prop-types';

import AppAspectRatio from '@/components/app/AppAspectRatio';
import AppButton from '@/components/app/AppButton';
import AppLoading from '@/components/app/AppLoading';

import { ReactComponent as CarIcon } from '@/assets/icons/car.svg';
import { ReactComponent as CloudIcon } from '@/assets/icons/cloud.svg';
import { ReactComponent as GasIcon } from '@/assets/icons/gas.svg';
import { ReactComponent as HomeIcon } from '@/assets/icons/home.svg';
import { ReactComponent as TreeIcon } from '@/assets/icons/tree.svg';
import images from '@/assets/images';
import { getFixedT, t } from '@/languages';
import {
  changePropsToArray as getDataPath,
  classnames,
  emptyFn,
  emptyObject,
  isHasData,
} from '@/utils';

import { formRules, initialForm, layout } from './config';

import styles from './ConfigsPage.module.scss';

const tFixed = getFixedT(null, null, 'ConfigsPage');

function ConfigsPage(props) {
  const {
    config,
    getConfigLoading,
    actionGetConfig,
    actionClearGetConfig,
    updateConfigLoading,
    actionUpdateConfig,
  } = props;

  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const rotationTime = Form.useWatch('rotationTime', form);

  const onUpdateConfigSuccess = useCallback(() => {
    messageApi.success(tFixed('messages.save_success'));
  }, [messageApi]);

  const onSubmitForm = useCallback(
    (finalValues) => {
      const values = { ...finalValues, id: config._id };
      actionUpdateConfig({ values, successCallback: onUpdateConfigSuccess });
    },
    [actionUpdateConfig, config, onUpdateConfigSuccess],
  );

  useEffect(() => {
    actionGetConfig();

    return () => {
      actionClearGetConfig();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isHasData(config)) {
      form.setFieldsValue(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config]);

  return (
    <Layout.Content className={styles.Page}>
      {contextHolder}

      <Helmet>
        <title>Other configs - SkyX’s Admin</title>
      </Helmet>

      <Form
        scrollToFirstError
        name="FormConfigSkyX"
        layout="vertical"
        className={styles.Form}
        form={form}
        onFinish={onSubmitForm}
        requiredMark={false}
        initialValues={initialForm}>
        <Row gutter={24}>
          <Col span={24} className={styles.PageHead}>
            <div className="d-flex align-items-center justify-content-between">
              <h1 className={styles.Title}>{tFixed('head.title')}</h1>

              <AppButton
                type="primary"
                className={styles.BtnSave}
                onClick={form.submit}
                disabled={getConfigLoading || updateConfigLoading}>
                {tFixed('head.save_btn')}
              </AppButton>
            </div>
          </Col>

          <Col span={24} xl={layout.form.xl}>
            <div className={styles.PageBody}>
              <div className={styles.Title}>{tFixed('body.constant.title')}</div>

              {getConfigLoading ? (
                <AppLoading className="py-4" />
              ) : (
                <div className={classnames(styles.Content, 'px-4 pt-4')}>
                  <div className="d-flex align-items-center me-0 me-md-2">
                    <TreeIcon className={classnames(styles.FieldIcon, 'd-none d-md-block')} />

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={t('symbol.kWh')}
                      name={getDataPath('treePlanted', 'kWh')}>
                      <InputNumber min={0} label={t('symbol.kWh')} className="w-100" />
                    </Form.Item>

                    <span className={styles.FieldEqualText}>=</span>

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={tFixed('body.constant.tree_planted')}
                      name={getDataPath('treePlanted', 'coefficient')}>
                      <InputNumber
                        min={0}
                        label={tFixed('body.constant.tree_planted')}
                        className="w-100"
                      />
                    </Form.Item>
                  </div>

                  <div className="d-flex align-items-center me-0 me-md-2">
                    <CloudIcon className={classnames(styles.FieldIcon, 'd-none d-md-block')} />

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={t('symbol.kWh')}
                      name={getDataPath('tC02Removed', 'kWh')}>
                      <InputNumber min={0} label={t('symbol.kWh')} className="w-100" />
                    </Form.Item>

                    <span className={styles.FieldEqualText}>=</span>

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={tFixed('body.constant.tCO2_removed')}
                      name={getDataPath('tC02Removed', 'coefficient')}>
                      <InputNumber
                        min={0}
                        label={tFixed('body.constant.tCO2_removed')}
                        className="w-100"
                      />
                    </Form.Item>
                  </div>

                  <div className="d-flex align-items-center me-0 me-md-2">
                    <CarIcon className={classnames(styles.FieldIcon, 'd-none d-md-block')} />

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={t('symbol.kWh')}
                      name={getDataPath('carsRemoved', 'kWh')}>
                      <InputNumber min={0} label={t('symbol.kWh')} className="w-100" />
                    </Form.Item>

                    <span className={styles.FieldEqualText}>=</span>

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={tFixed('body.constant.cars_removed')}
                      name={getDataPath('carsRemoved', 'coefficient')}>
                      <InputNumber
                        min={0}
                        label={tFixed('body.constant.cars_removed')}
                        className="w-100"
                      />
                    </Form.Item>
                  </div>

                  <div className="d-flex align-items-center me-0 me-md-2">
                    <GasIcon className={classnames(styles.FieldIcon, 'd-none d-md-block')} />

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={t('symbol.kWh')}
                      name={getDataPath('gas', 'kWh')}>
                      <InputNumber min={0} label={t('symbol.kWh')} className="w-100" />
                    </Form.Item>

                    <span className={styles.FieldEqualText}>=</span>

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={tFixed('body.constant.gas')}
                      name={getDataPath('gas', 'coefficient')}>
                      <InputNumber min={0} label={tFixed('body.constant.gas')} className="w-100" />
                    </Form.Item>
                  </div>

                  <div className="d-flex align-items-center me-0 me-md-2">
                    <HomeIcon className={classnames(styles.FieldIcon, 'd-none d-md-block')} />

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={t('symbol.kWh')}
                      name={getDataPath('energizedHome', 'kWh')}>
                      <InputNumber min={0} label={t('symbol.kWh')} className="w-100" />
                    </Form.Item>

                    <span className={styles.FieldEqualText}>=</span>

                    <Form.Item
                      rules={formRules.required}
                      className={styles.FieldData}
                      label={tFixed('body.constant.energized_home')}
                      name={getDataPath('energizedHome', 'coefficient')}>
                      <InputNumber
                        min={0}
                        label={tFixed('body.constant.energized_home')}
                        className="w-100"
                      />
                    </Form.Item>
                  </div>
                </div>
              )}
            </div>
          </Col>

          <Col span={24} xl={layout.form.xl} className="my-4 my-xl-0 ">
            <div className={styles.PageBody}>
              <div className={styles.Title}>{tFixed('body.rotation_time.title')}</div>

              {getConfigLoading ? (
                <AppLoading className="py-4" />
              ) : (
                <div className={classnames(styles.Slice, 'px-4 py-4')}>
                  <div className="d-md-flex justify-content-between w-100">
                    <div className={classnames(styles.Config, 'me-xl-3')}>
                      <Form.Item
                        rules={formRules.required}
                        label={tFixed('body.rotation_time.time')}
                        name="rotationTime">
                        <InputNumber
                          min={0}
                          placeholder={tFixed('body.rotation_time.time')}
                          className="w-100"
                        />
                      </Form.Item>
                    </div>

                    <div className={styles.SliceDemo}>
                      <div className={styles.Label}>{tFixed('body.rotation_time.preview')}</div>

                      <AppAspectRatio ratio={176 / 312}>
                        <Carousel
                          autoplay={isHasData(config)}
                          autoplaySpeed={(rotationTime || 0) * 1000}>
                          <Image src={images.configs.slice1} preview={false} />

                          <Image src={images.configs.slice2} preview={false} />

                          <Image src={images.configs.slice3} preview={false} />
                        </Carousel>
                      </AppAspectRatio>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </Layout.Content>
  );
}

ConfigsPage.propTypes = {
  config: PropTypes.instanceOf(Object),
  getConfigLoading: PropTypes.bool,
  actionGetConfig: PropTypes.func,
  actionClearGetConfig: PropTypes.func,

  updateConfigLoading: PropTypes.bool,
  actionUpdateConfig: PropTypes.func,
};

ConfigsPage.defaultProps = {
  config: emptyObject,
  getConfigLoading: false,
  actionGetConfig: emptyFn,
  actionClearGetConfig: emptyFn,

  updateConfigLoading: false,
  actionUpdateConfig: emptyFn,
};

export default ConfigsPage;
