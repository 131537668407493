/* eslint-disable no-param-reassign */
import { combineReducers } from 'redux';

import { LOCAL_STORAGE_KEYS } from '@/constants';
import app from '@/store/app/reducer';
import auth, { initialState as initAuth } from '@/store/auth/reducer';
import chart, { initialState as initChart } from '@/store/chart/reducer';
import config, { initialState as initConfig } from '@/store/config/reducer';
import dataViewMode, { initialState as initDataViewMode } from '@/store/dataViewMode/reducer';
import group, { initialState as initGroup } from '@/store/group/reducer';
import manager, { initialState as initManager } from '@/store/manager/reducer';
import plant, { initialState as initPlant } from '@/store/plant/reducer';
import skyx, { initialState as skyxConfig } from '@/store/skyx/reducer';
import user, { initialState as initUser } from '@/store/user/reducer';
import weather, { initialState as initWeatherConfig } from '@/store/weather/reducer';

import { SIGN_OUT } from './auth/actionTypes';

const rootReducer = combineReducers({
  auth,
  app,
  chart,
  dataViewMode,
  manager,
  group,
  plant,
  user,
  config,
  skyx,
  weather,
});

export default (state, action) => {
  if (action.type === SIGN_OUT) {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);

    state = {
      ...state,
      auth: initAuth,
      chart: initChart,
      dataViewMode: initDataViewMode,
      manager: initManager,
      plant: initPlant,
      user: initUser,
      group: initGroup,
      config: initConfig,
      skyx: skyxConfig,
      weather: initWeatherConfig,
    };
  }

  return rootReducer(state, action);
};
