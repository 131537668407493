/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useCallback, useMemo } from 'react';
import { Button, Dropdown } from 'antd';
import PropTypes from 'prop-types';

import { ReactComponent as AngleDownIcon } from '@/assets/icons/angle-down.svg';
// import { DROPDOWN_PLACEMENT } from '@/constants';
import { classnames, emptyFn } from '@/utils';

import styles from './AppDropdown.module.scss';

function AppDropdown(props) {
  const {
    isNotShowIconArrow,
    isOutline,
    isSmall,
    label,
    className,
    overlayClassName,
    trigger,
    items,
    dropdownOptions,
    buttonOptions,
    onClickItem,
    callbackArg,
  } = props;

  const onCallback = useCallback(
    (event) => onClickItem(event, callbackArg),
    [callbackArg, onClickItem],
  );

  const settings = useMemo(
    () => ({
      items,
      onClick: onCallback,
    }),
    [items, onCallback],
  );

  return (
    <Dropdown
      menu={settings}
      trigger={trigger}
      className={classnames(styles.Component, isSmall && styles.Small, className)}
      placement="bottomRight"
      openClassName={styles.Activated}
      overlayClassName={classnames(styles.Overlay, overlayClassName)}
      {...dropdownOptions}>
      <Button type={isOutline ? 'default' : 'primary'} {...buttonOptions}>
        {label}
        {!isNotShowIconArrow && <AngleDownIcon className={styles.Icon} />}
      </Button>
    </Dropdown>
  );
}

AppDropdown.propTypes = {
  isNotShowIconArrow: PropTypes.bool,
  isOutline: PropTypes.bool,
  isSmall: PropTypes.bool,
  label: PropTypes.node,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  // placement: PropTypes.oneOf(
  //   Object.keys(DROPDOWN_PLACEMENT).map((item) => DROPDOWN_PLACEMENT[item]),
  // ),
  trigger: PropTypes.oneOf(['click', 'hover']),
  items: PropTypes.instanceOf(Array),
  dropdownOptions: PropTypes.instanceOf(Object),
  buttonOptions: PropTypes.instanceOf(Object),
  onClickItem: PropTypes.func,
  callbackArg: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.func,
  ]),
};

AppDropdown.defaultProps = {
  isNotShowIconArrow: false,
  isOutline: false,
  isSmall: false,
  label: null,
  className: '',
  overlayClassName: '',
  // placement: 'bottom',
  trigger: 'click',
  items: [],
  dropdownOptions: null,
  buttonOptions: null,
  onClickItem: emptyFn,
  callbackArg: '',
};

export default memo(AppDropdown);
