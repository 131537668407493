import { connect } from 'react-redux';

import AppLogo from './AppLogo';

const mapStateToProps = () => ({
  // isDarkMode: state.app.isDarkMode,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AppLogo);
