import React, { memo, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Layout, Row } from 'antd';
import PropTypes from 'prop-types';

import CompaniesTable from '@/components/CompaniesTable';
import FormSearchCompany from '@/components/forms/FormSearchCompany';

import { USER_ROLES } from '@/constants';
import { t } from '@/languages';
import { classnames } from '@/utils';

import styles from './HomePage.module.scss';

function HomePage(props) {
  const { userRole, actionGetRetrieveCompanies, actionClearGetRetrieveCompanies } = props;

  useEffect(() => {
    actionGetRetrieveCompanies();

    return () => {
      actionClearGetRetrieveCompanies();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.Content
      className={classnames(styles.Page, userRole === USER_ROLES.MEMBER && styles.PageMember)}>
      <Helmet>
        <title>Home - SkyX’s Admin</title>
      </Helmet>

      <Row>
        <Col span={24}>
          <h1 className={styles.PageTitle}>{t('HomePage.title')}</h1>
        </Col>
      </Row>

      <Row className="flex-grow-1">
        <Col span={24} className="h-100">
          <div className={styles.Content}>
            <FormSearchCompany />

            <CompaniesTable />
          </div>
        </Col>
      </Row>
    </Layout.Content>
  );
}

HomePage.propTypes = {
  userRole: PropTypes.string,
  actionGetRetrieveCompanies: PropTypes.func.isRequired,
  actionClearGetRetrieveCompanies: PropTypes.func.isRequired,
};

HomePage.defaultProps = {
  userRole: '',
};

export default memo(HomePage);
