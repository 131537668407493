// GET_GROUPS_OVERVIEW
export const GET_GROUPS_OVERVIEW = 'GET_GROUPS_OVERVIEW';
export const GET_GROUPS_OVERVIEW_SUCCESS = 'GET_GROUPS_OVERVIEW_SUCCESS';
export const GET_GROUPS_OVERVIEW_FAILED = 'GET_GROUPS_OVERVIEW_FAILED';
export const CLEAR_GET_GROUPS_OVERVIEW = 'CLEAR_GET_GROUPS_OVERVIEW';

// GET_LIST_ALL_GROUP
export const GET_LIST_ALL_GROUP = 'GET_LIST_ALL_GROUP';
export const GET_LIST_ALL_GROUP_SUCCESS = 'GET_LIST_ALL_GROUP_SUCCESS';
export const GET_LIST_ALL_GROUP_FAILED = 'GET_LIST_ALL_GROUP_FAILED';
export const CLEAR_GET_LIST_ALL_GROUP = 'CLEAR_GET_LIST_ALL_GROUP';

// GET_RETRIEVE_OF_GROUP
export const GET_RETRIEVE_OF_GROUP = 'GET_RETRIEVE_OF_GROUP';
export const GET_RETRIEVE_OF_GROUP_SUCCESS = 'GET_RETRIEVE_OF_GROUP_SUCCESS';
export const GET_RETRIEVE_OF_GROUP_FAILED = 'GET_RETRIEVE_OF_GROUP_FAILED';
export const CLEAR_GET_RETRIEVE_OF_GROUP = 'CLEAR_GET_RETRIEVE_OF_GROUP';
