/* eslint-disable global-require */

export default {
  signInSlide1: require('./sign-in/slide-1.png'),
  signInSlide2: require('./sign-in/slide-2.png'),
  signInSlide3: require('./sign-in/slide-3.png'),
  signInSlide4: require('./sign-in/slide-4.png'),

  company1: require('./management/Image-1.png'),
  company2: require('./management/Image-2.png'),
  company3: require('./management/Image-3.png'),
  company4: require('./management/Image-4.png'),
  company5: require('./management/Image-5.png'),

  // configs page:
  configs: {
    slice1: require('./configs/slice-1.png'),
    slice2: require('./configs/slice-2.png'),
    slice3: require('./configs/slice-3.png'),
  },
};
