import React, { useCallback, useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Form, Layout, Row, Spin, Switch } from 'antd';
import PropTypes from 'prop-types';

import AppLoading from '@/components/app/AppLoading';

import { t } from '@/languages';
import { classnames, emptyFn, get, isHasData } from '@/utils';

import { layout, mapDataRender, RENDER_TYPE } from './config';

import styles from './CompanyClientView.module.scss';

function CompanyClientView(props) {
  const {
    clientCompany,
    getClientCompanyLoading,
    actionGetClientCompany,
    actionClearGetClientCompany,

    updateClientShowSkyXInfo,
    actionUpdateClientShowSkyXInfo,
  } = props;

  const dataRender = useMemo(
    () => ({
      nameEn: {
        label: t('CompanyClientViewPage.en.name'),
        value: 'en.name',
      },
      descriptionEn: {
        label: t('CompanyClientViewPage.en.description'),
        value: 'en.description',
      },
      nameVi: {
        label: t('CompanyClientViewPage.vi.name'),
        value: 'vi.name',
        isNeedDataToRender: true,
      },
      descriptionVi: {
        label: t('CompanyClientViewPage.vi.description'),
        value: 'vi.description',
        isNeedDataToRender: true,
      },
      nameKr: {
        label: t('CompanyClientViewPage.kr.name'),
        value: 'kr.name',
        isNeedDataToRender: true,
      },
      descriptionKr: {
        label: t('CompanyClientViewPage.kr.description'),
        value: 'kr.description',
        isNeedDataToRender: true,
      },
      nameCntw: {
        label: t('CompanyClientViewPage.cntw.name'),
        value: 'cn.name',
        isNeedDataToRender: true,
      },
      descriptionCntw: {
        label: t('CompanyClientViewPage.cntw.description'),
        value: 'cn.description',
        isNeedDataToRender: true,
      },
      electricityUsage: {
        label: t('CompanyClientViewPage.electricityUsage'),
        value: 'electricityUsage',
        isRender: true,
      },
      isShowContactInfo: {
        label: t('CompanyClientViewPage.showSkyxInfor'),
        value: 'isShowContactInfo',
        isRender: true,
      },
    }),
    [],
  );

  const [form] = Form.useForm();

  const onSubmit = useCallback(() => {
    actionUpdateClientShowSkyXInfo();
  }, [actionUpdateClientShowSkyXInfo]);

  const RenderByValue = useCallback(
    (type) => {
      switch (type) {
        case RENDER_TYPE.ELECTRICITY_USAGE: {
          return (
            <div className={styles.Value}>
              {get(clientCompany, type, 0)}
              {t('symbol.percent')}
            </div>
          );
        }

        case RENDER_TYPE.IS_SHOW_CONTACT_INFO: {
          return (
            <div className={styles.Value}>
              <Form name="FormToggleShowSkyXInfo" form={form}>
                <Form.Item name={type} className={styles.SwitchShowSkyXInfo}>
                  <div className="d-flex align-items-center">
                    <Switch
                      onChange={onSubmit}
                      checked={clientCompany.isShowContactInfo}
                      disabled={updateClientShowSkyXInfo}
                    />

                    {updateClientShowSkyXInfo && (
                      <Spin size="small" className="ms-3 d-flex align-items-center" />
                    )}
                  </div>
                </Form.Item>
              </Form>
            </div>
          );
        }

        default:
          return null;
      }
    },
    [clientCompany, form, onSubmit, updateClientShowSkyXInfo],
  );

  const RenderCompanyInfoItem = useCallback(
    (map, index) => {
      if (dataRender[map].isNeedDataToRender && !get(clientCompany, dataRender[map].value, false)) {
        return null;
      }

      return (
        <div className={classnames('d-md-flex', index && 'mt-3')} key={map}>
          <div className={styles.Title}>{dataRender[map].label}</div>

          {dataRender[map].isRender ? (
            RenderByValue(map)
          ) : (
            <div className={styles.Value}>{get(clientCompany, dataRender[map].value, '----')}</div>
          )}
        </div>
      );
    },
    [RenderByValue, clientCompany, dataRender],
  );

  useEffect(() => {
    actionGetClientCompany();

    return () => {
      actionClearGetClientCompany();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isHasData(clientCompany)) {
      form.setFieldsValue({
        isShowContactInfo: clientCompany.isShowContactInfo,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientCompany]);

  return (
    <Layout.Content className={styles.Page}>
      <Helmet>
        <title>Company detail - SkyX’s Admin</title>
      </Helmet>

      <Row gutter={36}>
        <Col span={24} xl={layout.xl} className={styles.PageContainer}>
          <div className={styles.PageWrapper}>
            <div className={styles.PageHead}>
              <h1 className={styles.Title}>Company information</h1>
            </div>

            <div className={styles.PageBody}>
              {getClientCompanyLoading ? <AppLoading /> : mapDataRender.map(RenderCompanyInfoItem)}
            </div>
          </div>
        </Col>
      </Row>
    </Layout.Content>
  );
}

CompanyClientView.propTypes = {
  clientCompany: PropTypes.instanceOf(Object),
  getClientCompanyLoading: PropTypes.bool,
  actionGetClientCompany: PropTypes.func,
  actionClearGetClientCompany: PropTypes.func,

  updateClientShowSkyXInfo: PropTypes.bool,
  actionUpdateClientShowSkyXInfo: PropTypes.func,
};

CompanyClientView.defaultProps = {
  clientCompany: {},
  getClientCompanyLoading: false,
  actionGetClientCompany: emptyFn,
  actionClearGetClientCompany: emptyFn,

  updateClientShowSkyXInfo: false,
  actionUpdateClientShowSkyXInfo: emptyFn,
};

export default CompanyClientView;
