import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { classnames } from '@/utils';

import styles from './AppLink.module.scss';

function AppLink(props) {
  const { children, to, className } = props;

  return (
    <Link to={to} className={classnames(className, styles.Link)}>
      {children}
    </Link>
  );
}

AppLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
};

AppLink.defaultProps = {
  className: '',
};

export default memo(AppLink);
