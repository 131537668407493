import { t } from '@/languages';

export const layout = {
  xl: {
    span: 12,
  },
};

export const RENDER_TYPE = {
  ELECTRICITY_USAGE: 'electricityUsage',
  IS_SHOW_CONTACT_INFO: 'isShowContactInfo',
};

export const dataRender = {
  nameEn: {
    label: t('CompanyClientViewPage.en.name'),
    value: 'en.name',
  },
  descriptionEn: {
    label: t('CompanyClientViewPage.en.description'),
    value: 'en.description',
  },
  nameVi: {
    label: t('CompanyClientViewPage.vi.name'),
    value: 'vi.name',
    isNeedDataToRender: true,
  },
  descriptionVi: {
    label: t('CompanyClientViewPage.vi.description'),
    value: 'vi.description',
    isNeedDataToRender: true,
  },
  nameKr: {
    label: t('CompanyClientViewPage.kr.name'),
    value: 'kr.name',
    isNeedDataToRender: true,
  },
  descriptionKr: {
    label: t('CompanyClientViewPage.kr.description'),
    value: 'kr.description',
    isNeedDataToRender: true,
  },
  nameCntw: {
    label: t('CompanyClientViewPage.cntw.name'),
    value: 'cn.name',
    isNeedDataToRender: true,
  },
  descriptionCntw: {
    label: t('CompanyClientViewPage.cntw.description'),
    value: 'cn.description',
    isNeedDataToRender: true,
  },
  electricityUsage: {
    label: t('CompanyClientViewPage.electricityUsage'),
    value: 'electricityUsage',
    isRender: true,
  },
  isShowContactInfo: {
    label: t('CompanyClientViewPage.showSkyxInfor'),
    value: 'isShowContactInfo',
    isRender: true,
  },
};

export const mapDataRender = Object.keys(dataRender);
