import { connect } from 'react-redux';

import { SIGN_OUT } from '@/store/auth/actionTypes';

import AppHeader from './AppHeader';

const mapStateToProps = (state) => ({
  // isDarkMode: state.app.isDarkMode,
  userRole: state.user.userRole,
  clientCompany: state.manager.clientCompany,
});

const mapDispatchToProps = (dispatch) => ({
  actionSignOut: () => dispatch({ type: SIGN_OUT }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
