import React, { memo } from 'react';
import PropTypes from 'prop-types';

import ModalWarning from './ModalWarning';

function ConfirmNavigation({ blocker }) {
  if (blocker.state === 'blocked') {
    return (
      <>
        <ModalWarning onClose={blocker?.reset} onSubmit={blocker?.proceed} />
        {/* <p style={{ color: 'red' }}>Blocked the last navigation to {blocker.location.pathname}</p>
        <button onClick={blocker?.proceed}>Let me through</button>
        <button onClick={blocker?.reset}>Keep me here</button> */}
      </>
    );
  }

  // if (blocker.state === 'proceeding') {
  //   return <p style={{ color: 'orange' }}>Proceeding through blocked navigation</p>;
  // }

  // return <p style={{ color: 'green' }}>Blocker is currently unblocked</p>;
}

ConfirmNavigation.propTypes = {
  blocker: PropTypes.instanceOf(Object),
};

ConfirmNavigation.defaultProps = {
  blocker: {},
};

export default memo(ConfirmNavigation);
