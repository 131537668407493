// TOGGLE_DARK_MODE
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';

// INIT_APP
export const INIT_APP = 'INIT_APP';
export const INIT_APP_SUCCESS = 'INIT_APP_SUCCESS';

// CHANGE_LANGUAGE
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_PENDING = 'CHANGE_LANGUAGE_PENDING';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
