import { cancelled, put, takeLeading } from 'redux-saga/effects';

import managerAPI from '@/apis/managerAPI';
import { watchingSagaCanceled } from '@/utils';

import {
  CLEAR_GET_CLIENT_COMPANY,
  CLEAR_GET_RETRIEVE_COMPANY_DETAIL,
  GET_CLIENT_COMPANY,
  GET_CLIENT_COMPANY_FAILED,
  GET_CLIENT_COMPANY_SUCCESS,
  GET_COMPANY_INFORMATION,
  GET_COMPANY_INFORMATION_FAILED,
  GET_COMPANY_INFORMATION_SUCCESS,
  GET_RETRIEVE_COMPANIES,
  GET_RETRIEVE_COMPANIES_FAILED,
  GET_RETRIEVE_COMPANIES_SUCCESS,
  GET_RETRIEVE_COMPANY_DETAIL,
  GET_RETRIEVE_COMPANY_DETAIL_FAILED,
  GET_RETRIEVE_COMPANY_DETAIL_SUCCESS,
  UPDATE_CLIENT_SHOW_SKY_X_INFO,
  UPDATE_CLIENT_SHOW_SKY_X_INFO_FAILED,
  UPDATE_CLIENT_SHOW_SKY_X_INFO_SUCCESS,
  UPDATE_COMPANY_INFORMATION,
  UPDATE_COMPANY_INFORMATION_FAILED,
  UPDATE_COMPANY_INFORMATION_SUCCESS,
} from './actionTypes';

function* actionGetRetrieveCompanies({ payload = {} }) {
  const { query = {} } = payload;

  try {
    const response = yield managerAPI.getRetrieveCompanies(query);

    yield put({
      type: GET_RETRIEVE_COMPANIES_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({ type: GET_RETRIEVE_COMPANIES_FAILED });
  }
}

function* actionGetRetrieveCompanyDetail({ payload = {} }) {
  const { id } = payload;

  try {
    const response = yield managerAPI.getRetrieveCompanyDetail(id);

    yield put({
      type: GET_RETRIEVE_COMPANY_DETAIL_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({ type: GET_RETRIEVE_COMPANY_DETAIL_FAILED });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_RETRIEVE_COMPANY_DETAIL });
    }
  }
}

function* actionGetCompanyInformation({ payload = {} }) {
  const { values, successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield managerAPI.getCompanyInformation(values);

    yield put({
      type: GET_COMPANY_INFORMATION_SUCCESS,
      payload: response.payload,
    });

    successCallback?.(response.payload);
  } catch (error) {
    yield put({ type: GET_COMPANY_INFORMATION_FAILED });

    errorCallback?.(error);
  } finally {
    finallyCallback?.();
  }
}

function* actionUpdateCompanyInformation({ payload = {} }) {
  const { values, successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield managerAPI.updateCompanyInformation(values);

    yield put({
      type: UPDATE_COMPANY_INFORMATION_SUCCESS,
      payload: response.payload,
    });

    successCallback?.(response.payload);
  } catch (error) {
    yield put({ type: UPDATE_COMPANY_INFORMATION_FAILED });

    errorCallback?.(error.response.data.errors);
  } finally {
    finallyCallback?.();
  }
}

function* actionGetClientCompany({ payload = {} }) {
  const { successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield managerAPI.getClientCompany();

    yield put({
      type: GET_CLIENT_COMPANY_SUCCESS,
      payload: response.payload,
    });

    successCallback?.();
  } catch (error) {
    yield put({ type: GET_CLIENT_COMPANY_FAILED });

    errorCallback?.(error);
  } finally {
    finallyCallback?.();

    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_CLIENT_COMPANY });
    }
  }
}

function* actionUpdateClientShowSkyXInfo({ payload = {} }) {
  const { successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield managerAPI.updateClientShowSkyXInfo();

    yield put({
      type: UPDATE_CLIENT_SHOW_SKY_X_INFO_SUCCESS,
      payload: response.payload,
    });

    successCallback?.();
  } catch (error) {
    yield put({ type: UPDATE_CLIENT_SHOW_SKY_X_INFO_FAILED });

    errorCallback?.(error);
  } finally {
    finallyCallback?.();
  }
}

export default function* managerSaga() {
  yield takeLeading(GET_RETRIEVE_COMPANIES, actionGetRetrieveCompanies);
  yield takeLeading(GET_RETRIEVE_COMPANY_DETAIL, function* getRetrieveCompanyDetail(payload) {
    yield watchingSagaCanceled(
      payload,
      actionGetRetrieveCompanyDetail,
      CLEAR_GET_RETRIEVE_COMPANY_DETAIL,
    );
  });

  yield takeLeading(GET_COMPANY_INFORMATION, actionGetCompanyInformation);
  yield takeLeading(UPDATE_COMPANY_INFORMATION, actionUpdateCompanyInformation);

  yield takeLeading(GET_CLIENT_COMPANY, function* getClientCompany(payload) {
    yield watchingSagaCanceled(payload, actionGetClientCompany, CLEAR_GET_CLIENT_COMPANY);
  });
  yield takeLeading(UPDATE_CLIENT_SHOW_SKY_X_INFO, actionUpdateClientShowSkyXInfo);
}
