import React, { memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';

import { ReactComponent as ConfigIcon } from '@/assets/icons/config.svg';
import { ReactComponent as ManagementIcon } from '@/assets/icons/management.svg';
import { ReactComponent as SunIcon } from '@/assets/icons/sun.svg';
import { SIDER_DIRECTION } from '@/constants';
import { t } from '@/languages';
import { classnames } from '@/utils';

import styles from './AppMenu.module.scss';

function AppMenu() {
  const location = useLocation();

  const generateMenuItem = (direction, icon, children) => {
    const { key, label, link } = direction;

    return {
      key,
      icon,
      children,
      label: (
        <Link to={link} className={styles.MenuLink}>
          <span>{t(label)}</span>
        </Link>
      ),
    };
  };

  const SIDER_ITEMS = [
    generateMenuItem(SIDER_DIRECTION.MANAGEMENT, <ManagementIcon />),
    generateMenuItem(SIDER_DIRECTION.CONFIGS, <ConfigIcon />),
    generateMenuItem(SIDER_DIRECTION.INFOR, <SunIcon />),
  ];

  return (
    <Menu
      defaultSelectedKeys={[SIDER_DIRECTION.MANAGEMENT.key]}
      className={classnames(styles.ResetBackground, styles.Menu)}
      items={SIDER_ITEMS}
      selectedKeys={[location.pathname]}
    />
  );
}

export default memo(AppMenu);
