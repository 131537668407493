import { t } from '@/languages';

export const initialForm = {
  treePlanted: {
    coefficient: 0,
    kWh: 0,
  },
  carsRemoved: {
    coefficient: 0,
    kWh: 0,
  },
  tC02Removed: {
    coefficient: 0,
    kWh: 0,
  },
  gas: {
    coefficient: 0,
    kWh: 0,
  },
  energizedHome: {
    coefficient: 0,
    kWh: 0,
  },
  beneficial: {
    coefficient: 0,
    price: 0,
  },
  rotationTime: 0,
};

export const formRules = {
  required: [
    {
      required: true,
      message: t('validate.required'),
    },
  ],
};

export const layout = {
  form: {
    xl: {
      span: 12,
    },
  },
};
