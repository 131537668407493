import {
  CLEAR_GET_LIST_OF_PLANT,
  CLEAR_GET_PLANT_DETAIL,
  CLEAR_GET_PLANTS_OVERVIEW,
  // GET_LIST_OF_PLANT
  GET_LIST_OF_PLANT,
  GET_LIST_OF_PLANT_FAILED,
  GET_LIST_OF_PLANT_SUCCESS,
  // GET_PLANT_DETAIL
  GET_PLANT_DETAIL,
  GET_PLANT_DETAIL_FAILED,
  GET_PLANT_DETAIL_SUCCESS,
  GET_PLANTS_OVERVIEW,
  GET_PLANTS_OVERVIEW_FAILED,
  GET_PLANTS_OVERVIEW_SUCCESS,
} from './actionTypes';

export const initialState = {
  getPlantsOverviewLoading: false,
  plantsOverview: {},

  getPlantDetailLoading: false,
  plantDetail: {},

  // Plants of account
  getListOfPlantLoading: true,
  listOfPlant: [],
  plants: {
    refs: [],
    capacity_dc: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANTS_OVERVIEW: {
      return {
        ...state,
        getPlantsOverviewLoading: true,
      };
    }

    case GET_PLANTS_OVERVIEW_SUCCESS: {
      return {
        ...state,
        getPlantsOverviewLoading: false,
        plantsOverview: action.payload,
      };
    }

    case GET_PLANTS_OVERVIEW_FAILED:
    case CLEAR_GET_PLANTS_OVERVIEW: {
      return {
        ...state,
        getPlantsOverviewLoading: false,
        plantsOverview: {},
      };
    }

    case GET_PLANT_DETAIL: {
      return {
        ...state,
        getPlantDetailLoading: true,
      };
    }

    case GET_PLANT_DETAIL_SUCCESS: {
      const { listOfPlant, plants } = action.payload;

      return {
        ...state,
        getPlantDetailLoading: false,
        getListOfPlantLoading: false,
        listOfPlant,
        plants: {
          ...state.plants,
          ...plants,
        },
      };
    }

    case GET_PLANT_DETAIL_FAILED:
    case CLEAR_GET_PLANT_DETAIL: {
      return {
        ...state,
        getPlantDetailLoading: false,
      };
    }

    // GET_LIST_OF_PLANT
    case GET_LIST_OF_PLANT: {
      return {
        ...state,
        getListOfPlantLoading: true,
      };
    }

    case GET_LIST_OF_PLANT_SUCCESS: {
      const { plants, listOfPlant } = action.payload;
      return {
        ...state,
        getListOfPlantLoading: false,
        listOfPlant,
        plants,
      };
    }

    case GET_LIST_OF_PLANT_FAILED:
    case CLEAR_GET_LIST_OF_PLANT: {
      return {
        ...state,
        getListOfPlantLoading: false,
        listOfPlant: [],
        plants: {
          refs: [],
          capacity_dc: null,
        },
      };
    }

    default: {
      return state;
    }
  }
};
