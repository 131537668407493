import React from 'react';
import { Helmet } from 'react-helmet';
import { Col, Layout, Row } from 'antd';

import AppLanguages from '@/components/app/AppLanguages';
import AppLogo from '@/components/app/AppLogo';
import FormSignIn from '@/components/forms/FormSignIn';

import { t } from '@/languages';
import { classnames } from '@/utils';

import Galleries from './components/Galleries';

import styles from './SignIn.module.scss';

function SignIn() {
  return (
    <Layout className={styles.Page}>
      <Helmet>
        <title>Login - SkyX’s Admin</title>
      </Helmet>

      <Layout.Content className={styles.Content}>
        <Row className="w-100">
          <Galleries />

          <Col span={24} md={{ span: 12, order: 1 }} lg={{ span: 8 }} className="mx-md-auto">
            <div className={classnames(styles.Form, 'justify-content-center')}>
              <AppLogo className={styles.Logo} />

              <div>
                <h1 className={styles.FormTitle}>{t('SignInPage.welcome')}</h1>

                <div className={styles.FormGreet}>{t('SignInPage.greet')}</div>
              </div>

              <FormSignIn />

              <div className="mb-4 mt-auto mt-md-0 mt-lg-auto">
                <AppLanguages isSmall />
              </div>

              <div className={styles.FooterText}>{t('SignInPage.footer')}</div>
            </div>
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}

export default SignIn;
