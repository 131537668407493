import React, { memo } from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';

import { ReactComponent as IconExclamationCircle } from '@/assets/icons/exclamation-circle.svg';
import { t } from '@/languages';
import { emptyFn } from '@/utils';

import styles from './ModalWarning.module.scss';

function ModalWarning({ onClose, onSubmit }) {
  return (
    <Modal
      open
      centered
      className={styles.Modal}
      closable={false}
      okText={t('modal.btn.discard')}
      onCancel={onClose}
      onOk={onSubmit}
      cancelButtonProps={{
        className: styles.Button,
      }}
      okButtonProps={{
        className: styles.Button,
      }}>
      <div className="d-flex gap-4 w-100">
        <div>
          <IconExclamationCircle />
        </div>

        <div className={styles.Content}>
          <p>{t('modal.warning.title')}</p>
          <p>{t('modal.warning.description')}</p>
        </div>
      </div>
    </Modal>
  );
}

ModalWarning.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

ModalWarning.defaultProps = {
  onClose: emptyFn,
  onSubmit: emptyFn,
};

export default memo(ModalWarning);
