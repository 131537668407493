import { connect } from 'react-redux';

import { CHANGE_LANGUAGE } from '@/store/app/actionTypes';

import AppLanguages from './AppLanguages';

const mapStateToProps = (state) => ({
  appLanguage: state.app.appLanguage,
});

const mapDispatchToProps = (dispatch) => ({
  actionChangeLanguage: (payload) => dispatch({ type: CHANGE_LANGUAGE, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppLanguages);
