import { all, fork } from 'redux-saga/effects';

import appSaga from '@/store/app/saga';
import authSaga from '@/store/auth/saga';
import chartSaga from '@/store/chart/saga';
import configSaga from '@/store/config/saga';
import dataViewModeSaga from '@/store/dataViewMode/saga';
import groupSaga from '@/store/group/saga';
import managerSaga from '@/store/manager/saga';
import plantSaga from '@/store/plant/saga';
import skyxSaga from '@/store/skyx/saga';
import userSaga from '@/store/user/saga';
import weatherSaga from '@/store/weather/saga';

export default function* rootSaga() {
  yield all([fork(appSaga)]);
  yield all([fork(authSaga)]);
  yield all([fork(chartSaga)]);
  yield all([fork(dataViewModeSaga)]);
  yield all([fork(managerSaga)]);
  yield all([fork(plantSaga)]);
  yield all([fork(userSaga)]);
  yield all([fork(groupSaga)]);
  yield all([fork(configSaga)]);
  yield all([fork(skyxSaga)]);
  yield all([fork(weatherSaga)]);
}
