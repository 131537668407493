// GET_RETRIEVE_CHART_PLANT
export const CLEAR_RETRIEVE_CHART_PLANT = 'CLEAR_RETRIEVE_CHART_PLANT';

// GET_RETRIEVE_CHART_PLANT_7_DAYS
export const GET_RETRIEVE_CHART_PLANT_7_DAYS = 'GET_RETRIEVE_CHART_PLANT_7_DAYS';
export const GET_RETRIEVE_CHART_PLANT_7_DAYS_SUCCESS = 'GET_RETRIEVE_CHART_PLANT_7_DAYS_SUCCESS';
export const GET_RETRIEVE_CHART_PLANT_7_DAYS_FAILED = 'GET_RETRIEVE_CHART_PLANT_7_DAYS_FAILED';
export const CLEAR_RETRIEVE_CHART_PLANT_7_DAYS = 'CLEAR_RETRIEVE_CHART_PLANT_7_DAYS';

// GET_RETRIEVE_CHART_PLANT_10_DAYS
export const GET_RETRIEVE_CHART_PLANT_10_DAYS = 'GET_RETRIEVE_CHART_PLANT_10_DAYS';
export const GET_RETRIEVE_CHART_PLANT_10_DAYS_SUCCESS = 'GET_RETRIEVE_CHART_PLANT_10_DAYS_SUCCESS';
export const GET_RETRIEVE_CHART_PLANT_10_DAYS_FAILED = 'GET_RETRIEVE_CHART_PLANT_10_DAYS_FAILED';
export const CLEAR_RETRIEVE_CHART_PLANT_10_DAYS = 'CLEAR_RETRIEVE_CHART_PLANT_10_DAYS';

// GET_RETRIEVE_CHART_PLANT
export const GET_RETRIEVE_CHART_PLANT_30_DAYS = 'GET_RETRIEVE_CHART_PLANT_30_DAYS';
export const GET_RETRIEVE_CHART_PLANT_30_DAYS_SUCCESS = 'GET_RETRIEVE_CHART_PLANT_30_DAYS_SUCCESS';
export const GET_RETRIEVE_CHART_PLANT_30_DAYS_FAILED = 'GET_RETRIEVE_CHART_PLANT_30_DAYS_FAILED';
export const CLEAR_RETRIEVE_CHART_PLANT_30_DAYS = 'CLEAR_RETRIEVE_CHART_PLANT_30_DAYS';

// GET_RETRIEVE_CHART_PLANT_12_MONTHS
export const GET_RETRIEVE_CHART_PLANT_12_MONTHS = 'GET_RETRIEVE_CHART_PLANT_12_MONTHS';
export const GET_RETRIEVE_CHART_PLANT_12_MONTHS_SUCCESS =
  'GET_RETRIEVE_CHART_PLANT_12_MONTHS_SUCCESS';
export const GET_RETRIEVE_CHART_PLANT_12_MONTHS_FAILED =
  'GET_RETRIEVE_CHART_PLANT_12_MONTHS_FAILED';
export const CLEAR_RETRIEVE_CHART_PLANT_12_MONTHS = 'CLEAR_RETRIEVE_CHART_PLANT_12_MONTHS';
