import {
  CLEAR_GET_SKY_X_INFORMATION,
  GET_SKY_X_INFORMATION,
  GET_SKY_X_INFORMATION_FAILED,
  GET_SKY_X_INFORMATION_SUCCESS,
  UPDATE_SKY_X_INFORMATION,
  UPDATE_SKY_X_INFORMATION_FAILED,
  UPDATE_SKY_X_INFORMATION_SUCCESS,
} from './actionTypes';

export const initialState = {
  getSkyXInformationLoading: true,
  skyXInformation: {},

  updateSkyXInformationLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // GET_SKY_X_INFORMATION
    case GET_SKY_X_INFORMATION:
      return {
        ...state,
        getSkyXInformationLoading: true,
      };
    case GET_SKY_X_INFORMATION_SUCCESS:
      return {
        ...state,
        getSkyXInformationLoading: false,
        skyXInformation: action.payload,
      };
    case CLEAR_GET_SKY_X_INFORMATION: {
      return {
        ...state,
        skyXInformation: {},
        getSkyXInformationLoading: false,
      };
    }
    case GET_SKY_X_INFORMATION_FAILED:
      return {
        ...state,
        getSkyXInformationLoading: false,
      };

    // UPDATE_SKY_X_INFORMATION
    case UPDATE_SKY_X_INFORMATION:
      return {
        ...state,
        updateSkyXInformationLoading: true,
      };
    case UPDATE_SKY_X_INFORMATION_SUCCESS:
    case UPDATE_SKY_X_INFORMATION_FAILED:
      return {
        ...state,
        updateSkyXInformationLoading: false,
      };

    default: {
      return state;
    }
  }
};
