import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LogoIcon } from '@/assets/icons/logo.svg';
import { classnames } from '@/utils';

// import { ReactComponent as LogoLightIcon } from '@/assets/icons/logo-light.svg';
import styles from './AppLogo.module.scss';

function AppLogo(props) {
  const { width, className } = props;

  // if (isDarkMode) {
  //   return <LogoLightIcon width={width} className={classnames(styles.Logo, className)} />;
  // }

  return <LogoIcon width={width} className={classnames(styles.Logo, className)} />;
}

AppLogo.propTypes = {
  width: PropTypes.number,
  // isDarkMode: PropTypes.bool,
  className: PropTypes.string,
};

AppLogo.defaultProps = {
  // isDarkMode: false,
  width: null,
  className: '',
};

export default memo(AppLogo);
