import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';

import { classnames } from '@/utils';

import styles from './AppAspectRatio.module.scss';

function AppAspectRatio(props) {
  const { ratio, className, children } = props;

  const mixedStyles = useMemo(
    () => ({
      paddingTop: `${ratio * 100}%`,
    }),
    [ratio],
  );

  return (
    <div className={classnames(styles.Component, className)} style={mixedStyles}>
      <div className={styles.Content}>{children}</div>
    </div>
  );
}

AppAspectRatio.propTypes = {
  ratio: PropTypes.number,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

AppAspectRatio.defaultProps = {
  ratio: 1,
  className: '',
};

export default memo(AppAspectRatio);
