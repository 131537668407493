export const layout = {
  row: {
    span: 24,
    xs: {
      span: 8,
    },
  },
};

export const WINDOW_SIZE = {
  width: 1440,
  height: 810,
};
