import React, { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import * as colors from '@ant-design/colors';
import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';

import CompanyClientView from '@/pages/CompanyClientView';
import CompanySettingPage from '@/pages/CompanySettingPage';
import ConfigsPage from '@/pages/ConfigsPage';
import HomePage from '@/pages/HomePage';
import SignIn from '@/pages/SignIn';
import SkyXInformationPage from '@/pages/SkyXInformationPage';
import ViewModePage from '@/pages/ViewModePage';

import DefaultLayout from '@/components/layouts/DefaultLayout';
import SignInLayout from '@/components/layouts/SignInLayout';

import { APP_COLORS, LANGUAGES, LOCATIONS, THEME_COLORS, USER_ROLES } from '@/constants';
import { classnames, emptyFn } from '@/utils';

import AppLoading from './components/app/AppLoading';

import 'antd/dist/reset.css';
import './styles/App.scss';
import styles from './App.module.scss';

const themeConfig = {
  token: {
    borderRadius: 8,
    colorPrimary: colors.blue.primary,
    colorPrimaryActive: colors.blue[7],
    ...THEME_COLORS.LIGHT,
  },
  components: {
    Menu: {
      radiusItem: 8,
    },
    Input: {
      colorIconHover: colors.blue.primary,
    },
  },
};

const ALLOW_ROLES = {
  ONLY_ADMIN: [USER_ROLES.ADMIN],
  NOT_ADMIN: [USER_ROLES.MEMBER, USER_ROLES.CLIENT],

  ONLY_MEMBER: [USER_ROLES.MEMBER],
  NOT_MEMBER: [USER_ROLES.ADMIN, USER_ROLES.CLIENT],

  ONLY_CLIENT: [USER_ROLES.CLIENT],
  NOT_CLIENT: [USER_ROLES.ADMIN, USER_ROLES.MEMBER],
};

function App(props) {
  const { actionInitApp, appLanguage, changeLanguageLoading, userRole } = props;

  const HomePageByUserRole = useMemo(() => {
    if (userRole === USER_ROLES.CLIENT) {
      return CompanyClientView;
    }

    return HomePage;
  }, [userRole]);

  const router = useMemo(
    () =>
      createBrowserRouter(
        createRoutesFromElements(
          <>
            <Route element={<DefaultLayout />}>
              <Route path={LOCATIONS.HOME} element={<HomePageByUserRole />} />
            </Route>

            {/* EX: ADMIN ONLY ROUTES */}
            <Route element={<DefaultLayout allowRoles={ALLOW_ROLES.ONLY_ADMIN} />}>
              <Route path={LOCATIONS.COMPANY_SETTING()} element={<CompanySettingPage />} />
              <Route path={LOCATIONS.CONFIGS} element={<ConfigsPage />} />
              <Route path={LOCATIONS.INFOR} element={<SkyXInformationPage />} />
            </Route>

            <Route path={LOCATIONS.VIEW_MODE}>
              <Route element={<DefaultLayout isEmptyLayout allowRoles={ALLOW_ROLES.NOT_CLIENT} />}>
                <Route element={<ViewModePage />} path={LOCATIONS.ADMIN_VIEW_MODE} />
              </Route>

              <Route element={<DefaultLayout isEmptyLayout />}>
                <Route element={<ViewModePage />} path={LOCATIONS.CLIENT_VIEW_MODE()} />
              </Route>
            </Route>

            <Route element={<SignInLayout />}>
              <Route path={LOCATIONS.SIGN_IN} element={<SignIn />} />
            </Route>

            {/* Handle Redirect */}
            <Route path="*" element={<Navigate to={LOCATIONS.HOME} />} />
          </>,
        ),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userRole],
  );

  // const appVariables = useMemo(
  //   () =>
  //     Object.keys(APP_COLORS).reduce(
  //       (previous, current) => ({ ...previous, [`--${current}`]: APP_COLORS[current] }),
  //       {},
  //     ),
  //   [],
  // );

  useEffect(() => {
    Object.keys(APP_COLORS).forEach((item) => {
      document.body.style.setProperty(`--${item}`, APP_COLORS[item]);
    });

    actionInitApp();

    if (process.env.REACT_APP_WINDOW_DIMENSIONS) {
      // eslint-disable-next-line
      alert(`width: ${window.innerWidth} \nheight: ${window.innerHeight} `);
    }
  }, []); // eslint-disable-line

  return (
    <div className={styles.App}>
      <Helmet>
        <title>SkyX’s Admin</title>
      </Helmet>

      <div className={classnames(styles.AppLoading, changeLanguageLoading && styles.Activated)}>
        <AppLoading />
      </div>

      <ConfigProvider theme={themeConfig} key={appLanguage}>
        <RouterProvider router={router} />
      </ConfigProvider>
    </div>
  );
}

App.propTypes = {
  actionInitApp: PropTypes.func,

  appLanguage: PropTypes.string,
  changeLanguageLoading: PropTypes.bool,

  userRole: PropTypes.string,
};

App.defaultProps = {
  actionInitApp: emptyFn,

  appLanguage: LANGUAGES.DEFAULT,
  changeLanguageLoading: false,

  userRole: '',
};

export default App;
