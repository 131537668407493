// GET_CONFIG
export const GET_CONFIG = 'GET_CONFIG';
export const GET_CONFIG_SUCCESS = 'GET_CONFIG_SUCCESS';
export const GET_CONFIG_FAILED = 'GET_CONFIG_FAILED';
export const CLEAR_GET_CONFIG = 'CLEAR_GET_CONFIG';

// UPDATE_CONFIG
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_CONFIG_SUCCESS = 'UPDATE_CONFIG_SUCCESS';
export const UPDATE_CONFIG_FAILED = 'UPDATE_CONFIG_FAILED';

// GET_ROTATION_TIME
export const GET_ROTATION_TIME = 'GET_ROTATION_TIME';
export const GET_ROTATION_TIME_SUCCESS = 'GET_ROTATION_TIME_SUCCESS';
export const GET_ROTATION_TIME_FAILED = 'GET_ROTATION_TIME_FAILED';
export const CLEAR_GET_ROTATION_TIME = 'CLEAR_GET_ROTATION_TIME';
