import { connect } from 'react-redux';

import CompaniesTable from './CompaniesTable';

const mapStateToProps = (state) => ({
  getRetrieveCompaniesLoading: state.manager.getRetrieveCompaniesLoading,
  retrieveCompanies: state.manager.retrieveCompanies,

  userRole: state.user.userRole,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesTable);
