import { connect } from 'react-redux';

import { TOGGLE_DARK_MODE } from '@/store/app/actionTypes';
import {
  CLEAR_RETRIEVE_CHART_PLANT,
  GET_RETRIEVE_CHART_PLANT_7_DAYS,
  GET_RETRIEVE_CHART_PLANT_10_DAYS,
  GET_RETRIEVE_CHART_PLANT_12_MONTHS,
  GET_RETRIEVE_CHART_PLANT_30_DAYS,
} from '@/store/chart/actionTypes';
import { CLEAR_GET_ROTATION_TIME, GET_ROTATION_TIME } from '@/store/config/actionTypes';
import {
  ACTIVE_VIEW_MODE_LANGUAGE,
  CLEAR_GET_DATA_VIEW_MODE,
  CLEAR_VIEW_MODE_LANGUAGE,
  GET_DATA_VIEW_MODE,
  SET_VIEW_MODE_LANGUAGE,
  SET_VIEW_MODE_REFS,
} from '@/store/dataViewMode/actionTypes';
import {
  CLEAR_GET_GROUPS_OVERVIEW,
  CLEAR_GET_LIST_ALL_GROUP,
  CLEAR_GET_RETRIEVE_OF_GROUP,
  GET_GROUPS_OVERVIEW,
  GET_LIST_ALL_GROUP,
  GET_RETRIEVE_OF_GROUP,
} from '@/store/group/actionTypes';
import {
  CLEAR_GET_CLIENT_COMPANY,
  CLEAR_GET_RETRIEVE_COMPANY_DETAIL,
  GET_CLIENT_COMPANY,
  GET_RETRIEVE_COMPANY_DETAIL,
} from '@/store/manager/actionTypes';
import {
  CLEAR_GET_LIST_OF_PLANT,
  // CLEAR_GET_PLANTS_OVERVIEW,
  // CLEAR_GET_PLANT_DETAIL,
  GET_LIST_OF_PLANT,
  GET_PLANT_DETAIL,
  // GET_PLANTS_OVERVIEW,
} from '@/store/plant/actionTypes';
import { CLEAR_GET_SKY_X_INFORMATION, GET_SKY_X_INFORMATION } from '@/store/skyx/actionTypes';
import {
  CLEAR_GET_RETRIEVE_WEATHER_BY_LOCATION,
  GET_RETRIEVE_WEATHER_BY_LOCATION,
} from '@/store/weather/actionTypes';
import { isHasData } from '@/utils';

import ViewModePage from './ViewModePage';

const mapStateToProps = (state) => ({
  skyXInformation: state.skyx.skyXInformation,
  clientCompany: state.manager.clientCompany,

  userRole: state.user.userRole,
  // isDarkMode: state.app.isDarkMode,

  videos: state.dataViewMode.videos,
  // countdown: state.dataViewMode.countdown,

  isReadyForScreen:
    !!state.config.rotationTime &&
    !!isHasData(state.dataViewMode.dataViewMode) &&
    !!isHasData(state.chart.retrieveChartPlant30Days) &&
    !!isHasData(state.chart.retrieveChartPlant12Months) &&
    !!isHasData(state.group.groupsOverview),
  // isHasData(state.dataViewMode.videos) &&
  // isHasData(state.plant.plantDetail) &&
  // isHasData(state.manager.clientCompany) &&
  // isHasData(state.plant.plants.refs),

  viewModeRefs: state.dataViewMode.refs,
  // plants: state.plant.plants,
  // allGroups: state.group.allGroups,

  viewModeLanguage: state.dataViewMode.viewModeLanguage,

  rotationTime: state.config.rotationTime,
  listOfPlant: state.plant.listOfPlant,

  retrieveChartPlant7Days: state.chart.retrieveChartPlant7Days,
  retrieveChartPlant10Days: state.chart.retrieveChartPlant10Days,

  // retrieveWeatherByLocation: state.weather.retrieveWeatherByLocation,
});

const mapDispatchToProps = (dispatch) => ({
  actionToggleDarkMode: (payload) => dispatch({ type: TOGGLE_DARK_MODE, payload }),

  actionGetDataViewMode: (payload) => dispatch({ type: GET_DATA_VIEW_MODE, payload }),
  actionClearGetDataViewMode: () => dispatch({ type: CLEAR_GET_DATA_VIEW_MODE }),

  // actionGetPlantsOverview: () => dispatch({ type: GET_PLANTS_OVERVIEW }),
  // actionClearGetPlantsOverview: () => dispatch({ type: CLEAR_GET_PLANTS_OVERVIEW }),
  actionGetGroupsOverview: (payload) => dispatch({ type: GET_GROUPS_OVERVIEW, payload }),
  actionClearGetGroupsOverview: () => dispatch({ type: CLEAR_GET_GROUPS_OVERVIEW }),

  actionGetPlantDetail: (payload) => dispatch({ type: GET_PLANT_DETAIL, payload }),
  // actionClearGetPlantDetail: () => dispatch({ type: CLEAR_GET_PLANT_DETAIL }),

  actionGetListOfPlant: () => dispatch({ type: GET_LIST_OF_PLANT }),
  actionClearGetListOfPlant: () => dispatch({ type: CLEAR_GET_LIST_OF_PLANT }),

  actionGetRetrieveChartPlant7Days: ({ values }) =>
    dispatch({
      type: GET_RETRIEVE_CHART_PLANT_7_DAYS,
      payload: { values },
    }),
  actionGetRetrieveChartPlant10Days: ({ values }) =>
    dispatch({
      type: GET_RETRIEVE_CHART_PLANT_10_DAYS,
      payload: { values },
    }),
  actionGetRetrieveChartPlant30Days: ({ values }) =>
    dispatch({
      type: GET_RETRIEVE_CHART_PLANT_30_DAYS,
      payload: { values },
    }),
  actionGetRetrieveChartPlant12Months: ({ values }) =>
    dispatch({
      type: GET_RETRIEVE_CHART_PLANT_12_MONTHS,
      payload: { values },
    }),
  actionClearRetrieveChartPlant: () => dispatch({ type: CLEAR_RETRIEVE_CHART_PLANT }),

  actionActiveViewModeLanguage: () => dispatch({ type: ACTIVE_VIEW_MODE_LANGUAGE }),
  actionSetViewModeLanguage: (payload) => dispatch({ type: SET_VIEW_MODE_LANGUAGE, payload }),
  actionClearViewModeLanguage: () => dispatch({ type: CLEAR_VIEW_MODE_LANGUAGE }),

  actionGetListAllGroup: () => dispatch({ type: GET_LIST_ALL_GROUP }),
  actionClearGetListAllGroup: () => dispatch({ type: CLEAR_GET_LIST_ALL_GROUP }),

  actionClearViewModeRefs: () => dispatch({ type: SET_VIEW_MODE_REFS, payload: [] }),

  actionGetSkyXInformation: () => dispatch({ type: GET_SKY_X_INFORMATION }),
  actionClearGetSkyXInformation: () => dispatch({ type: CLEAR_GET_SKY_X_INFORMATION }),

  actionGetClientCompany: () => dispatch({ type: GET_CLIENT_COMPANY }),
  actionClearGetClientCompany: () => dispatch({ type: CLEAR_GET_CLIENT_COMPANY }),

  actionGetRetrieveCompanyDetail: (id) =>
    dispatch({ type: GET_RETRIEVE_COMPANY_DETAIL, payload: { id } }),
  actionClearGetRetrieveCompanyDetail: () => dispatch({ type: CLEAR_GET_RETRIEVE_COMPANY_DETAIL }),

  actionGetRetrieveOfGroup: (payload) => dispatch({ type: GET_RETRIEVE_OF_GROUP, payload }),

  actionGetRotationTime: () => dispatch({ type: GET_ROTATION_TIME }),
  actionClearRotationTime: () => dispatch({ type: CLEAR_GET_ROTATION_TIME }),

  actionGetRetrieveWeatherByLocation: (payload) =>
    dispatch({ type: GET_RETRIEVE_WEATHER_BY_LOCATION, payload }),

  actionClearGetRetrieveWeatherByLocation: () =>
    dispatch({ type: CLEAR_GET_RETRIEVE_WEATHER_BY_LOCATION }),

  actionClearGetRetrieveOfGroup: () => dispatch({ type: CLEAR_GET_RETRIEVE_OF_GROUP }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewModePage);
