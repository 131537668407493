/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Layout, Row, Spin } from 'antd';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';

import AppAspectRatio from '@/components/app/AppAspectRatio';
import LiquidChart from '@/components/charts/LiquidChart';
import ProgressChart from '@/components/charts/ProgressChart';
import MapContainer from '@/components/MapContainer';

import { ReactComponent as LogoLightIcon } from '@/assets/icons/logo-light.svg';
import { ReactComponent as MoneyIcon } from '@/assets/icons/money.svg';
import { ReactComponent as ThunderIcon } from '@/assets/icons/thunder.svg';
import iconSolarPanelDark from '@/assets/images/view-mode/solar-panel-dark.json';
import iconSolarPanelLight from '@/assets/images/view-mode/solar-panel-light.json';
import iconCarJsonDark from '@/assets/images/view-mode/stroke-car-dark.json';
import iconCarJsonLight from '@/assets/images/view-mode/stroke-car-light.json';
import iconCO2JsonDark from '@/assets/images/view-mode/stroke-co2-dark.json';
import iconCO2JsonLight from '@/assets/images/view-mode/stroke-co2-light.json';
import iconTreeJsonDark from '@/assets/images/view-mode/stroke-tree-dark.json';
import iconTreeJsonLight from '@/assets/images/view-mode/stroke-tree-light.json';
import { ReactComponent as SunIcon } from '@/assets/images/view-mode/sun.svg';
import { LANGUAGES } from '@/constants';
import { t } from '@/languages';
import { classnames, emptyFn, formatNumber } from '@/utils';

import ColumnChartWrapper from '../ColumnChartWrapper';

import config from './config';

import styles from './DetailsScreen.module.scss';

const covertNumberCapacity = (number = 0) => {
  const num = Math.round(number);
  const numString = num.toString();
  const numLength = numString.length;

  if (numLength === 1) return num;

  const divisionNumToCalc = (num / 10).toString();
  const divisionNumToCalcSplit = divisionNumToCalc.split('.');
  const divisionNumToCalcRootNumber = divisionNumToCalcSplit[0]; // type = string
  const divisionNumToCalcNumber = +divisionNumToCalcSplit[1];

  switch (true) {
    case divisionNumToCalcNumber < 3:
      return Math.floor(+divisionNumToCalc) * 10;

    case divisionNumToCalcNumber >= 8:
      return Math.ceil(+divisionNumToCalc) * 10;

    default:
      return +(divisionNumToCalcRootNumber + 5);
  }
};

function DetailsScreen(props) {
  const {
    skyXInformation,
    clientCompany,

    isDarkMode,

    dataViewMode,

    groupsOverview,

    // plantDetail,

    listOfPlant,
    retrieveWeatherByLocation,
    plants,

    // auto-play slide
    isPlay,
    isPlayingSlider,
    onNextPageSlide,

    carouselIndex,

    viewModeLanguage,

    scale,
    transformScale,
  } = props;

  const params = useParams();

  const isAdminViewMode = useMemo(() => !params.slug, [params.slug]);

  const animationIcon = useMemo(() => {
    if (isDarkMode) {
      return {
        solar: iconSolarPanelDark,
        tree: iconTreeJsonDark,
        co2: iconCO2JsonDark,
        car: iconCarJsonDark,
      };
    }

    return {
      solar: iconSolarPanelLight,
      tree: iconTreeJsonLight,
      co2: iconCO2JsonLight,
      car: iconCarJsonLight,
    };
  }, [isDarkMode]);

  const RenderCapacity = useMemo(() => {
    const capacityDc = isAdminViewMode ? plants.capacity_dc : plants.capacity_dc * 1000;
    let convertCapacityDc = covertNumberCapacity(capacityDc);
    let sup = t(`ViewMode.capacity.${isAdminViewMode ? 'total_unit' : 'total_unit_client'}`);

    if (plants.capacity_dc && isAdminViewMode && convertCapacityDc < 10) {
      convertCapacityDc *= 1000;
      sup = t(`ViewMode.capacity.total_unit_client`);
    }

    return (
      <div className={styles.HeaderData}>
        {formatNumber(convertCapacityDc)}

        <sup>{sup}</sup>
      </div>
    );
  }, [isAdminViewMode, plants.capacity_dc]);

  return (
    <div className={classnames(styles.ComponentBackground, isDarkMode && styles.DarkMode)}>
      <Layout
        className={classnames(
          styles.Component,
          isDarkMode && styles.DarkMode,
          carouselIndex !== 0 && styles.Hidden,
          isAdminViewMode && styles.Admin,
          transformScale < 1 && styles.ActiveTransform,
        )}>
        <Row gutter={16 * scale} className="flex-grow-1 flex-nowrap">
          <Col span={6} className={styles.CapacityContainer}>
            <div className={styles.Capacity}>
              <div className={styles.SunBlurWrapper}>
                <div className="position-relative w-100 h-100">
                  <SunIcon className={styles.SunBlur} />
                </div>
              </div>

              <div className={styles.Header}>
                <div className={styles.HeaderImage}>
                  <AppAspectRatio ratio={202 / 336}>
                    <Lottie
                      className="w-100 h-auto d-block"
                      animationData={animationIcon.solar}
                      loop
                    />

                    <SunIcon className={styles.Sun} />
                  </AppAspectRatio>
                </div>

                <div className={styles.HeaderTitle}>
                  <div className={styles.Label}>{t('ViewMode.capacity.title')}</div>

                  {RenderCapacity}

                  <div className={styles.Label}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t('ViewMode.capacity.equal_to')}
                  </div>
                </div>
              </div>

              <div className={styles.Body}>
                <div className={styles.Item}>
                  <Lottie animationData={animationIcon.tree} className={styles.Icon} loop />

                  <div className={styles.Text}>
                    <div className={styles.Number}>{formatNumber(dataViewMode.treePlant)}</div>

                    <div className={styles.Label}>{t('ViewMode.capacity.trees_planted')}</div>
                  </div>
                </div>

                <div className={styles.Item}>
                  <Lottie animationData={animationIcon.co2} className={styles.Icon} loop />

                  <div className={styles.Text}>
                    <div className={styles.Number}>{formatNumber(dataViewMode.co2)}</div>

                    <div className={styles.Label}>{t('ViewMode.capacity.co2_removed')}</div>
                  </div>
                </div>

                <div className={styles.Item}>
                  <Lottie animationData={animationIcon.car} className={styles.Icon} loop />

                  <div className={styles.Text}>
                    <div className={styles.Number}>{formatNumber(dataViewMode.cars)}</div>

                    <div className={styles.Label}>{t('ViewMode.capacity.cars_removed')}</div>
                  </div>
                </div>
              </div>

              <div className="mt-auto">
                <div className="position-relative">
                  <LogoLightIcon className={styles.Logo} />

                  <div className={styles.LogoText}>{skyXInformation.website}</div>
                </div>
              </div>
            </div>
          </Col>

          <Col span={12} className={styles.ChartContainer}>
            <div className={styles.Chart}>
              <div className={styles.ChartWrapper}>
                <div className={styles.Data}>
                  <div className={styles.Wrapper}>
                    <div className={styles.Content}>
                      {dataViewMode.totalGreenEnergy ? (
                        <div className={styles.Item}>
                          <ThunderIcon className={styles.Icon} />

                          <div>
                            <div className={classnames(styles.TextInter, 'mb-2')}>
                              {t('ViewMode.chart.energy.title')}
                            </div>
                            <div className={styles.TextJakarta}>
                              {formatNumber(dataViewMode.totalGreenEnergy / 1000, {
                                maximumFractionDigits: 1,
                              })}
                              &nbsp;
                              <sup>{t('ViewMode.chart.energy.unit')}</sup>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                          <Spin size="large" />
                        </div>
                      )}
                    </div>

                    <div className={styles.Divider}>
                      <div className={styles.DividerLine} />
                    </div>

                    <div className={styles.Content}>
                      {dataViewMode.beneficial ? (
                        <div className={styles.Item}>
                          <MoneyIcon className={styles.Icon} />

                          <div>
                            <div className={classnames(styles.TextInter, 'mb-2')}>
                              {t('ViewMode.chart.beneficial.title')}
                            </div>
                            <div className={styles.TextJakarta}>
                              {formatNumber(dataViewMode.beneficial, {
                                maximumFractionDigits: 1,
                              })}
                              &nbsp;
                              <sup>{t('ViewMode.chart.beneficial.unit')}</sup>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="h-100 w-100 d-md-flex align-items-center justify-content-center d-none">
                          <Spin size="large" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className={styles.ChartRings}>
                  {isAdminViewMode ? (
                    <>
                      <div className={styles.ChartRing}>
                        <div className={styles.Wrapper}>
                          <div className={styles.Content}>
                            <ProgressChart
                              {...config.availabilityChart}
                              percent={groupsOverview?.availability || 0}
                              className={classnames('w-100 h-100', styles.Ring)}
                              isDarkMode={isDarkMode}
                              title={t('ViewMode.chart.availability')}
                            />
                          </div>
                        </div>
                      </div>

                      <div className={styles.ChartRing}>
                        <div className={styles.Wrapper}>
                          <div className={styles.Content}>
                            <ProgressChart
                              {...config.performanceChart}
                              percent={groupsOverview?.performanceRatio || 0}
                              className={classnames('w-100 h-100', styles.Ring)}
                              isDarkMode={isDarkMode}
                              title={t('ViewMode.chart.performance')}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={classnames(styles.ChartRing, styles.ChartLiquid)} key={scale}>
                        <div className={styles.Wrapper}>
                          <div className={styles.Content}>
                            <AppAspectRatio className={styles.Ratio}>
                              <LiquidChart
                                {...config.availabilityLiquidChart}
                                percent={(clientCompany?.electricityUsage ?? 0) / 100}
                                className={classnames('w-100 h-100')}
                                isDarkMode={isDarkMode}
                                title={t('ViewMode.chart.percentage_usage')}
                                borderWidth={10 * scale}
                              />
                            </AppAspectRatio>
                          </div>
                        </div>
                      </div>

                      <div className={classnames(styles.ChartRing, styles.ChartMultiple)}>
                        <div className={styles.Wrapper}>
                          <div className={styles.Desc}>
                            <div>
                              <p>{t('ViewMode.chart.availability')}</p>
                              <p>{`${Math.floor(groupsOverview?.availability ?? 0)}%`}</p>
                            </div>

                            <div>
                              <p className={styles.Yellow}>{t('ViewMode.chart.performance')}</p>
                              <p>{`${Math.floor(groupsOverview?.performanceRatio ?? 0)}%`}</p>
                            </div>
                          </div>

                          <div className={styles.Content}>
                            <AppAspectRatio className={styles.Ratio}>
                              <div className={styles.RingSmall}>
                                <ProgressChart
                                  {...config.performanceChart}
                                  strokeWidth={8}
                                  percent={groupsOverview?.performanceRatio || 0}
                                  className={classnames('w-100 h-100', styles.Ring)}
                                  isDarkMode={isDarkMode}
                                  isHiddenTitle
                                />
                              </div>

                              <ProgressChart
                                {...config.availabilityChart}
                                percent={groupsOverview?.availability || 0}
                                className={classnames('w-100 h-100', styles.Ring)}
                                isDarkMode={isDarkMode}
                                isHiddenTitle
                              />
                            </AppAspectRatio>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>

                <ColumnChartWrapper
                  isPlay={isPlay}
                  isAdminViewMode={isAdminViewMode}
                  isPlayingSlider={isPlayingSlider}
                  onNextPageSlide={onNextPageSlide}
                  scale={scale}
                />
              </div>
            </div>
          </Col>

          <Col span={6} className={styles.MapContainer}>
            <div className={styles.Map}>
              <div className={styles.Wrapper}>
                <MapContainer
                  isAdminViewMode={isAdminViewMode}
                  listOfPlant={listOfPlant}
                  retrieveWeatherByLocation={retrieveWeatherByLocation}
                  isDarkMode={isDarkMode}
                  viewModeLanguage={viewModeLanguage}
                  clientCompany={clientCompany}
                  scale={scale}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Layout>
    </div>
  );
}

DetailsScreen.propTypes = {
  skyXInformation: PropTypes.instanceOf(Object),
  clientCompany: PropTypes.instanceOf(Object),

  isDarkMode: PropTypes.bool,

  dataViewMode: PropTypes.instanceOf(Object),

  groupsOverview: PropTypes.instanceOf(Object),

  // plantDetail: PropTypes.instanceOf(Object),

  listOfPlant: PropTypes.instanceOf(Array),

  plants: PropTypes.instanceOf(Object),
  retrieveWeatherByLocation: PropTypes.instanceOf(Object),

  // auto-play slider
  isPlay: PropTypes.bool,
  isPlayingSlider: PropTypes.bool,
  onNextPageSlide: PropTypes.func,

  carouselIndex: PropTypes.number,

  viewModeLanguage: PropTypes.string,

  scale: PropTypes.number,
  transformScale: PropTypes.number,
};

DetailsScreen.defaultProps = {
  skyXInformation: {},
  clientCompany: {},

  isDarkMode: true,

  dataViewMode: {},

  groupsOverview: {},

  // plantDetail: {},

  listOfPlant: [],
  retrieveWeatherByLocation: {},

  plants: {},

  // auto-play slide
  isPlay: true,
  isPlayingSlider: false,
  onNextPageSlide: emptyFn,

  carouselIndex: 0,

  viewModeLanguage: LANGUAGES.DEFAULT,

  scale: 1,
  transformScale: 1,
};

export default memo(DetailsScreen);
