import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Carousel, Col, Layout, Row } from 'antd';
import PropTypes from 'prop-types';

import { ReactComponent as LogoLightIcon } from '@/assets/icons/logo-light.svg';
import { ReactComponent as RotateIcon } from '@/assets/icons/rotate.svg';
import { ReactComponent as MailDarkIcon } from '@/assets/images/view-mode/video-overlay-mail-dark.svg';
import { ReactComponent as MailLightIcon } from '@/assets/images/view-mode/video-overlay-mail-light.svg';
import { ReactComponent as PhoneDarkIcon } from '@/assets/images/view-mode/video-overlay-phone-dark.svg';
import { ReactComponent as PhoneLightIcon } from '@/assets/images/view-mode/video-overlay-phone-light.svg';
import { ReactComponent as WebDarkIcon } from '@/assets/images/view-mode/video-overlay-web-dark.svg';
import { ReactComponent as WebLightIcon } from '@/assets/images/view-mode/video-overlay-web-light.svg';
import {
  LANGUAGES,
  RETRIEVE_CHART_7_DAYS_PAYLOAD,
  RETRIEVE_CHART_10_DAYS_PAYLOAD,
  RETRIEVE_CHART_12_MONTHS_PAYLOAD,
  RETRIEVE_CHART_30_DAYS_PAYLOAD,
  THIRTY_DAYS_AGO,
  TIME_BY_MILLISECONDS,
  TODAY,
  USER_ROLES,
} from '@/constants';
import { t } from '@/languages';
import { classnames, emptyFn, isHasData } from '@/utils';
import { useBoolean, useCountdown, useWindowSize } from '@/utils/hooks';

import DetailsScreen from './components/DetailsScreen';
import OverlayToolbar from './components/OverlayToolbar';
import VideoScreen from './components/VideoScreen';
import { layout, WINDOW_SIZE } from './config';

import './ViewModePage.scss';
import styles from './ViewModePage.module.scss';

function ViewModePage(props) {
  const {
    clientCompany,
    skyXInformation,

    userRole,

    isDarkMode,
    // actionToggleDarkMode,

    actionGetDataViewMode,
    actionClearGetDataViewMode,

    // actionGetPlantsOverview,
    // actionClearGetPlantsOverview,
    actionGetGroupsOverview,
    actionClearGetGroupsOverview,

    actionGetPlantDetail,
    // actionClearGetPlantDetail,

    actionGetSkyXInformation,
    actionClearGetSkyXInformation,

    actionGetRetrieveCompanyDetail,
    actionClearGetRetrieveCompanyDetail,

    actionGetClientCompany,
    actionClearGetClientCompany,

    actionGetListOfPlant,
    actionClearGetListOfPlant,

    actionGetRetrieveOfGroup,

    retrieveChartPlant7Days,
    retrieveChartPlant10Days,
    actionGetRetrieveChartPlant7Days,
    actionGetRetrieveChartPlant10Days,
    actionGetRetrieveChartPlant30Days,
    actionGetRetrieveChartPlant12Months,
    actionClearRetrieveChartPlant,
    actionClearViewModeRefs,

    videos: adminVideos,
    // countdown,
    viewModeRefs,
    listOfPlant,

    // plants,
    // allGroups,

    isReadyForScreen: isReady,

    viewModeLanguage,
    actionActiveViewModeLanguage,
    actionSetViewModeLanguage,
    actionClearViewModeLanguage,

    rotationTime,
    actionGetRotationTime,
    actionClearRotationTime,
    // retrieveWeatherByLocation,
    actionGetRetrieveWeatherByLocation,
    actionClearGetRetrieveWeatherByLocation,

    actionClearGetRetrieveOfGroup,
  } = props;

  const params = useParams();

  const carouselRef = useRef();
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const {
    value: isPlay,
    toggle: onTogglePlay,
    setTrue: onTriggerPlayTrue,
    setFalse: onTriggerPlayFalse,
  } = useBoolean(true);

  const {
    value: isShowLayerFinal,
    setTrue: onShowLayerFinal,
    setFalse: onHideLayerFinal,
  } = useBoolean(false);

  const [count, { startCountdown, stopCountdown, resetCountdown, isCountdownRunning }] =
    useCountdown({
      countStart: rotationTime + 1,
    });

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [triggerDetailScreenReplay, setTriggerDetailScreenReplay] = useState(false);

  const isAdminViewMode = useMemo(() => !params.slug, [params.slug]);

  const pageScale = useMemo(() => {
    const scaleWidth = windowWidth / WINDOW_SIZE.width;
    const scaleHeight = windowHeight / WINDOW_SIZE.height;
    const scale = Math.min(scaleWidth, scaleHeight);
    const stylesScale = scale > 1 ? scale : 1;

    // EX: If mobile or ratio < 1, we change all scale to 1 and transform scale to correct design
    // if ratio >= 1 we * scale every item, font-size or anything to better
    return {
      styles: {
        '--scale-value': stylesScale,
        '--transform-scale-value': scale,
      },
      scale: stylesScale,
      transformScale: scale,
    };
  }, [windowWidth, windowHeight]);

  const videos = useMemo(() => {
    if (isAdminViewMode) return adminVideos;

    return clientCompany?.videos ?? [];
  }, [adminVideos, clientCompany.videos, isAdminViewMode]);

  const lengthOfCarousel = useMemo(() => videos.length + 1, [videos]);

  const refs = useMemo(() => {
    switch (true) {
      case userRole === USER_ROLES.ADMIN && isAdminViewMode:
      case userRole === USER_ROLES.MEMBER && isAdminViewMode:
        return ['groups/6373dbd2-62cf-42a7-ad06-61d41f87b3cb'];

      case userRole === USER_ROLES.ADMIN && !isAdminViewMode:
      case userRole === USER_ROLES.MEMBER && !isAdminViewMode:
        return clientCompany?.objects ?? [];

      default:
        return viewModeRefs;
    }
  }, [clientCompany?.objects, isAdminViewMode, userRole, viewModeRefs]);

  const isReadyForScreen = useMemo(() => {
    const defaultCondition =
      isReady && !!refs.length && !!listOfPlant.length && isHasData(skyXInformation);

    if (isAdminViewMode) return defaultCondition && isHasData(retrieveChartPlant10Days);

    return (
      defaultCondition && isHasData(clientCompany) && isHasData(retrieveChartPlant7Days)
      // isHasData(retrieveWeatherByLocation)
    );
  }, [
    clientCompany,
    isAdminViewMode,
    isReady,
    listOfPlant.length,
    refs.length,
    retrieveChartPlant10Days,
    retrieveChartPlant7Days,
    skyXInformation,
  ]);

  const IconGroups = useMemo(() => {
    if (isDarkMode) {
      return {
        Phone: PhoneDarkIcon,
        Mail: MailDarkIcon,
        Web: WebDarkIcon,
      };
    }

    return {
      Phone: PhoneLightIcon,
      Mail: MailLightIcon,
      Web: WebLightIcon,
    };
  }, [isDarkMode]);

  const RenderLayer = useMemo(
    () => (
      <div
        className={classnames(
          styles.FinalLayer,
          isDarkMode && styles.DarkMode,
          isShowLayerFinal && styles.Activated,
        )}>
        <div
          className={classnames(
            styles.Content,
            pageScale.transformScale < 1 && styles.ActiveTransform,
          )}>
          <LogoLightIcon className={styles.Logo} />

          <div className={styles.Title}>{skyXInformation[viewModeLanguage]?.headline}</div>

          <div className={styles.Contact}>{t('ViewMode.final_screen.contact')}</div>

          <Row className={styles.ContactGroup} gutter={16}>
            <Col span={layout.row.span} xs={layout.row.xs} className="mb-xs-0">
              <div className={styles.ContactItem}>
                <div className={styles.ContactIcon}>
                  <IconGroups.Phone />
                </div>

                <div className={styles.ContactTitle}>{skyXInformation.contactPhone ?? ''}</div>
              </div>
            </Col>

            <Col span={layout.row.span} xs={layout.row.xs} className="mb-xs-0">
              <div className={styles.ContactItem}>
                <div className={styles.ContactIcon}>
                  <IconGroups.Mail />
                </div>

                <div className={styles.ContactTitle}>{skyXInformation.email ?? ''}</div>
              </div>
            </Col>

            <Col span={layout.row.span} xs={layout.row.xs}>
              <div className={styles.ContactItem}>
                <div className={styles.ContactIcon}>
                  <IconGroups.Web />
                </div>

                <div className={styles.ContactTitle}>{skyXInformation.website ?? ''}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    ),
    [isDarkMode, isShowLayerFinal, pageScale.transformScale, skyXInformation, viewModeLanguage],
  );

  const onTriggerDetailScreenReplay = useCallback(() => {
    setTriggerDetailScreenReplay(true);
    setTimeout(() => setTriggerDetailScreenReplay(false), 100);
  }, []);

  const onNextPageSlide = useCallback(() => {
    if (carouselIndex + 1 < lengthOfCarousel) {
      carouselRef.current.next();
      setCarouselIndex(carouselIndex + 1);
    } else if (
      isAdminViewMode ||
      userRole !== USER_ROLES.CLIENT ||
      clientCompany.isShowContactInfo
    ) {
      onShowLayerFinal();

      if (isPlay) {
        startCountdown();
      }
    } else {
      // EX: Trigger isCountdownRunning turn true => false => true
      // to start again column chart
      // work in case videos: [], isShowContactInfo: false
      if (videos.length) {
        carouselRef.current.goTo(0);
        setCarouselIndex(0);

        return;
      }

      onTriggerDetailScreenReplay();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isPlay,
    carouselIndex,
    clientCompany.isShowContactInfo,
    lengthOfCarousel,
    onTriggerDetailScreenReplay,
    startCountdown,
    videos.length,
  ]);

  const onCallApiWithCompanyIdCondition = useCallback(() => {
    // Role = Admin, viewMode = Admin
    // Role = Member, viewMode = Admin
    // Role = Client, viewMode = Client
    if (
      (userRole === USER_ROLES.ADMIN && isAdminViewMode) ||
      (userRole === USER_ROLES.MEMBER && isAdminViewMode) ||
      userRole === USER_ROLES.CLIENT
    ) {
      actionGetListOfPlant();
    }

    // Role = [Admin, Member], viewMode = Client
    if (!isAdminViewMode && isHasData(clientCompany) && clientCompany.objects?.length) {
      const { objects = [] } = clientCompany;

      if (!objects.length) return;

      const splitObject = objects[0].split('/');
      const splitObjectType = splitObject[0] ?? '';
      const splitObjectId = splitObject[1] ?? '';

      const isObjectPlants = splitObjectType === 'plants';

      const onGetPlantDetail = (id) => {
        actionGetPlantDetail({ values: [...id] });
      };

      if (isObjectPlants) {
        onGetPlantDetail([splitObjectId]);
      } else {
        actionGetRetrieveOfGroup({
          successCallback: (res = []) => {
            const ids = [];

            res.forEach((plant) => {
              const { ref = '' } = plant;

              const splitRef = ref.split('/');
              const splitRefId = splitRef[1] ?? '';

              ids.push(splitRefId);
            });

            onGetPlantDetail(ids);
          },
          values: {
            id: splitObjectId,
          },
        });
      }
    }
  }, [
    actionGetListOfPlant,
    actionGetPlantDetail,
    actionGetRetrieveOfGroup,
    clientCompany,
    isAdminViewMode,
    userRole,
  ]);

  const onCallApiWithListOfPlantLengthCondition = useCallback(() => {
    if (!isAdminViewMode && listOfPlant.length) {
      let plant = {};

      listOfPlant.forEach((plantItem) => {
        if ((plantItem.capacity_dc ?? 0) > (plant.capacity_dc ?? 0)) {
          plant = plantItem;
        }
      });

      actionGetRetrieveWeatherByLocation({
        lat: plant.latitude,
        lon: plant.longitude,
        refs,
      });
    }
  }, [actionGetRetrieveWeatherByLocation, isAdminViewMode, listOfPlant, refs]);

  const onCallApiWithRefsLengthCondition = useCallback(() => {
    if (refs.length) {
      const onGetDataViewMode = (ref = '') => {
        actionGetDataViewMode({
          ref,
          values: {
            object: refs,
            indicator: ['yield.inverter'],
            from_date: '2020-10-01',
            'time-zone-align': true,
            'reply-tz': 'Asia/Ho_Chi_Minh',
            to_date: TODAY,
          },
        });
      };

      if (isAdminViewMode) {
        // viewMode: Admin
        actionGetRetrieveChartPlant10Days({
          values: {
            ...RETRIEVE_CHART_10_DAYS_PAYLOAD,
            object: refs,
          },
        });

        onGetDataViewMode();
      } else {
        // viewMode: Client
        actionGetRetrieveChartPlant7Days({
          values: {
            ...RETRIEVE_CHART_7_DAYS_PAYLOAD,
            object: refs,
            ref: params.slug,
          },
        });

        onGetDataViewMode(params.slug);
      }

      // viewMode: [Admin, Client]
      actionGetRetrieveChartPlant30Days({
        values: {
          ...RETRIEVE_CHART_30_DAYS_PAYLOAD,
          object: refs,
          // ref: params.slug,
        },
      });

      actionGetRetrieveChartPlant12Months({
        values: {
          ...RETRIEVE_CHART_12_MONTHS_PAYLOAD,
          object: refs,
          // ref: params.slug,
        },
      });

      // actionGetPlantsOverview();

      actionGetGroupsOverview({
        values: {
          object: refs,
          from_date: THIRTY_DAYS_AGO,
          'time-zone-align': true,
          'reply-tz': 'Asia/Ho_Chi_Minh',
          to_date: TODAY,
        },
      });
    }
  }, [
    actionGetDataViewMode,
    actionGetGroupsOverview,
    actionGetRetrieveChartPlant10Days,
    actionGetRetrieveChartPlant12Months,
    actionGetRetrieveChartPlant30Days,
    actionGetRetrieveChartPlant7Days,
    isAdminViewMode,
    params.slug,
    refs,
  ]);

  const onCallApiWithNoCondition = useCallback(() => {
    switch (true) {
      // Role: Client, ViewMode: Client
      case userRole === USER_ROLES.CLIENT:
        actionGetClientCompany();
        break;

      // Role: [Member, Admin], ViewMode: Client
      case !isAdminViewMode:
        actionGetRetrieveCompanyDetail(params.slug);
        break;

      default:
        // Role = Admin, viewMode = Admin
        break;
    }

    actionGetRotationTime();
    actionGetSkyXInformation();
    actionActiveViewModeLanguage();
  }, [
    actionActiveViewModeLanguage,
    actionGetClientCompany,
    actionGetRetrieveCompanyDetail,
    actionGetRotationTime,
    actionGetSkyXInformation,
    isAdminViewMode,
    params.slug,
    userRole,
  ]);

  useEffect(() => {
    if (!isShowLayerFinal || !(carouselIndex + 1 === lengthOfCarousel)) return;

    if (isCountdownRunning) {
      stopCountdown();
    } else {
      startCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlay]);

  // EX: Handle reset countdown when rotationTime update
  useEffect(() => {
    if (rotationTime) {
      resetCountdown();
    }
  }, [rotationTime]);// eslint-disable-line

  // EX: Watching isShowFinalLayer for Final Layer
  useEffect(() => {
    if (!isCountdownRunning && !count && carouselIndex + 1 === lengthOfCarousel) {
      carouselRef.current.goTo(0, true);
      onHideLayerFinal();
      setCarouselIndex(0);
      resetCountdown();

      if (!isAdminViewMode) {
        onTriggerDetailScreenReplay();
      }
    }
  }, [isCountdownRunning]);// eslint-disable-line

  useEffect(() => {
    const intervalId = setInterval(() => {
      onCallApiWithCompanyIdCondition();
      onCallApiWithListOfPlantLengthCondition();
      onCallApiWithNoCondition();
      onCallApiWithRefsLengthCondition();
    }, TIME_BY_MILLISECONDS.HOUR);

    return () => {
      clearInterval(intervalId);
    };
  }, [
    onCallApiWithCompanyIdCondition,
    onCallApiWithListOfPlantLengthCondition,
    onCallApiWithNoCondition,
    onCallApiWithRefsLengthCondition,
  ]);

  useEffect(() => {
    onCallApiWithListOfPlantLengthCondition();
  }, [listOfPlant.length]);// eslint-disable-line

  useEffect(() => {
    onCallApiWithRefsLengthCondition();
  }, [refs.length]);// eslint-disable-line

  useEffect(() => {
    onCallApiWithCompanyIdCondition();
  }, [clientCompany?._id]); // eslint-disable-line

  useEffect(
    () => {
      // Handle user click
      if (document) {
        // TODO: Update when client update requirements
        document.onkeydown = (event) => {
          const keys = [32, 13]; // Space, Enter

          if (keys.includes(event.keyCode)) {
            onTogglePlay();
          }

          return false;
        };
      }

      // Call Api
      onCallApiWithNoCondition();

      // Cleanup Component
      return () => {
        document.onkeydown = (event) => event;

        actionClearGetDataViewMode();

        actionClearGetClientCompany();
        actionClearGetRetrieveCompanyDetail();
        actionClearGetSkyXInformation();

        // actionClearGetPlantsOverview();
        actionClearGetGroupsOverview();
        // actionClearGetPlantDetail();
        actionClearRetrieveChartPlant();

        actionClearGetListOfPlant();
        actionClearViewModeLanguage();
        actionClearViewModeRefs();

        actionClearGetRetrieveWeatherByLocation();
        actionClearGetRetrieveOfGroup();

        actionClearRotationTime();
      };
    },
    [],// eslint-disable-line
  );

  return (
    <>
      <Helmet>
        <title>{isAdminViewMode ? 'SkyX' : 'Client'} viewmode - SkyX’s Admin</title>
      </Helmet>

      <div className={styles.Alert}>
        <div className={styles.RotateIcon}>
          <RotateIcon />
        </div>

        <span>{t('ViewMode.final_screen.alert')}</span>
      </div>

      <Layout
        className={classnames(styles.Page, isDarkMode && styles.DarkMode)}
        style={pageScale.styles}>
        <OverlayToolbar
          isDarkMode={isDarkMode}
          actionToggleDarkMode={emptyFn}
          viewModeLanguage={viewModeLanguage}
          actionSetViewModeLanguage={actionSetViewModeLanguage}
          isPlay={isPlay}
          onTogglePlay={onTogglePlay}
        />

        <Carousel
          className={styles.Carousel}
          dots={false}
          ref={carouselRef}
          accessibility={false}
          infinite={false}>
          <DetailsScreen
            isDarkMode={isDarkMode}
            onNextPageSlide={onNextPageSlide}
            isPlayingSlider={
              isReadyForScreen &&
              carouselIndex === 0 &&
              !triggerDetailScreenReplay &&
              !isShowLayerFinal
            }
            carouselIndex={carouselIndex}
            viewModeLanguage={viewModeLanguage}
            isPlay={isPlay}
            scale={pageScale.scale}
            transformScale={pageScale.transformScale}
          />

          {videos.map((video, index) => (
            <VideoScreen
              isDarkMode={isDarkMode}
              video={video}
              key={video}
              onVideoDone={onNextPageSlide}
              isPlayingVideo={index + 1 === carouselIndex}
              isPlay={isPlay && !isShowLayerFinal}
              onTriggerPlayTrue={onTriggerPlayTrue}
              onTriggerPlayFalse={onTriggerPlayFalse}
            />
          ))}
        </Carousel>

        {RenderLayer}
      </Layout>
    </>
  );
}

ViewModePage.propTypes = {
  clientCompany: PropTypes.instanceOf(Object),
  skyXInformation: PropTypes.instanceOf(Object),

  userRole: PropTypes.string,
  isDarkMode: PropTypes.bool,
  // actionToggleDarkMode: PropTypes.func,

  actionGetDataViewMode: PropTypes.func,
  actionClearGetDataViewMode: PropTypes.func,

  // actionGetPlantsOverview: PropTypes.func,
  // actionClearGetPlantsOverview: PropTypes.func,
  actionGetGroupsOverview: PropTypes.func,
  actionClearGetGroupsOverview: PropTypes.func,

  actionGetPlantDetail: PropTypes.func,
  // actionClearGetPlantDetail: PropTypes.func,

  actionGetListOfPlant: PropTypes.func,
  actionClearGetListOfPlant: PropTypes.func,

  actionGetSkyXInformation: PropTypes.func,
  actionClearGetSkyXInformation: PropTypes.func,

  actionGetRetrieveCompanyDetail: PropTypes.func,
  actionClearGetRetrieveCompanyDetail: PropTypes.func,

  actionGetClientCompany: PropTypes.func,
  actionClearGetClientCompany: PropTypes.func,

  actionGetRetrieveOfGroup: PropTypes.func,

  retrieveChartPlant7Days: PropTypes.instanceOf(Object),
  retrieveChartPlant10Days: PropTypes.instanceOf(Object),
  actionGetRetrieveChartPlant7Days: PropTypes.func,
  actionGetRetrieveChartPlant10Days: PropTypes.func,
  actionGetRetrieveChartPlant30Days: PropTypes.func,
  actionGetRetrieveChartPlant12Months: PropTypes.func,
  actionClearRetrieveChartPlant: PropTypes.func,

  videos: PropTypes.instanceOf(Array),
  // countdown: PropTypes.number,
  viewModeRefs: PropTypes.instanceOf(Array),
  listOfPlant: PropTypes.instanceOf(Array),
  actionClearViewModeRefs: PropTypes.func,

  // plants: PropTypes.instanceOf(Object),
  // allGroups: PropTypes.instanceOf(Object),

  isReadyForScreen: PropTypes.bool,

  viewModeLanguage: PropTypes.string,
  actionActiveViewModeLanguage: PropTypes.func,
  actionSetViewModeLanguage: PropTypes.func,
  actionClearViewModeLanguage: PropTypes.func,

  rotationTime: PropTypes.number,
  actionGetRotationTime: PropTypes.func,
  actionClearRotationTime: PropTypes.func,

  // retrieveWeatherByLocation: PropTypes.instanceOf(Object),
  actionGetRetrieveWeatherByLocation: PropTypes.func,
  actionClearGetRetrieveWeatherByLocation: PropTypes.func,

  actionClearGetRetrieveOfGroup: PropTypes.func,
};

ViewModePage.defaultProps = {
  clientCompany: {},
  skyXInformation: {},

  userRole: '',
  isDarkMode: true,
  // actionToggleDarkMode: emptyFn,

  actionGetDataViewMode: emptyFn,
  actionClearGetDataViewMode: emptyFn,

  // actionGetPlantsOverview: emptyFn,
  // actionClearGetPlantsOverview: emptyFn,
  actionGetGroupsOverview: emptyFn,
  actionClearGetGroupsOverview: emptyFn,

  actionGetPlantDetail: emptyFn,
  // actionClearGetPlantDetail: emptyFn,

  actionGetListOfPlant: emptyFn,
  actionClearGetListOfPlant: emptyFn,

  actionGetSkyXInformation: emptyFn,
  actionClearGetSkyXInformation: emptyFn,

  actionGetRetrieveCompanyDetail: emptyFn,
  actionClearGetRetrieveCompanyDetail: emptyFn,

  actionGetClientCompany: emptyFn,
  actionClearGetClientCompany: emptyFn,

  actionGetRetrieveOfGroup: emptyFn,

  retrieveChartPlant7Days: {},
  retrieveChartPlant10Days: {},
  actionGetRetrieveChartPlant7Days: emptyFn,
  actionGetRetrieveChartPlant10Days: emptyFn,
  actionGetRetrieveChartPlant30Days: emptyFn,
  actionGetRetrieveChartPlant12Months: emptyFn,
  actionClearRetrieveChartPlant: emptyFn,
  actionClearViewModeRefs: emptyFn,

  videos: [],
  // countdown: 0,
  viewModeRefs: [],
  listOfPlant: [],
  // plants: {},
  // allGroups: {},

  isReadyForScreen: false,

  viewModeLanguage: LANGUAGES.DEFAULT,
  actionActiveViewModeLanguage: emptyFn,
  actionSetViewModeLanguage: emptyFn,
  actionClearViewModeLanguage: emptyFn,

  rotationTime: 0,
  actionGetRotationTime: emptyFn,
  actionClearRotationTime: emptyFn,

  // retrieveWeatherByLocation: {},
  actionGetRetrieveWeatherByLocation: emptyFn,
  actionClearGetRetrieveWeatherByLocation: emptyFn,

  actionClearGetRetrieveOfGroup: emptyFn,
};

export default ViewModePage;
