import { GET_USER_INFO, GET_USER_INFO_FAILED, GET_USER_INFO_SUCCESS } from './actionTypes';

export const initialState = {
  getUserInfoLoading: false,
  userInfo: null,
  userRole: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_INFO: {
      return {
        ...state,
        getUserInfoLoading: true,
      };
    }

    case GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        getUserInfoLoading: false,
        userInfo: action.payload,
        userRole: action.payload.userRole,
      };
    }

    case GET_USER_INFO_FAILED: {
      return {
        ...state,
        getUserInfoLoading: false,
        userInfo: null,
      };
    }

    default: {
      return state;
    }
  }
};
