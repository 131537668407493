import { call, put, takeLeading } from 'redux-saga/effects';

import authAPI from '@/apis/authAPI';
import { LOCAL_STORAGE_KEYS } from '@/constants';
import { actionGetUserInfo } from '@/store/user/saga';
import { setAppAccessToken } from '@/utils/axios';

import { SIGN_IN, SIGN_IN_FAILED, SIGN_IN_SUCCESS } from './actionTypes';

function* actionSignIn({ payload = {} }) {
  const { values, successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield authAPI.signIn(values);

    localStorage.setItem(LOCAL_STORAGE_KEYS.TOKEN, response.payload);

    setAppAccessToken(response.payload);

    yield call(actionGetUserInfo, {});

    yield put({
      type: SIGN_IN_SUCCESS,
      payload: response.payload,
    });

    successCallback?.();
  } catch (error) {
    yield put({
      type: SIGN_IN_FAILED,
    });

    errorCallback?.();
  } finally {
    finallyCallback?.();
  }
}

export default function* authSaga() {
  yield takeLeading(SIGN_IN, actionSignIn);
}
