// GET_RETRIEVE_COMPANIES
export const GET_RETRIEVE_COMPANIES = 'GET_RETRIEVE_COMPANIES';
export const GET_RETRIEVE_COMPANIES_SUCCESS = 'GET_RETRIEVE_COMPANIES_SUCCESS';
export const GET_RETRIEVE_COMPANIES_FAILED = 'GET_RETRIEVE_COMPANIES_FAILED';
export const CLEAR_GET_RETRIEVE_COMPANIES = 'CLEAR_GET_RETRIEVE_COMPANIES';

// GET_COMPANY_INFORMATION
export const GET_COMPANY_INFORMATION = 'GET_COMPANY_INFORMATION';
export const GET_COMPANY_INFORMATION_SUCCESS = 'GET_COMPANY_INFORMATION_SUCCESS';
export const GET_COMPANY_INFORMATION_FAILED = 'GET_COMPANY_INFORMATION_FAILED';
export const CLEAR_GET_COMPANY_INFORMATION = 'CLEAR_GET_COMPANY_INFORMATION';

// UPDATE_COMPANY_INFORMATION
export const UPDATE_COMPANY_INFORMATION = 'UPDATE_COMPANY_INFORMATION';
export const UPDATE_COMPANY_INFORMATION_SUCCESS = 'UPDATE_COMPANY_INFORMATION_SUCCESS';
export const UPDATE_COMPANY_INFORMATION_FAILED = 'UPDATE_COMPANY_INFORMATION_FAILED';

// GET_CLIENT_COMPANY
export const GET_CLIENT_COMPANY = 'GET_CLIENT_COMPANY';
export const GET_CLIENT_COMPANY_SUCCESS = 'GET_CLIENT_COMPANY_SUCCESS';
export const GET_CLIENT_COMPANY_FAILED = 'GET_CLIENT_COMPANY_FAILED';
export const CLEAR_GET_CLIENT_COMPANY = 'CLEAR_GET_CLIENT_COMPANY';

// UPDATE_CLIENT_SHOW_SKY_X_INFO
export const UPDATE_CLIENT_SHOW_SKY_X_INFO = 'UPDATE_CLIENT_SHOW_SKY_X_INFO';
export const UPDATE_CLIENT_SHOW_SKY_X_INFO_SUCCESS = 'UPDATE_CLIENT_SHOW_SKY_X_INFO_SUCCESS';
export const UPDATE_CLIENT_SHOW_SKY_X_INFO_FAILED = 'UPDATE_CLIENT_SHOW_SKY_X_INFO_FAILED';

// GET_RETRIEVE_COMPANY_DETAIL
export const GET_RETRIEVE_COMPANY_DETAIL = 'GET_RETRIEVE_COMPANY_DETAIL';
export const GET_RETRIEVE_COMPANY_DETAIL_SUCCESS = 'GET_RETRIEVE_COMPANY_DETAIL_SUCCESS';
export const GET_RETRIEVE_COMPANY_DETAIL_FAILED = 'GET_RETRIEVE_COMPANY_DETAIL_FAILED';
export const CLEAR_GET_RETRIEVE_COMPANY_DETAIL = 'CLEAR_GET_RETRIEVE_COMPANY_DETAIL';

// GET_RETRIEVE_PLANTS_DETAIL
export const GET_RETRIEVE_PLANTS_DETAIL = 'GET_RETRIEVE_PLANTS_DETAIL';
export const GET_RETRIEVE_PLANTS_DETAIL_SUCCESS = 'GET_RETRIEVE_PLANTS_DETAIL_SUCCESS';
export const GET_RETRIEVE_PLANTS_DETAIL_FAILED = 'GET_RETRIEVE_PLANTS_DETAIL_FAILED';
export const CLEAR_GET_RETRIEVE_PLANTS_DETAIL = 'CLEAR_GET_RETRIEVE_PLANTS_DETAIL';
