import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as ExpandIcon } from '@/assets/icons/expand.svg';

import styles from './ViewModeLabel.module.scss';

function ViewModeLabel(props) {
  const { text } = props;

  return (
    <div className={styles.ViewSkyX}>
      <ExpandIcon />
      <span>{text}</span>
    </div>
  );
}

ViewModeLabel.propTypes = {
  text: PropTypes.string,
};

ViewModeLabel.defaultProps = {
  text: '',
};

export default memo(ViewModeLabel);
