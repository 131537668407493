import axiosClient from '@/utils/axios';

const endPoint = '/user';

export default {
  async getUserInfo() {
    const response = await axiosClient.get(endPoint);

    return response.data;
  },

  async getUserPreferences() {
    const path = `${endPoint}/preferences`;

    const response = await axiosClient.get(path);

    return response.data;
  },
};
