import axiosClient from '@/utils/axios';

const endPoint = '/config';

export default {
  async getConfig() {
    const response = await axiosClient.get(endPoint);

    return response.data;
  },

  async updateConfig(payload) {
    const { id, ...values } = payload;
    const path = `${endPoint}/${id}`;

    const response = await axiosClient.put(path, values);

    return response.data;
  },

  async getRotationTime() {
    const response = await axiosClient.get(`${endPoint}/rotation-time`);

    return response.data;
  },
};
