import axiosClient from '@/utils/axios';

const endPoint = '/data';

export default {
  async getDataViewMode({ values, ref }) {
    const path = `${endPoint}?envelope=true&ref=${ref}`;

    const response = await axiosClient.post(path, { ...values });

    return response.data;
  },
};
