import React, { memo, useCallback } from 'react';
import { Progress } from 'antd';
import PropTypes from 'prop-types';

import { classnames } from '@/utils';

import styles from './ProgressChart.module.scss';

function ProgressChart(props) {
  const {
    className,
    percent,
    type,
    strokeColor,
    trailColor,
    strokeWidth,
    title,
    isDarkMode,
    isHiddenTitle,
  } = props;

  const RenderTitle = useCallback(
    (value) => {
      if (isHiddenTitle) return '';

      return (
        <div>
          <div className={styles.Title}>{title}</div>

          <div className={styles.Value}>{Math.floor(value)}%</div>
        </div>
      );
    },
    [isHiddenTitle, title],
  );

  return (
    <Progress
      className={classnames(styles.Chart, className, isDarkMode && styles.DarkMode)}
      type={type}
      percent={percent}
      strokeColor={strokeColor}
      trailColor={trailColor}
      strokeWidth={strokeWidth}
      format={RenderTitle}
    />
  );
}

ProgressChart.propTypes = {
  className: PropTypes.string,
  percent: PropTypes.number,
  type: PropTypes.oneOf('line', 'circle', 'dashboard'),
  strokeColor: PropTypes.string,
  trailColor: PropTypes.string,
  strokeWidth: PropTypes.number,
  title: PropTypes.string,
  isDarkMode: PropTypes.bool,
  isHiddenTitle: PropTypes.bool,
};

ProgressChart.defaultProps = {
  className: '',
  percent: 0,
  type: 'line',
  strokeColor: null,
  trailColor: null,
  strokeWidth: null,
  title: null,
  isDarkMode: true,
  isHiddenTitle: false,
};

export default memo(ProgressChart);
