import { cancelled, put, takeLeading } from 'redux-saga/effects';

import groupsAPI from '@/apis/groupAPI';
import { watchingSagaCanceled } from '@/utils';

import {
  CLEAR_GET_GROUPS_OVERVIEW,
  CLEAR_GET_LIST_ALL_GROUP,
  CLEAR_GET_RETRIEVE_OF_GROUP,
  GET_GROUPS_OVERVIEW,
  GET_GROUPS_OVERVIEW_FAILED,
  GET_GROUPS_OVERVIEW_SUCCESS,
  GET_LIST_ALL_GROUP,
  GET_LIST_ALL_GROUP_FAILED,
  GET_LIST_ALL_GROUP_SUCCESS,
  GET_RETRIEVE_OF_GROUP,
  GET_RETRIEVE_OF_GROUP_FAILED,
  GET_RETRIEVE_OF_GROUP_SUCCESS,
} from './actionTypes';

function* actionGetGroupsOverview({ payload }) {
  const { values, successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield groupsAPI.getGroupsOverview(values);

    yield put({ type: GET_GROUPS_OVERVIEW_SUCCESS, payload: response.payload });
    successCallback?.(response);
  } catch (error) {
    errorCallback?.(error);

    yield put({ type: GET_GROUPS_OVERVIEW_FAILED });
  } finally {
    finallyCallback?.();

    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_GROUPS_OVERVIEW });
    }
  }
}

export function* actionGetListAllGroup({ payload = {} }) {
  const { errorCallback, finallyCallback } = payload;

  try {
    const response = yield groupsAPI.getListAllGroup();

    yield put({ type: GET_LIST_ALL_GROUP_SUCCESS, payload: response.payload });
  } catch (error) {
    errorCallback?.(error);

    yield put({ type: GET_LIST_ALL_GROUP_FAILED });
  } finally {
    finallyCallback?.();

    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_LIST_ALL_GROUP });
    }
  }
}

export function* actionGetRetrieveOfGroup({ payload = {} }) {
  const { successCallback, values } = payload;

  try {
    const response = yield groupsAPI.getRetrieveOfGroup(values.id);

    yield put({ type: GET_RETRIEVE_OF_GROUP_SUCCESS, payload: response.payload });

    successCallback?.(response.payload);
  } catch (error) {
    yield put({ type: GET_RETRIEVE_OF_GROUP_FAILED });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_RETRIEVE_OF_GROUP });
    }
  }
}

export default function* groupSaga() {
  yield takeLeading(GET_GROUPS_OVERVIEW, function* getGroupsOverview(payload) {
    yield watchingSagaCanceled(payload, actionGetGroupsOverview, CLEAR_GET_GROUPS_OVERVIEW);
  });
  yield takeLeading(GET_LIST_ALL_GROUP, function* getListAllGroup(payload) {
    yield watchingSagaCanceled(payload, actionGetListAllGroup, CLEAR_GET_LIST_ALL_GROUP);
  });
  yield takeLeading(GET_RETRIEVE_OF_GROUP, function* getRetrieveOfGroup(payload) {
    yield watchingSagaCanceled(payload, actionGetRetrieveOfGroup, CLEAR_GET_RETRIEVE_OF_GROUP);
  });
}
