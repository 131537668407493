import React, { memo, useCallback } from 'react';
import { Liquid } from '@ant-design/plots';
import PropTypes from 'prop-types';

import { classnames } from '@/utils';

import styles from './LiquidChart.module.scss';

function LiquidChart(props) {
  const {
    isDarkMode,
    className,
    percent,
    strokeColor,
    fillColor,
    title = 'Percentage usage',
    borderWidth,
  } = props;

  const RenderTitle = useCallback(
    (value) => (
      <div>
        <div className={styles.Title}>{title}</div>

        <div className={styles.Value}>{Math.floor(value)}%</div>
      </div>
    ),
    [title],
  );

  return (
    <Liquid
      className={classnames(styles.Chart, className, isDarkMode && styles.DarkMode)}
      percent={percent}
      renderer="svg"
      outline={{ border: borderWidth }}
      liquidStyle={{ stroke: strokeColor, fill: fillColor }}
      statistic={{
        content: {
          customHtml: () => RenderTitle(percent * 100),
        },
      }}
    />
  );
}

LiquidChart.propTypes = {
  className: PropTypes.string,
  percent: PropTypes.number,
  strokeColor: PropTypes.string,
  fillColor: PropTypes.string,
  borderWidth: PropTypes.number,
  title: PropTypes.string,
  isDarkMode: PropTypes.bool,
};

LiquidChart.defaultProps = {
  className: '',
  percent: 0,
  strokeColor: null,
  fillColor: null,
  borderWidth: null,
  title: null,
  isDarkMode: true,
};

export default memo(LiquidChart);
