import { connect } from 'react-redux';

import { CLEAR_GET_RETRIEVE_COMPANIES, GET_RETRIEVE_COMPANIES } from '@/store/manager/actionTypes';

import HomePage from './HomePage';

const mapStateToProps = (state) => ({
  userRole: state.user.userRole,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetRetrieveCompanies: () => dispatch({ type: GET_RETRIEVE_COMPANIES }),
  actionClearGetRetrieveCompanies: () => dispatch({ type: CLEAR_GET_RETRIEVE_COMPANIES }),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
