import { LANGUAGES, LOCAL_STORAGE_KEYS } from '@/constants';
import { changeLanguage } from '@/languages';
import { existedVal } from '@/utils';

import { GET_SKY_X_INFORMATION_SUCCESS } from '../skyx/actionTypes';

import {
  ACTIVE_VIEW_MODE_LANGUAGE,
  CLEAR_GET_DATA_VIEW_MODE,
  CLEAR_VIEW_MODE_LANGUAGE,
  GET_DATA_VIEW_MODE,
  GET_DATA_VIEW_MODE_FAILED,
  GET_DATA_VIEW_MODE_SUCCESS,
  SET_VIEW_MODE_LANGUAGE,
  SET_VIEW_MODE_REFS,
} from './actionTypes';

export const initialState = {
  getDataViewModeLoading: false,
  dataViewMode: {},

  countdown: 7,
  videos: [
    // 'https://www.youtube.com/watch?v=Dgg3Zf4-zzo',
    // 'https://www.youtube.com/watch?v=7lUHfM0jI60',
    // 'https://www.youtube.com/watch?v=LJpgvQ0DixE',
  ],

  viewModeLanguage: LANGUAGES.DEFAULT,

  refs: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SKY_X_INFORMATION_SUCCESS:
      return {
        ...state,
        videos: action.payload.videos ?? [],
      };

    case GET_DATA_VIEW_MODE: {
      return {
        ...state,
        getDataViewModeLoading: true,
      };
    }

    case GET_DATA_VIEW_MODE_SUCCESS: {
      return {
        ...state,
        getDataViewModeLoading: false,
        dataViewMode: action.payload,
      };
    }

    case GET_DATA_VIEW_MODE_FAILED:
    case CLEAR_GET_DATA_VIEW_MODE: {
      return {
        ...state,
        getDataViewModeLoading: false,
        dataViewMode: {},
      };
    }

    case ACTIVE_VIEW_MODE_LANGUAGE: {
      const language = localStorage.getItem(LOCAL_STORAGE_KEYS.VIEW_MODE_LANGUAGE);
      const viewModeLanguage =
        existedVal(language) && language !== '' ? language : LANGUAGES.DEFAULT;

      changeLanguage(viewModeLanguage);

      return {
        ...state,
        viewModeLanguage,
      };
    }

    case SET_VIEW_MODE_LANGUAGE: {
      const viewModeLanguage =
        existedVal(action.payload) && existedVal(LANGUAGES[action.payload])
          ? action.payload
          : LANGUAGES.DEFAULT;
      localStorage.setItem(LOCAL_STORAGE_KEYS.VIEW_MODE_LANGUAGE, viewModeLanguage);

      changeLanguage(viewModeLanguage);

      return {
        ...state,
        viewModeLanguage,
      };
    }

    case CLEAR_VIEW_MODE_LANGUAGE: {
      const language = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_LANGUAGE);
      const appLanguage = existedVal(language) && language !== '' ? language : LANGUAGES.DEFAULT;

      changeLanguage(appLanguage);

      return state;
    }

    case SET_VIEW_MODE_REFS:
      return {
        ...state,
        refs: action.payload,
      };

    default: {
      return state;
    }
  }
};
