import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';

import AppDropdown from '@/components/app/AppDropdown';

import { LANGUAGES } from '@/constants';
// import { DROPDOWN_PLACEMENT } from '@/constants';
import { classnames, emptyFn } from '@/utils';

import styles from './AppLanguages.module.scss';

const APP_LANGUAGES = [LANGUAGES.en, LANGUAGES.vi];

function AppLanguages(props) {
  const { isSmall, className, appLanguage, actionChangeLanguage } = props;

  const onSetLanguage = useCallback(
    (e) => {
      if (e.key !== appLanguage) {
        actionChangeLanguage(e.key);
      }
    },
    [actionChangeLanguage, appLanguage],
  );

  return (
    <AppDropdown
      isOutline
      isSmall={isSmall}
      items={APP_LANGUAGES}
      label={LANGUAGES[appLanguage].title}
      className={classnames(styles.Component, isSmall && styles.Small, className)}
      onClickItem={onSetLanguage}
    />
  );
}

AppLanguages.propTypes = {
  // placement: PropTypes.oneOf(
  //   Object.keys(DROPDOWN_PLACEMENT).map((item) => DROPDOWN_PLACEMENT[item]),
  // ),
  isSmall: PropTypes.bool,
  className: PropTypes.string,

  appLanguage: PropTypes.string,
  actionChangeLanguage: PropTypes.func,
};

AppLanguages.defaultProps = {
  // placement: 'bottom',
  isSmall: false,
  className: '',

  appLanguage: LANGUAGES.DEFAULT,
  actionChangeLanguage: emptyFn,
};

export default memo(AppLanguages);
