import React, { memo } from 'react';
import ImageGallery from 'react-image-gallery';
import { Col, Image } from 'antd';

import images from '@/assets/images';
import { classnames } from '@/utils';

import 'react-image-gallery/styles/css/image-gallery.css';
import styles from './Galleries.module.scss';

const items = [
  { original: images.signInSlide1 },
  { original: images.signInSlide2 },
  { original: images.signInSlide3 },
  { original: images.signInSlide4 },
];

function Galleries() {
  return (
    <Col
      span={24}
      lg={{ span: 16, order: 2 }}
      className={classnames('d-none d-lg-block', styles.Galleries)}>
      <ImageGallery
        autoPlay
        infinite
        items={items}
        slideInterval={3000}
        showBullets={false}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        showNav={false}
        renderItem={(item) => (
          <Image
            preview={false}
            className={styles.GalleriesImage}
            rootClassName={styles.GalleriesImageWrapper}
            src={item.original}
            alt="Sign in to your account"
          />
        )}
      />
    </Col>
  );
}

export default memo(Galleries);
