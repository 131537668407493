import axiosClient from '@/utils/axios';

const endPoint = '/skyx';

export default {
  async getSkyXInformation() {
    const path = `${endPoint}/information`;

    const response = await axiosClient.get(path);

    return response.data;
  },
  async updateSkyXInformation(values) {
    const path = `${endPoint}/information/${values.id}`;

    const response = await axiosClient.put(path, values);

    return response.data;
  },
};
