import React, { memo, useCallback, useEffect } from 'react';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import uuid from 'react-uuid';
import { Breadcrumb, Col, Divider, Empty, Form, Input, InputNumber, message, Row } from 'antd';
import PropTypes from 'prop-types';

import { breadcrumb, formConfig, layout } from '@/pages/CompanySettingPage/config';

import AppButton from '@/components/app/AppButton';
import AppInput from '@/components/app/AppInput';
import AppLink from '@/components/app/AppLink';
import AppLoading from '@/components/app/AppLoading';

import { ReactComponent as IconAngleLeft } from '@/assets/icons/angle-left.svg';
import { ReactComponent as IconViewInfo } from '@/assets/icons/expand.svg';
import { ReactComponent as IconPlusCircle } from '@/assets/icons/plus-circle.svg';
import { ReactComponent as IconTrash } from '@/assets/icons/trash.svg';
import { ReactComponent as IconVerifiedCompany } from '@/assets/icons/verified.svg';
// import { ReactComponent as IconVideosEmpty } from '@/assets/images/company-information/form-videos-empty.svg';
import { LOCATIONS, SIDER_DIRECTION } from '@/constants';
import { t } from '@/languages';
import {
  changePropsToArray as getDataPath,
  classnames,
  emptyFn,
  isHasData,
  mergeDeep,
} from '@/utils';
import { useBeforeUnload, useBoolean } from '@/utils/hooks';

import ConfirmNavigation from './ConfirmNavigation';

import styles from './FormInformation.module.scss';

const FIELD_NOT_SHOW_ERROR = {
  videos: true,
};

function FormInformation(props) {
  const { isSkyX, information, loading, updateLoading, onSubmitForm } = props;

  const [form] = Form.useForm();

  const [messageApi, contextHolder] = message.useMessage();

  const { value: hasNotSaved, setTrue: setNotSaved, setFalse: setSaved } = useBoolean(false);
  const navigateBlocker = useBlocker(hasNotSaved);

  const getTitleOfVideo = useCallback(
    (number) => t('CompanySettingPage.form.video_url', { number }),
    [],
  );

  const onSuccessUpdateCompanyInformationDetails = useCallback(() => {
    setSaved();
    messageApi.success(t('CompanySettingPage.form.save_form_success'));
  }, [setSaved, messageApi]);

  const onFailedUpdateCompanyInformationDetails = useCallback(
    (errors) => {
      const errorFields = errors
        .map((error) => {
          messageApi.warning(error.msg);

          if (!FIELD_NOT_SHOW_ERROR[error.param]) {
            return {
              name: error.param,
              errors: [error.msg],
            };
          }

          return null;
        })
        .filter((item) => !!item);

      form.setFields(errorFields);
    },
    [form, messageApi],
  );

  const onSubmit = useCallback(
    (formData) => {
      const formDataFromEn = {
        en: {
          headline: '',
          name: '',
          description: '',
          ...formData.en,
        },
        vi: {
          headline: '',
          name: '',
          description: '',
          ...formData.en,
        },
        kr: {
          headline: '',
          name: '',
          description: '',
          ...formData.en,
        },
        cn: {
          headline: '',
          name: '',
          description: '',
          ...formData.en,
        },
      };

      const mergedFormData = mergeDeep(formData, formDataFromEn);

      onSubmitForm(mergedFormData, {
        successCallback: onSuccessUpdateCompanyInformationDetails,
        errorCallback: onFailedUpdateCompanyInformationDetails,
      });
    },
    [
      onFailedUpdateCompanyInformationDetails,
      onSubmitForm,
      onSuccessUpdateCompanyInformationDetails,
    ],
  );

  useEffect(() => {
    form.setFieldsValue(information);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [information]);

  useEffect(
    () => () => {
      form.resetFields();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (isHasData(navigateBlocker) && navigateBlocker.state === 'blocked' && !hasNotSaved) {
      navigateBlocker.reset();
    }
  }, [navigateBlocker, hasNotSaved]);

  // handle f5, message not working, but it will not run without message
  useBeforeUnload(hasNotSaved, 'You have unsaved changes, are you sure?');

  return (
    <Row>
      {contextHolder}

      {navigateBlocker ? <ConfirmNavigation blocker={navigateBlocker} /> : null}

      {!isSkyX && (
        <Col span={24}>
          <Breadcrumb className={styles.Breadcrumb}>
            {breadcrumb.map((item) => (
              <Breadcrumb.Item key={item.label}>
                {item.to ? <AppLink to={item.to}>{item.label}</AppLink> : <span>{item.label}</span>}
              </Breadcrumb.Item>
            ))}
          </Breadcrumb>
        </Col>
      )}

      <Col span={24} className={classnames(styles.PageHeader, isSkyX && styles.IsSkyX)}>
        <div className="d-flex justify-content-between align-items-center">
          {isSkyX ? (
            <span className={styles.PageTitle}>{t(SIDER_DIRECTION.INFOR.label)}</span>
          ) : (
            <div className="d-flex align-items-center flex-grow-1 flex-grow-md-0">
              <AppLink to={LOCATIONS.HOME} className={styles.PageBack}>
                <IconAngleLeft />
              </AppLink>

              <h1 className={styles.PageTitle}>{information.en?.name ?? ''}</h1>

              <AppLink
                to={LOCATIONS.CLIENT_VIEW_MODE(information._id)}
                className="ms-auto ms-md-0 d-none d-sm-flex">
                <AppButton ghost type="primary" className={styles.BtnViewInfo}>
                  <IconViewInfo />

                  <span>{t('CompanySettingPage.head.viewInfo')}</span>
                </AppButton>
              </AppLink>
            </div>
          )}

          <AppButton
            className={classnames(styles.BtnSave, 'd-none d-md-flex')}
            type="primary"
            onClick={form.submit}
            disabled={updateLoading}>
            {t('CompanySettingPage.head.saveBtn')}
          </AppButton>
        </div>

        <div className="d-sm-none">
          <AppLink
            to={LOCATIONS.CLIENT_VIEW_MODE(information._id)}
            className="justify-content-start my-3">
            <AppButton ghost type="primary" className={styles.BtnViewInfo}>
              <IconViewInfo />

              <span>{t('CompanySettingPage.head.viewInfo')}</span>
            </AppButton>
          </AppLink>
        </div>
      </Col>

      <Col span={24}>
        <Form
          scrollToFirstError
          name="FormCompanyInformation"
          layout="horizontal"
          className={styles.Form}
          form={form}
          onChange={setNotSaved}
          onFinish={onSubmit}
          initialValues={information}>
          <Row gutter={24}>
            <Col span={24} xl={layout.information.xl} className="mb-3 mb-xl-0">
              <div className={styles.Section}>
                <div className={styles.Group}>
                  <div className={styles.TitleWrapper}>
                    <div className={styles.Title}>
                      {t('CompanySettingPage.section.companyInformation')}
                    </div>
                  </div>
                </div>

                <div className={styles.Group}>
                  {loading ? (
                    <AppLoading className="py-5" />
                  ) : (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>
                      {isSkyX ? (
                        <div className={styles.Content}>
                          <AppInput
                            label={t('SkyXInformationPage.form.headline_en')}
                            name={getDataPath('en', 'headline')}
                            placeholder={t('SkyXInformationPage.form.headline_en_placeholder')}
                            rules={formConfig.rules.headline_en}
                            labelClass={styles.Label}
                            labelOptions={formConfig.options.headline_en}
                          />

                          <AppInput
                            label={t('SkyXInformationPage.form.headline_vi')}
                            name={getDataPath('vi', 'headline')}
                            placeholder={t('SkyXInformationPage.form.headline_vi_placeholder')}
                            labelClass={styles.Label}
                          />

                          <AppInput
                            label={t('SkyXInformationPage.form.headline_kr')}
                            name={getDataPath('kr', 'headline')}
                            placeholder={t('SkyXInformationPage.form.headline_kr_placeholder')}
                            labelClass={styles.Label}
                          />

                          <AppInput
                            label={t('SkyXInformationPage.form.headline_cntw')}
                            name={getDataPath('cn', 'headline')}
                            placeholder={t('SkyXInformationPage.form.headline_cntw_placeholder')}
                            labelClass={styles.Label}
                          />

                          <div className="mt-2 pt-2 mb-4 pb-2">
                            <Divider className="m-0" />
                          </div>

                          <AppInput
                            label={t('SkyXInformationPage.form.contact_phone')}
                            name="contactPhone"
                            rules={formConfig.rules.contact_phone}
                            labelClass={styles.Label}
                            labelOptions={formConfig.options.contact_phone}
                          />

                          <AppInput
                            label={t('SkyXInformationPage.form.contact_email')}
                            name="email"
                            placeholder={t('SkyXInformationPage.form.contact_email_placeholder')}
                            rules={formConfig.rules.contact_email}
                            labelClass={styles.Label}
                            labelOptions={formConfig.options.contact_email}
                          />

                          <AppInput
                            label={t('SkyXInformationPage.form.contact_website')}
                            name="website"
                            placeholder={t('SkyXInformationPage.form.contact_website_placeholder')}
                            rules={formConfig.rules.contact_website}
                            labelClass={styles.Label}
                            labelOptions={formConfig.options.contact_website}
                            inputOptions={{
                              addonBefore: 'http://',
                            }}
                          />

                          <div className="pt-2 mb-4 pb-2">
                            <Divider className="m-0" />
                          </div>

                          <Form.Item
                            label={t('SkyXInformationPage.form.beneficial_vnd')}
                            name={getDataPath('beneficial', 'vnd', 'amount')}
                            className={styles.Label}
                            rules={formConfig.rules.beneficial_vnd}>
                            <InputNumber
                              min={0}
                              controls={false}
                              className="w-100"
                              placeholder={t('SkyXInformationPage.form.beneficial_vnd_placeholder')}
                              addonAfter={t('symbol.vnd')}
                            />
                          </Form.Item>

                          <Form.Item
                            label={t('SkyXInformationPage.form.beneficial_usd')}
                            name={getDataPath('beneficial', 'usd', 'amount')}
                            className={styles.Label}
                            rules={formConfig.rules.beneficial_usd}>
                            <InputNumber
                              min={0}
                              controls={false}
                              className="w-100"
                              placeholder={t('SkyXInformationPage.form.beneficial_usd_placeholder')}
                              addonAfter={t('symbol.usd')}
                            />
                          </Form.Item>
                        </div>
                      ) : (
                        <div className={styles.Content}>
                          <Form.Item
                            label={t('CompanySettingPage.form.username')}
                            className={styles.Label}>
                            <div className="d-flex align-items-center ms-md-1 ps-md-2">
                              <span className="me-1">{information.username}</span>

                              <IconVerifiedCompany />
                            </div>
                          </Form.Item>

                          <AppInput
                            label={t('CompanySettingPage.form.name_en')}
                            name={getDataPath('en', 'name')}
                            placeholder={t('CompanySettingPage.form.name_en_placeholder')}
                            rules={formConfig.rules.name_en}
                            labelClass={styles.Label}
                            labelOptions={formConfig.options.name_en}
                          />

                          <AppInput
                            label={t('CompanySettingPage.form.description_en')}
                            name={getDataPath('en', 'description')}
                            placeholder={t('CompanySettingPage.form.description_en_placeholder')}
                            custom="TextArea"
                            rules={formConfig.rules.description_en}
                            labelClass={classnames(styles.Label, 'mb-5')}
                            labelOptions={formConfig.options.description_en}
                            inputOptions={formConfig.options.textarea}
                          />

                          <AppInput
                            label={t('CompanySettingPage.form.name_vi')}
                            name={getDataPath('vi', 'name')}
                            placeholder={t('CompanySettingPage.form.name_vi_placeholder')}
                            labelClass={styles.Label}
                          />

                          <AppInput
                            label={t('CompanySettingPage.form.description_vi')}
                            name={getDataPath('vi', 'description')}
                            placeholder={t('CompanySettingPage.form.description_vi_placeholder')}
                            custom="TextArea"
                            labelClass={classnames(styles.Label, 'mb-5')}
                            inputOptions={formConfig.options.textarea}
                          />

                          <AppInput
                            label={t('CompanySettingPage.form.name_kr')}
                            name={getDataPath('kr', 'name')}
                            placeholder={t('CompanySettingPage.form.name_kr_placeholder')}
                            labelClass={styles.Label}
                          />

                          <AppInput
                            label={t('CompanySettingPage.form.description_kr')}
                            name={getDataPath('kr', 'description')}
                            placeholder={t('CompanySettingPage.form.description_kr_placeholder')}
                            custom="TextArea"
                            labelClass={classnames(styles.Label, 'mb-5')}
                            inputOptions={formConfig.options.textarea}
                          />

                          <AppInput
                            label={t('CompanySettingPage.form.name_cntw')}
                            name={getDataPath('cn', 'name')}
                            placeholder={t('CompanySettingPage.form.name_cntw_placeholder')}
                            labelClass={styles.Label}
                          />

                          <AppInput
                            label={t('CompanySettingPage.form.description_cntw')}
                            name={getDataPath('cn', 'description')}
                            placeholder={t('CompanySettingPage.form.description_cntw_placeholder')}
                            custom="TextArea"
                            labelClass={styles.Label}
                            inputOptions={formConfig.options.textarea}
                          />

                          <div className="mt-5 pt-2 mb-4 pb-2">
                            <Divider className="m-0" />
                          </div>

                          <Form.Item
                            label={t('CompanySettingPage.form.electricity_usage')}
                            name="electricityUsage"
                            className={classnames(styles.Label, styles.Percent)}
                            rules={formConfig.rules.electricity_usage}>
                            <InputNumber
                              // required
                              name="electricityUsage"
                              min={0}
                              max={100}
                              controls={false}
                              className="w-100"
                              placeholder={t(
                                'CompanySettingPage.form.electricity_usage_placeholder',
                              )}
                              addonAfter={t('symbol.percent')}
                            />
                          </Form.Item>

                          <Form.Item
                            label={t('CompanySettingPage.form.beneficial_vnd')}
                            name={getDataPath('beneficial', 'vnd', 'amount')}
                            className={styles.Label}
                            rules={formConfig.rules.beneficial_vnd}>
                            <InputNumber
                              min={0}
                              controls={false}
                              className="w-100"
                              placeholder={t('CompanySettingPage.form.beneficial_vnd_placeholder')}
                              addonAfter={t('symbol.vnd')}
                            />
                          </Form.Item>

                          <Form.Item
                            label={t('CompanySettingPage.form.beneficial_usd')}
                            name={getDataPath('beneficial', 'usd', 'amount')}
                            className={styles.Label}
                            rules={formConfig.rules.beneficial_usd}>
                            <InputNumber
                              min={0}
                              controls={false}
                              className="w-100"
                              placeholder={t('CompanySettingPage.form.beneficial_usd_placeholder')}
                              addonAfter={t('symbol.usd')}
                            />
                          </Form.Item>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </Col>

            <Col span={24} xl={layout.video.xl} className="mb-3 mb-xl-0">
              <Form.List name="videos">
                {(fields, { remove, add }) => (
                  <div className={styles.Section}>
                    <div className={styles.Group}>
                      <div className={styles.TitleWrapper}>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className={styles.Title}>
                            {t('CompanySettingPage.section.video')}
                          </div>

                          {fields.length < 5 && (
                            <AppButton
                              type="link"
                              className={classnames('p-0', styles.BtnAddVideo)}
                              onClick={add}>
                              <IconPlusCircle />

                              <span>{t('CompanySettingPage.form.btn_add_video')}</span>
                            </AppButton>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className={styles.Group}>
                      <div className={classnames(styles.Content, styles.Videos)}>
                        {isHasData(fields) ? (
                          fields.map((field, index) => (
                            <div key={uuid()} className={styles.LabelVideoWrapper}>
                              <Form.Item
                                name={field.name}
                                label={getTitleOfVideo(index + 1)}
                                className={classnames(styles.Label, 'flex-grow-1')}
                                rules={formConfig.rules.video}>
                                <Input
                                  placeholder={t('CompanySettingPage.form.video_url_placeholder')}
                                />
                              </Form.Item>

                              <AppButton
                                type="text"
                                value={field.name}
                                className={styles.BtnRemoveVideo}
                                onClick={remove}>
                                <IconTrash />
                              </AppButton>
                            </div>
                          ))
                        ) : (
                          <div className="d-flex justify-content-center">
                            <Empty description={t('CompanySettingPage.section.no_video')} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </Form.List>
            </Col>

            <Col span={24} className="d-md-none">
              <AppButton
                className={styles.BtnSave}
                type="primary"
                htmlType="submit"
                disabled={updateLoading}>
                {t('CompanySettingPage.head.saveBtn')}
              </AppButton>
            </Col>
          </Row>
        </Form>
      </Col>
    </Row>
  );
}

FormInformation.propTypes = {
  isSkyX: PropTypes.bool,
  information: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
  updateLoading: PropTypes.bool,
  onSubmitForm: PropTypes.func,
};

FormInformation.defaultProps = {
  isSkyX: false,
  information: {},
  loading: false,
  updateLoading: false,
  onSubmitForm: emptyFn,
};

export default memo(FormInformation);
