import React, { memo, useCallback, useState } from 'react';

import { ReactComponent as CollapseIcon } from '@/assets/icons/collapse.svg';
import { ReactComponent as ExpandIcon } from '@/assets/icons/expand.svg';
import { toggleFullScreen } from '@/utils';
import { useEventListener } from '@/utils/hooks';

function Fullscreen() {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const onToggleFullScreen = useCallback(() => {
    toggleFullScreen();
  }, []);

  useEventListener('fullscreenchange', () => {
    setIsFullScreen(!!document.fullscreenElement);
  });

  return (
    <button type="button" onClick={onToggleFullScreen}>
      {isFullScreen ? <CollapseIcon /> : <ExpandIcon />}
    </button>
  );
}

export default memo(Fullscreen);
