import { connect } from 'react-redux';

import {
  CLEAR_GET_CLIENT_COMPANY,
  GET_CLIENT_COMPANY,
  UPDATE_CLIENT_SHOW_SKY_X_INFO,
} from '@/store/manager/actionTypes';

import CompanyClientView from './CompanyClientView';

const mapStateToProps = (state) => ({
  clientCompany: state.manager.clientCompany,
  getClientCompanyLoading: state.manager.getClientCompanyLoading,
  updateClientShowSkyXInfo: state.manager.updateClientShowSkyXInfo,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetClientCompany: () => dispatch({ type: GET_CLIENT_COMPANY }),
  actionClearGetClientCompany: () => dispatch({ type: CLEAR_GET_CLIENT_COMPANY }),

  actionUpdateClientShowSkyXInfo: () => dispatch({ type: UPDATE_CLIENT_SHOW_SKY_X_INFO }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyClientView);
