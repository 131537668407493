import { connect } from 'react-redux';

import DetailsScreen from './DetailsScreen';

const mapStateToProps = (state) => ({
  skyXInformation: state.skyx.skyXInformation,
  clientCompany: state.manager.clientCompany,

  dataViewMode: state.dataViewMode.dataViewMode,
  groupsOverview: state.group.groupsOverview,
  // plantDetail: state.plant.plantDetail,
  listOfPlant: state.plant.listOfPlant,
  retrieveWeatherByLocation: state.weather.retrieveWeatherByLocation,

  plants: state.plant.plants,
  viewModeLanguage: state.dataViewMode.viewModeLanguage,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(DetailsScreen);
