import axiosClient from '@/utils/axios';

const endPoint = '/plants';

export default {
  async getPlantsOverview() {
    const path = `${endPoint}/overview`;

    const response = await axiosClient.get(path);

    return response.data;
  },

  async getPlantDetail(id) {
    const path = `${endPoint}/${id}`;

    const response = await axiosClient.get(path);

    return response.data;
  },

  async getListOfPlant() {
    const response = await axiosClient.get(endPoint);

    return response.data;
  },
};
