import {
  CLEAR_GET_CONFIG,
  CLEAR_GET_ROTATION_TIME,
  GET_CONFIG,
  GET_CONFIG_FAILED,
  GET_CONFIG_SUCCESS,
  GET_ROTATION_TIME,
  GET_ROTATION_TIME_FAILED,
  GET_ROTATION_TIME_SUCCESS,
  UPDATE_CONFIG,
  UPDATE_CONFIG_FAILED,
  UPDATE_CONFIG_SUCCESS,
} from './actionTypes';

export const initialState = {
  getConfigLoading: false,
  config: {},

  updateConfigLoading: false,

  getRotationTimeLoading: false,
  rotationTime: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG: {
      return {
        ...state,
        getConfigLoading: true,
      };
    }

    case GET_CONFIG_SUCCESS: {
      return {
        ...state,
        getConfigLoading: false,
        config: action.payload,
      };
    }

    case GET_CONFIG_FAILED:
    case CLEAR_GET_CONFIG: {
      return {
        ...state,
        getConfigLoading: false,
        config: {},
      };
    }

    case UPDATE_CONFIG: {
      return {
        ...state,
        updateConfigLoading: true,
      };
    }

    case UPDATE_CONFIG_SUCCESS:
    case UPDATE_CONFIG_FAILED: {
      return {
        ...state,
        updateConfigLoading: false,
      };
    }

    case GET_ROTATION_TIME: {
      return {
        ...state,
        getRotationTimeLoading: true,
      };
    }

    case GET_ROTATION_TIME_SUCCESS: {
      return {
        ...state,
        getRotationTimeLoading: false,
        rotationTime: action.payload.rotationTime,
      };
    }

    case GET_ROTATION_TIME_FAILED:
    case CLEAR_GET_ROTATION_TIME: {
      return {
        ...state,
        getRotationTimeLoading: false,
        rotationTime: 0,
      };
    }

    default: {
      return state;
    }
  }
};
