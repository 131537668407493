import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import moment from 'moment';
import PropTypes from 'prop-types';

// import { ReactComponent as IconBrokenClouds } from '@/assets/icons/broken-clouds.svg';
import { ReactComponent as IconClearSky } from '@/assets/icons/clear-sky.svg';
// import { ReactComponent as IconFewClouds } from '@/assets/icons/few-clouds.svg';
import { ReactComponent as IconMist } from '@/assets/icons/mist.svg';
import { ReactComponent as IconRain } from '@/assets/icons/rain.svg';
import { ReactComponent as IconScatteredClouds } from '@/assets/icons/scattered-clouds.svg';
import { ReactComponent as IconShowerRain } from '@/assets/icons/shower-rain.svg';
import { ReactComponent as IconSnow } from '@/assets/icons/snow.svg';
import { ReactComponent as IconThunderStorm } from '@/assets/icons/thunder-storm.svg';
import MarkerIcon from '@/assets/images/view-mode/map-marker.png';
import { LANGUAGES } from '@/constants';
import { t } from '@/languages';
import { classnames } from '@/utils';

import { DARK_MODE_MAP, LIGHT_MODE_MAP, mapStyles } from './faker';

import styles from './MapContainer.module.scss';

const mapDim = {
  height: 652,
  width: 336,
};

function getBoundsZoomLevel(bounds) {
  const WORLD_DIM = { height: mapDim.height, width: mapDim.width };
  const ZOOM_MAX = 21;

  function latRad(lat) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

// EX: This variable is used for clearing setTimeout.
let renderMapRef;

export function MapContainer(props) {
  const {
    retrieveWeatherByLocation,
    viewModeLanguage,
    clientCompany,
    google,
    loaded,
    listOfPlant,
    isDarkMode,
    isAdminViewMode,
    scale,
  } = props;

  // EX: This variable is used for re-rendering the map when the theme changes
  const [isMapReady, setIsMapReady] = useState(false);

  const mapScaleStyles = useMemo(() => ({ '--map-scale': scale }), [scale]);

  const temp = useMemo(() => {
    if (!retrieveWeatherByLocation.main?.temp) return 0;

    return Math.round((retrieveWeatherByLocation.main.temp ?? 0) - 272.15);
  }, [retrieveWeatherByLocation.main?.temp]);

  const weather = useMemo(() => {
    const weatherMain = retrieveWeatherByLocation.weather?.[0]?.main;

    let icon = null;
    let weatherDescription = '';
    const currentMonth = moment().format('MMMM').toLocaleLowerCase();
    const month = t(`month.${currentMonth}`);
    const day = moment().format('D');

    let renderDate = `${month} ${day}`;

    switch (viewModeLanguage) {
      case LANGUAGES.vi.key:
        renderDate = `${day} ${month}`;
        break;

      default:
        break;
    }

    const defaultValue = {
      renderDate,
    };

    if (!weatherMain || isAdminViewMode) return { ...defaultValue, icon, weatherDescription };

    const weatherConvert = weatherMain.toLowerCase();

    switch (true) {
      case weatherConvert === 'clear':
        icon = <IconClearSky />;
        weatherDescription = t('weather.clear');
        break;
      case weatherConvert === 'clouds':
        icon = <IconScatteredClouds />;
        weatherDescription = t('weather.clouds');
        break;
      case weatherConvert === 'drizzle':
        icon = <IconShowerRain />;
        weatherDescription = t('weather.drizzle');
        break;
      case weatherConvert === 'rain':
        icon = <IconRain />;
        weatherDescription = t('weather.rain');
        break;
      case weatherConvert === 'thunderstorm':
        icon = <IconThunderStorm />;
        weatherDescription = t('weather.thunderstorm');
        break;
      case weatherConvert === 'snow':
        icon = <IconSnow />;
        weatherDescription = t('weather.snow');
        break;
      default:
        icon = <IconMist />;
        weatherDescription = t('weather.atmosphere');
        break;
    }

    return { ...defaultValue, icon, weatherDescription };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAdminViewMode, retrieveWeatherByLocation.weather, viewModeLanguage]);

  const componentLoading = useMemo(
    () => !listOfPlant.length || !loaded || !isMapReady,
    [listOfPlant.length, loaded, isMapReady],
  );

  const MapMarkers = useMemo(
    () =>
      listOfPlant
        .map((plant) => ({
          latitude: plant.latitude,
          longitude: plant.longitude,
        }))
        .map((store, idx) => (
          <Marker
            key={`${new Date().toLocaleString() + idx}`}
            position={{
              lat: store.latitude,
              lng: store.longitude,
            }}
            icon={{
              url: MarkerIcon,
              scaledSize: new google.maps.Size(36, 36),
              anchor: new google.maps.Point(18, 18),
            }}
          />
        )),
    [google.maps.Point, google.maps.Size, listOfPlant],
  );

  const mapLoaded = useCallback(
    (_, map) => {
      let lat = 0;
      let lng = 0;
      const listOfPlantLength = listOfPlant.length;

      listOfPlant.forEach((plant) => {
        lat += plant?.latitude ?? 0;
        lng += plant?.longitude ?? 0;
      });

      const latAverage = lat / listOfPlantLength;
      const lngAverage = lng / listOfPlantLength;

      const center = isAdminViewMode
        ? { lat: 15.657934, lng: 105.8660125 }
        : { lat: latAverage, lng: lngAverage };

      map.setOptions({
        styles: isDarkMode ? DARK_MODE_MAP : LIGHT_MODE_MAP,
        draggable: false,
        gestureHandling: 'none',
        keyboardShortcuts: false,
        // Lao coordinate
        center,
        style: { backgroundColor: 'transparent' },
      });
    },
    [isAdminViewMode, isDarkMode, listOfPlant],
  );

  const createPointForMarkers = useCallback(
    (points) =>
      points.map(
        (point) =>
          new google.maps.Marker({
            position: new google.maps.LatLng(point.lat, point.lng),
          }),
      ),
    [google.maps.LatLng, google.maps.Marker],
  );

  const createBoundsForMarkers = useCallback(
    (markers) => {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach((marker) => {
        bounds.extend(marker.getPosition());
      });

      return bounds;
    },
    [google.maps.LatLngBounds],
  );

  const points = useMemo(
    () => listOfPlant.map((plant) => ({ lat: plant.latitude, lng: plant.longitude })),
    [listOfPlant],
  );

  const bounds = useMemo(
    () => (points.length > 0 ? createBoundsForMarkers(createPointForMarkers(points)) : null),
    [createBoundsForMarkers, createPointForMarkers, points],
  );

  const zoom = useMemo(() => {
    switch (true) {
      case isAdminViewMode:
        return 6;
      case !listOfPlant.length:
        return 0;
      case listOfPlant.length === 1:
        return 10;

      default:
        return getBoundsZoomLevel(bounds);
    }
  }, [bounds, isAdminViewMode, listOfPlant.length]);

  useEffect(() => {
    setIsMapReady(false);

    renderMapRef = setTimeout(() => {
      setIsMapReady(true);
    }, 100);

    return () => {
      clearTimeout(renderMapRef);
    };
  }, [isDarkMode]);

  return (
    <div
      style={mapScaleStyles}
      className={classnames(
        'h-100',
        isDarkMode && styles.DarkMode,
        !isAdminViewMode && styles.Client,
      )}>
      {componentLoading && (
        <div className={styles.Spin}>
          <Spin size="large" />
        </div>
      )}

      {!componentLoading && (
        <>
          <div className={styles.Heading}>
            {isAdminViewMode ? (
              <>
                <span className={styles.Title}>{t('ViewMode.map.title')}</span>

                <span className={styles.SubTitle}>
                  {t('ViewMode.map.data', {
                    number: listOfPlant.length || 0,
                  })}
                </span>
              </>
            ) : (
              <>
                <span className={styles.Title}>{clientCompany[viewModeLanguage]?.name ?? ''}</span>

                <span className={styles.Desc}>
                  {clientCompany[viewModeLanguage]?.description ?? ''}
                </span>
              </>
            )}
          </div>

          <div className={styles.MapWrapper}>
            <div className={styles.MapContainer}>
              <Map
                google={google}
                zoom={zoom}
                style={mapStyles}
                onReady={mapLoaded}
                disableDefaultUI>
                {MapMarkers}
              </Map>
            </div>
          </div>

          {!isAdminViewMode && temp && (
            <div className={styles.Weather}>
              <p className={styles.WeatherTempWrapper}>
                <span className={styles.WeatherIcon}>{weather.icon}</span>
                <span className={styles.WeatherTemp}>{temp}°C</span>
              </p>

              <p className={styles.WeatherDescription}>
                {weather.weatherDescription ?? ''}, {weather.renderDate}
              </p>
            </div>
          )}
        </>
      )}

      <div className={styles.Overlay} />

      {temp && <div className={styles.OverlayBottom} />}
    </div>
  );
}

MapContainer.propTypes = {
  clientCompany: PropTypes.instanceOf(Object),
  retrieveWeatherByLocation: PropTypes.instanceOf(Object),
  google: PropTypes.instanceOf(Object),
  listOfPlant: PropTypes.instanceOf(Array),
  loaded: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isAdminViewMode: PropTypes.bool,
  viewModeLanguage: PropTypes.string,

  scale: PropTypes.number,
};

MapContainer.defaultProps = {
  clientCompany: {},
  retrieveWeatherByLocation: {},
  google: {},
  listOfPlant: [],
  loaded: false,
  isDarkMode: true,
  isAdminViewMode: true,
  viewModeLanguage: '',

  scale: 1,
};

export default memo(
  GoogleApiWrapper({ apiKey: process.env.REACT_APP_GOOGLE_MAP_KEY })(MapContainer),
);
