import axiosClient from '@/utils/axios';

const endPoint = '/weather';

export default {
  async getRetrieveWeatherByLocation({ lat, lon }) {
    const path = `${endPoint}/onecall?lat=${lat}&lon=${lon}`;

    const response = await axiosClient.get(path);

    return response.data;
  },
};
