import { cancelled, put, takeLeading } from 'redux-saga/effects';

import chartAPI from '@/apis/chartApi';
import { watchingSagaCanceled } from '@/utils';

import {
  CLEAR_RETRIEVE_CHART_PLANT,
  GET_RETRIEVE_CHART_PLANT_7_DAYS,
  GET_RETRIEVE_CHART_PLANT_7_DAYS_FAILED,
  GET_RETRIEVE_CHART_PLANT_7_DAYS_SUCCESS,
  GET_RETRIEVE_CHART_PLANT_10_DAYS,
  GET_RETRIEVE_CHART_PLANT_10_DAYS_FAILED,
  GET_RETRIEVE_CHART_PLANT_10_DAYS_SUCCESS,
  GET_RETRIEVE_CHART_PLANT_12_MONTHS,
  GET_RETRIEVE_CHART_PLANT_12_MONTHS_FAILED,
  GET_RETRIEVE_CHART_PLANT_12_MONTHS_SUCCESS,
  GET_RETRIEVE_CHART_PLANT_30_DAYS,
  GET_RETRIEVE_CHART_PLANT_30_DAYS_FAILED,
  GET_RETRIEVE_CHART_PLANT_30_DAYS_SUCCESS,
} from './actionTypes';

function* actionGetRetrieveChartPlant7Days({ payload = {} }) {
  const { values = {} } = payload;

  try {
    const response = yield chartAPI.getRetrieveChartPlants(values);

    yield put({
      type: GET_RETRIEVE_CHART_PLANT_7_DAYS_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({
      type: GET_RETRIEVE_CHART_PLANT_7_DAYS_FAILED,
    });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_RETRIEVE_CHART_PLANT });
    }
  }
}

function* actionGetRetrieveChartPlant10Days({ payload = {} }) {
  const { values = {} } = payload;

  try {
    const response = yield chartAPI.getRetrieveChartPlants(values);

    yield put({
      type: GET_RETRIEVE_CHART_PLANT_10_DAYS_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({
      type: GET_RETRIEVE_CHART_PLANT_10_DAYS_FAILED,
    });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_RETRIEVE_CHART_PLANT });
    }
  }
}

function* actionGetRetrieveChartPlant30Days({ payload = {} }) {
  const { values = {} } = payload;

  try {
    const response = yield chartAPI.getRetrieveChartPlants(values);

    yield put({
      type: GET_RETRIEVE_CHART_PLANT_30_DAYS_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({
      type: GET_RETRIEVE_CHART_PLANT_30_DAYS_FAILED,
    });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_RETRIEVE_CHART_PLANT });
    }
  }
}

function* actionGetRetrieveChartPlant12Months({ payload = {} }) {
  const { values = {} } = payload;

  try {
    const response = yield chartAPI.getRetrieveChartPlants(values);

    yield put({
      type: GET_RETRIEVE_CHART_PLANT_12_MONTHS_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({
      type: GET_RETRIEVE_CHART_PLANT_12_MONTHS_FAILED,
    });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_RETRIEVE_CHART_PLANT });
    }
  }
}

const forkAction = (payload, action) =>
  watchingSagaCanceled(payload, action, CLEAR_RETRIEVE_CHART_PLANT);

export default function* chartSaga() {
  yield takeLeading(GET_RETRIEVE_CHART_PLANT_7_DAYS, function* getRetrievePlant7days(payload) {
    yield forkAction(payload, actionGetRetrieveChartPlant7Days);
  });
  yield takeLeading(GET_RETRIEVE_CHART_PLANT_10_DAYS, function* getRetrievePlant10days(payload) {
    yield forkAction(payload, actionGetRetrieveChartPlant10Days);
  });
  yield takeLeading(GET_RETRIEVE_CHART_PLANT_30_DAYS, function* getRetrievePlant30days(payload) {
    yield forkAction(payload, actionGetRetrieveChartPlant30Days);
  });
  yield takeLeading(
    GET_RETRIEVE_CHART_PLANT_12_MONTHS,
    function* getRetrievePlant12months(payload) {
      yield forkAction(payload, actionGetRetrieveChartPlant12Months);
    },
  );
}
