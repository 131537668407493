// SIGN IN
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';

// REFRESH_TOKEN
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILED = 'REFRESH_TOKEN_FAILED';

// SET_TOKEN
export const SET_TOKEN = 'SET_TOKEN';

// SIGN_OUT
export const SIGN_OUT = 'SIGN_OUT';
