import { call, delay, put, takeLeading } from 'redux-saga/effects';

import { LANGUAGES, LOCAL_STORAGE_KEYS } from '@/constants';
import { changeLanguage } from '@/languages';
import { actionGetUserInfo } from '@/store/user/saga';
import { existedVal } from '@/utils';
import { setAppAccessToken } from '@/utils/axios';

import {
  CHANGE_LANGUAGE,
  CHANGE_LANGUAGE_PENDING,
  CHANGE_LANGUAGE_SUCCESS,
  INIT_APP,
  INIT_APP_SUCCESS,
} from './actionTypes';

function* actionInitApp() {
  const language = localStorage.getItem(LOCAL_STORAGE_KEYS.APP_LANGUAGE);
  const appLanguage = existedVal(language) && language !== '' ? language : LANGUAGES.DEFAULT;

  changeLanguage(appLanguage);

  try {
    const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);

    if (token) {
      setAppAccessToken(token);

      yield call(actionGetUserInfo, { payload: { token } });
    }
  } catch (error) {
    // TODO: Nothing
  } finally {
    yield delay(300);
    yield put({ type: INIT_APP_SUCCESS, payload: { appLanguage } });
  }
}

function* actionChangeLanguage({ payload }) {
  changeLanguage(payload);

  yield delay(500);

  yield put({ type: CHANGE_LANGUAGE_PENDING, payload });

  yield delay(500);

  yield put({ type: CHANGE_LANGUAGE_SUCCESS });
}

export default function* appSaga() {
  yield takeLeading(INIT_APP, actionInitApp);

  yield takeLeading(CHANGE_LANGUAGE, actionChangeLanguage);
}
