import React, { memo } from 'react';
import { Spin } from 'antd';
import PropTypes from 'prop-types';

import { classnames } from '@/utils';

function AppLoading(props) {
  const { className } = props;

  return (
    <div className={classnames('d-flex align-items-center justify-content-center', className)}>
      <Spin size="large" />
    </div>
  );
}

AppLoading.propTypes = {
  className: PropTypes.string,
};

AppLoading.defaultProps = {
  className: '',
};

export default memo(AppLoading);
