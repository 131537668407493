import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import FormInformation from '@/components/forms/FormInformation';

import { emptyFn } from '@/utils';

import styles from './SkyXInformationPage.module.scss';

function SkyXInformationPage(props) {
  const {
    skyXInformation,
    skyXInformationLoading,
    actionGetSkyXInformation,
    actionClearGetSkyXInformation,

    updateSkyXInformationLoading,
    actionUpdateSkyXInformation,
  } = props;

  const onSubmitForm = useCallback(
    (formData, { successCallback, errorCallback }) => {
      const values = { ...formData, id: skyXInformation._id };

      actionUpdateSkyXInformation({
        values,
        successCallback,
        errorCallback,
      });
    },
    [actionUpdateSkyXInformation, skyXInformation._id],
  );

  useEffect(() => {
    actionGetSkyXInformation();

    return () => {
      actionClearGetSkyXInformation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout.Content className={styles.Page}>
      <Helmet>
        <title> Information - SkyX’s Admin</title>
      </Helmet>

      <FormInformation
        isSkyX
        information={skyXInformation}
        loading={skyXInformationLoading}
        onSubmitForm={onSubmitForm}
        updateLoading={updateSkyXInformationLoading}
      />
    </Layout.Content>
  );
}

SkyXInformationPage.propTypes = {
  skyXInformation: PropTypes.instanceOf(Object),
  skyXInformationLoading: PropTypes.bool,
  actionGetSkyXInformation: PropTypes.func,
  actionClearGetSkyXInformation: PropTypes.func,

  updateSkyXInformationLoading: PropTypes.bool,
  actionUpdateSkyXInformation: PropTypes.func,
};

SkyXInformationPage.defaultProps = {
  skyXInformation: {},
  skyXInformationLoading: false,
  actionGetSkyXInformation: emptyFn,
  actionClearGetSkyXInformation: emptyFn,

  updateSkyXInformationLoading: false,
  actionUpdateSkyXInformation: emptyFn,
};

export default SkyXInformationPage;
