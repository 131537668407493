import { cancelled, put, takeLeading } from 'redux-saga/effects';

import configAPI from '@/apis/configAPI';
import { watchingSagaCanceled } from '@/utils';

import {
  CLEAR_GET_ROTATION_TIME,
  GET_CONFIG,
  GET_CONFIG_FAILED,
  GET_CONFIG_SUCCESS,
  GET_ROTATION_TIME,
  GET_ROTATION_TIME_FAILED,
  GET_ROTATION_TIME_SUCCESS,
  UPDATE_CONFIG,
  UPDATE_CONFIG_FAILED,
  UPDATE_CONFIG_SUCCESS,
} from './actionTypes';

function* actionGetConfig({ payload = {} }) {
  const { successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield configAPI.getConfig();

    yield put({
      type: GET_CONFIG_SUCCESS,
      payload: response.payload,
    });

    successCallback?.(response.payload);
  } catch (error) {
    yield put({ type: GET_CONFIG_FAILED });

    errorCallback?.(error);
  } finally {
    finallyCallback?.();
  }
}

function* actionUpdateConfig({ payload = {} }) {
  const { values, successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield configAPI.updateConfig(values);

    yield put({
      type: UPDATE_CONFIG_SUCCESS,
      payload: response.payload,
    });

    successCallback?.(response.payload);
  } catch (error) {
    yield put({ type: UPDATE_CONFIG_FAILED });

    errorCallback?.(error.response.data.errors);
  } finally {
    finallyCallback?.();
  }
}

function* actionGetRotationTime({ payload = {} }) {
  const { successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield configAPI.getRotationTime();

    yield put({
      type: GET_ROTATION_TIME_SUCCESS,
      payload: response.payload,
    });

    successCallback?.(response.payload);
  } catch (error) {
    yield put({ type: GET_ROTATION_TIME_FAILED });

    errorCallback?.(error);
  } finally {
    finallyCallback?.();

    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_ROTATION_TIME });
    }
  }
}

export default function* configSaga() {
  yield takeLeading(GET_CONFIG, actionGetConfig);

  yield takeLeading(UPDATE_CONFIG, actionUpdateConfig);

  yield takeLeading(GET_ROTATION_TIME, function* getRotationTime(payload) {
    yield watchingSagaCanceled(payload, actionGetRotationTime, CLEAR_GET_ROTATION_TIME);
  });
}
