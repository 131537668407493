import { connect } from 'react-redux';

import {
  CLEAR_GET_SKY_X_INFORMATION,
  GET_SKY_X_INFORMATION,
  UPDATE_SKY_X_INFORMATION,
} from '@/store/skyx/actionTypes';

import SkyXInformationPage from './SkyXInformationPage';

const mapStateToProps = (state) => ({
  skyXInformation: state.skyx.skyXInformation,
  skyXInformationLoading: state.skyx.getSkyXInformationLoading,
  updateSkyXInformationLoading: state.skyx.updateSkyXInformationLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetSkyXInformation: () => dispatch({ type: GET_SKY_X_INFORMATION }),
  actionClearGetSkyXInformation: () => dispatch({ type: CLEAR_GET_SKY_X_INFORMATION }),

  actionUpdateSkyXInformation: (payload) =>
    dispatch({
      type: UPDATE_SKY_X_INFORMATION,
      payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(SkyXInformationPage);
