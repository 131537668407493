import * as colors from '@ant-design/colors';
import moment from 'moment';

export const LOCATIONS = {
  HOME: '/', // Management
  CONFIGS: '/configs',
  INFOR: '/information',
  COMPANY_SETTING: (slug = ':slug') => `/company-setting/${slug}`,

  VIEW_MODE: '/view-mode',
  ADMIN_VIEW_MODE: `/view-mode/admin/`,
  CLIENT_VIEW_MODE: (slug = ':slug') => `/view-mode/client/${slug}`,

  SIGN_IN: '/sign-in',
};

export const LOCAL_STORAGE_KEYS = {
  TOKEN: 'TOKEN',
  THEME: 'THEME', // for ViewModePage's theme switching
  LIGHT: 'LIGHT',
  DARK: 'DARK',

  APP_LANGUAGE: 'APP_LANGUAGE',
  VIEW_MODE_LANGUAGE: 'VIEW_MODE_LANGUAGE',
};

export const APP_COLORS = {
  BLACK: '#000000',
  WHITE: '#FFFFFF',
  TRANSPARENT: 'transparent',

  GRAY_1: 'rgba(0, 0, 0, 0.45)',
  GRAY_2: 'rgba(0, 0, 0, 0.85)',
  GRAY_3: 'rgba(0, 0, 0, 0.25)',
  GRAY_4: 'rgba(0, 0, 0, 0.06)',
  GRAY_5: 'rgba(255, 255, 255, 0.5)',
  GRAY_6: 'rgba(255, 255, 255, 0.25)',
  GRAY_7: 'rgba(255, 255, 255, 0.05)',
  GRAY_8: '#F0F0F0',

  BLUE_1: colors.blue[0],
  BLUE_5: colors.blue[4],
  BLUE_6: colors.blue[5],
  BLUE_7: colors.blue[6],
  BLUE_8: colors.blue[7],

  NEUTRAL_3: '#F5F5F5',
  NEUTRAL_5: '#D9D9D9',
  NEUTRAL_6: '#BFBFBF',
  NEUTRAL_7: '#8C8C8C',
};

export const THEME_COLORS = {
  LIGHT: {
    colorTextBase: APP_COLORS.BLACK,
    colorBgBase: APP_COLORS.WHITE,
  },
  DARK: {
    colorTextBase: APP_COLORS.WHITE,
    colorBgBase: APP_COLORS.BLACK,
  },
};

export const DROPDOWN_PLACEMENT = {
  TOP: 'top',
  BOTTOM: 'bottom',
  LEFT: 'left',
  RIGHT: 'right',
};

export const SIDER_DIRECTION = {
  MANAGEMENT: {
    label: 'Menu.management_menu',
    key: LOCATIONS.HOME,
    link: LOCATIONS.HOME,
  },
  CONFIGS: {
    label: 'Menu.other_config_menu',
    key: LOCATIONS.CONFIGS,
    link: LOCATIONS.CONFIGS,
  },
  INFOR: {
    label: 'Menu.skyx_infor_menu',
    key: LOCATIONS.INFOR,
    link: LOCATIONS.INFOR,
  },
};

export const VIEW_MODE_OPTIONS = [
  {
    key: 0,
    label: 'View in English',
  },
  {
    key: 1,
    label: 'View in Vietnamese',
  },
];

export const TODAY = moment().format('YYYY-MM-DD');
export const SEVEN_DAYS_AGO = moment().add(-7, 'days').format('YYYY-MM-DD');
export const TEN_DAYS_AGO = moment().add(-10, 'days').format('YYYY-MM-DD');
export const THIRTY_DAYS_AGO = moment().add(-30, 'days').format('YYYY-MM-DD');
export const TWELVE_MONTHS_AGO = moment().add(-11, 'months').format('YYYY-MM-DD');

export const DATE_QUERY_KEY = {
  DAYS_7: 'DAYS_7',
  DAYS_10: 'DAYS_10',
  MONTHS_12: 'MONTHS_12',
  DAYS_30: 'DAYS_30',
};

const RETRIEVE_CHART_PAYLOAD = {
  envelope: true,
  object: [],
  indicator: ['yield.inverter'],
  timeZoneAlign: true,
  replyTz: 'Asia/Ho_Chi_Minh',
  toDate: TODAY,
};

export const RETRIEVE_CHART_7_DAYS_PAYLOAD = {
  ...RETRIEVE_CHART_PAYLOAD,
  granularity: '1-days',
  fromDate: SEVEN_DAYS_AGO,
};

export const RETRIEVE_CHART_10_DAYS_PAYLOAD = {
  ...RETRIEVE_CHART_PAYLOAD,
  granularity: '1-days',
  fromDate: TEN_DAYS_AGO,
};

export const RETRIEVE_CHART_30_DAYS_PAYLOAD = {
  ...RETRIEVE_CHART_PAYLOAD,
  granularity: '1-days',
  fromDate: THIRTY_DAYS_AGO,
};

export const RETRIEVE_CHART_12_MONTHS_PAYLOAD = {
  ...RETRIEVE_CHART_PAYLOAD,
  granularity: '1-months',
  fromDate: TWELVE_MONTHS_AGO,
};

export const TIME_BY_MILLISECONDS = {
  SECOND: 1000,
  MINUTE: 60 * 1000,
  HOUR: 60 * 60 * 1000,
  DAY: 24 * 60 * 60 * 1000,
};

export const LANGUAGES = {
  DEFAULT: 'en',
  en: { title: 'English', value: 'en', key: 'en', label: 'English' },
  vi: { title: 'Tiếng Việt', value: 'vi', key: 'vi', label: 'Tiếng Việt' },
  kr: { title: '한국인', value: 'kr', key: 'kr', label: '한국인' },
  cn: { title: '中文', value: 'cn', key: 'cn', label: '中文' },
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  MEMBER: 'SKY_MEMBER',
  CLIENT: 'CLIENT',
};
