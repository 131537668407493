import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Carousel } from 'antd';
import PropTypes from 'prop-types';

import { DATE_QUERY_KEY } from '@/constants';
import { emptyFn } from '@/utils';
import { useCountdown } from '@/utils/hooks';

import ColumnChart from '../ColumnChart';

import styles from './ColumnChartWrapper.module.scss';

function ColumnChartWrapper(props) {
  const {
    isAdminViewMode,
    isDarkMode,

    retrieveChartPlant7Days,
    retrieveChartPlant10Days,
    retrieveChartPlant30Days,
    retrieveChartPlant12Months,

    // auto play slider
    isPlay,
    isPlayingSlider,
    // countdown,
    onNextPageSlide,

    rotationTime,

    scale,
  } = props;

  const carouselRef = useRef();

  // EX: Countdown for slider (1 year, 7 days, 30 days)
  // TODO: Function stopCountdown for Feature Pause from header.
  const [count, { startCountdown, stopCountdown, resetCountdown, isCountdownRunning }] =
    useCountdown({
      countStart: rotationTime,
    });

  const [carouselIndex, setCarouselIndex] = useState(0);

  useEffect(() => {
    // Handle play/pause
    if (isPlayingSlider) {
      if (isCountdownRunning) {
        stopCountdown();

        if (!count) {
          // Special time in zero, we can't stop
          // DO: Trigger to next slide and pause

          // In last item
          if (carouselIndex === 2) {
            setCarouselIndex(0);
            onNextPageSlide();
            carouselRef?.current?.goTo(0);
            resetCountdown();
          } else {
            setCarouselIndex(carouselIndex + 1);
            carouselRef?.current?.next();
            resetCountdown();
          }
        }
      } else {
        startCountdown();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlay]);

  useEffect(() => {
    if (isPlayingSlider && !!rotationTime && isPlay) {
      resetCountdown();
      startCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlayingSlider, rotationTime]);

  useEffect(() => {
    if (!count && !isCountdownRunning && isPlayingSlider) {
      resetCountdown();

      if (carouselIndex < 2) {
        setCarouselIndex(carouselIndex + 1);
        startCountdown();
        carouselRef?.current?.next();
      } else {
        onNextPageSlide();
        setCarouselIndex(0);
        carouselRef?.current?.goTo(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountdownRunning]);

  // EX: Wrapper by useMemo to cache component re-render
  const Render = useMemo(
    () => (
      <div className={styles.Component}>
        <Carousel
          className={styles.Carousel}
          dots={false}
          ref={carouselRef}
          accessibility={false}
          infinite={false}>
          {isAdminViewMode ? (
            <ColumnChart
              isDarkMode={isDarkMode}
              type={DATE_QUERY_KEY.DAYS_10}
              data={retrieveChartPlant10Days.data}
              scale={scale}
            />
          ) : (
            <ColumnChart
              isDarkMode={isDarkMode}
              type={DATE_QUERY_KEY.DAYS_7}
              data={retrieveChartPlant7Days.data}
              scale={scale}
            />
          )}

          <ColumnChart
            isDarkMode={isDarkMode}
            type={DATE_QUERY_KEY.DAYS_30}
            data={retrieveChartPlant30Days.data}
            scale={scale}
          />

          <ColumnChart
            isDarkMode={isDarkMode}
            type={DATE_QUERY_KEY.MONTHS_12}
            data={retrieveChartPlant12Months.data}
            scale={scale}
          />
        </Carousel>
      </div>
    ),
    [
      isAdminViewMode,
      isDarkMode,
      retrieveChartPlant10Days.data,
      retrieveChartPlant12Months.data,
      retrieveChartPlant30Days.data,
      retrieveChartPlant7Days.data,
      scale,
    ],
  );

  return Render;
}

ColumnChartWrapper.propTypes = {
  isAdminViewMode: PropTypes.bool,
  isDarkMode: PropTypes.bool,

  retrieveChartPlant7Days: PropTypes.instanceOf(Object).isRequired,
  retrieveChartPlant10Days: PropTypes.instanceOf(Object).isRequired,
  retrieveChartPlant30Days: PropTypes.instanceOf(Object).isRequired,
  retrieveChartPlant12Months: PropTypes.instanceOf(Object).isRequired,

  // auto play slider
  isPlayingSlider: PropTypes.bool,
  isPlay: PropTypes.bool,
  // countdown: PropTypes.number,
  onNextPageSlide: PropTypes.func,

  rotationTime: PropTypes.number,

  scale: PropTypes.number,
};

ColumnChartWrapper.defaultProps = {
  isAdminViewMode: false,

  // auto play slider
  isPlay: true,
  isDarkMode: true,
  isPlayingSlider: false,
  // countdown: 0,
  onNextPageSlide: emptyFn,

  rotationTime: 0,

  scale: 1,
};

export default memo(ColumnChartWrapper);
