import { cancelled, fork, put, takeLeading } from 'redux-saga/effects';

import skyxAPI from '@/apis/skyxAPI';
import { watchingSagaCanceled } from '@/utils';

import {
  CLEAR_GET_SKY_X_INFORMATION,
  GET_SKY_X_INFORMATION,
  GET_SKY_X_INFORMATION_FAILED,
  GET_SKY_X_INFORMATION_SUCCESS,
  UPDATE_SKY_X_INFORMATION,
  UPDATE_SKY_X_INFORMATION_FAILED,
  UPDATE_SKY_X_INFORMATION_SUCCESS,
} from './actionTypes';

function* actionGetSkyXInformation() {
  try {
    const response = yield skyxAPI.getSkyXInformation();

    yield put({
      type: GET_SKY_X_INFORMATION_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({ type: GET_SKY_X_INFORMATION_FAILED });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_SKY_X_INFORMATION });
    }
  }
}

function* actionUpdateSkyXInformation({ payload = {} }) {
  const { values, successCallback, errorCallback, finallyCallback } = payload;

  try {
    yield skyxAPI.updateSkyXInformation(values);

    yield put({
      type: UPDATE_SKY_X_INFORMATION_SUCCESS,
    });

    successCallback?.();
  } catch (error) {
    yield put({ type: UPDATE_SKY_X_INFORMATION_FAILED });

    errorCallback?.(error.response.data.errors);
  } finally {
    finallyCallback?.();
  }
}

export default function* skyXSaga() {
  yield takeLeading(GET_SKY_X_INFORMATION, function* getSkyXInformation(payload) {
    yield fork(
      watchingSagaCanceled,
      payload,
      actionGetSkyXInformation,
      CLEAR_GET_SKY_X_INFORMATION,
    );
  });
  yield takeLeading(UPDATE_SKY_X_INFORMATION, actionUpdateSkyXInformation);
}
