import React, { memo, useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import { LOCATIONS } from '@/constants';

function SignInLayout(props) {
  const { token, initApp } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const urlParams = useMemo(() => queryString.parse(location.search || ''), [location.search]);

  useEffect(() => {
    if (token) {
      navigate({ pathname: urlParams.redirect || LOCATIONS.HOME });
    }
  }, [navigate, token, urlParams.redirect]);

  if (!initApp) {
    return (
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <Spin size="large" />
      </div>
    );
  }

  return <Outlet />;
}

SignInLayout.propTypes = {
  token: PropTypes.string,
  initApp: PropTypes.bool,
};

SignInLayout.defaultProps = {
  token: '',
  initApp: false,
};

export default memo(SignInLayout);
