import React, { memo, useEffect, useMemo } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import AppHeader from '@/components/app/AppHeader';
import AppMenu from '@/components/app/AppMenu';

import { LOCATIONS, USER_ROLES } from '@/constants';
import { classnames, isHasData } from '@/utils';

import styles from './DefaultLayout.module.scss';

function DefaultLayout(props) {
  const {
    isEmptyLayout,
    allowRoles,

    initApp,
    userInfo,
    userRole,
  } = props;
  const location = useLocation();
  const navigator = useNavigate();

  const isAuthored = useMemo(() => userInfo?._id, [userInfo]);

  const isAdmin = useMemo(() => userRole === USER_ROLES.ADMIN, [userRole]);

  useEffect(() => {
    if (!isAuthored && initApp) {
      navigator({
        pathname: LOCATIONS.SIGN_IN,
        search: queryString.stringify({
          redirect: location.pathname,
        }),
      });
    }
  }, [initApp, isAuthored, location.pathname, navigator]);

  if (!isAuthored) {
    return (
      <div className="flex-grow-1 d-flex align-items-center justify-content-center">
        <Spin size="large" />
      </div>
    );
  }

  if (isHasData(allowRoles) && !allowRoles.includes(userRole)) {
    return <Navigate to={LOCATIONS.HOME} />;
  }

  if (isEmptyLayout) {
    return <Outlet />;
  }

  return (
    <Layout className={styles.Component}>
      <AppHeader />

      <Layout className={styles.ResetBackground}>
        {isAdmin && (
          <Layout.Sider
            collapsible
            className={classnames(styles.Sider, styles.ResetBackground, 'd-none d-lg-block')}
            width={208}
            trigger={null}>
            <AppMenu />
          </Layout.Sider>
        )}

        <Layout.Content className={styles.Content}>
          <Outlet />
        </Layout.Content>
      </Layout>
    </Layout>
  );
}

DefaultLayout.propTypes = {
  isEmptyLayout: PropTypes.bool,
  allowRoles: PropTypes.arrayOf(PropTypes.string),

  initApp: PropTypes.bool,
  userInfo: PropTypes.instanceOf(Object),
  userRole: PropTypes.string,
};

DefaultLayout.defaultProps = {
  isEmptyLayout: false,
  allowRoles: [],

  initApp: false,
  userInfo: {},
  userRole: '',
};

export default memo(DefaultLayout);
