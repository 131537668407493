import {
  CLEAR_GET_CLIENT_COMPANY,
  CLEAR_GET_COMPANY_INFORMATION,
  CLEAR_GET_RETRIEVE_COMPANIES,
  CLEAR_GET_RETRIEVE_COMPANY_DETAIL,
  CLEAR_GET_RETRIEVE_PLANTS_DETAIL,
  GET_CLIENT_COMPANY,
  GET_CLIENT_COMPANY_FAILED,
  GET_CLIENT_COMPANY_SUCCESS,
  GET_COMPANY_INFORMATION,
  GET_COMPANY_INFORMATION_FAILED,
  GET_COMPANY_INFORMATION_SUCCESS,
  GET_RETRIEVE_COMPANIES,
  GET_RETRIEVE_COMPANIES_FAILED,
  GET_RETRIEVE_COMPANIES_SUCCESS,
  GET_RETRIEVE_COMPANY_DETAIL,
  GET_RETRIEVE_COMPANY_DETAIL_FAILED,
  GET_RETRIEVE_COMPANY_DETAIL_SUCCESS,
  GET_RETRIEVE_PLANTS_DETAIL,
  GET_RETRIEVE_PLANTS_DETAIL_FAILED,
  GET_RETRIEVE_PLANTS_DETAIL_SUCCESS,
  UPDATE_CLIENT_SHOW_SKY_X_INFO,
  UPDATE_CLIENT_SHOW_SKY_X_INFO_FAILED,
  UPDATE_CLIENT_SHOW_SKY_X_INFO_SUCCESS,
  UPDATE_COMPANY_INFORMATION,
  UPDATE_COMPANY_INFORMATION_FAILED,
  UPDATE_COMPANY_INFORMATION_SUCCESS,
} from './actionTypes';

export const initialState = {
  getRetrieveCompaniesLoading: true,
  retrieveCompanies: [],

  getCompanyInformationLoading: false,
  companyInformation: {},

  updateCompanyInformationLoading: false,

  getClientCompanyLoading: false,
  clientCompany: {},

  updateClientShowSkyXInfo: false,

  getRetrievePlantsDetailLoading: false,
  retrievePlantsDetail: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_RETRIEVE_PLANTS_DETAIL:
      return {
        ...state,
        getRetrievePlantsDetailLoading: true,
      };
    case GET_RETRIEVE_PLANTS_DETAIL_SUCCESS: {
      return {
        ...state,
        getRetrievePlantsDetailLoading: false,
        retrievePlantsDetail: action.payload,
      };
    }
    case GET_RETRIEVE_PLANTS_DETAIL_FAILED:
    case CLEAR_GET_RETRIEVE_PLANTS_DETAIL:
      return {
        ...state,
        getRetrievePlantsDetailLoading: false,
        retrievePlantsDetail: [],
      };

    case GET_RETRIEVE_COMPANIES: {
      return {
        ...state,
        getRetrieveCompaniesLoading: true,
      };
    }

    case GET_RETRIEVE_COMPANIES_SUCCESS: {
      return {
        ...state,
        getRetrieveCompaniesLoading: false,
        retrieveCompanies: action.payload,
      };
    }

    case GET_RETRIEVE_COMPANIES_FAILED:
    case CLEAR_GET_RETRIEVE_COMPANIES: {
      return {
        ...state,
        getRetrieveCompaniesLoading: false,
        retrieveCompanies: [],
      };
    }

    case GET_COMPANY_INFORMATION: {
      return {
        ...state,
        getCompanyInformationLoading: true,
      };
    }

    case GET_COMPANY_INFORMATION_SUCCESS: {
      return {
        ...state,
        getCompanyInformationLoading: false,
        companyInformation: action.payload,
      };
    }

    case GET_COMPANY_INFORMATION_FAILED:
    case CLEAR_GET_COMPANY_INFORMATION: {
      return {
        ...state,
        companyInformation: {},
        getCompanyInformationLoading: false,
      };
    }

    case UPDATE_COMPANY_INFORMATION: {
      return {
        ...state,
        updateCompanyInformationLoading: true,
      };
    }

    case UPDATE_COMPANY_INFORMATION_SUCCESS:
    case UPDATE_COMPANY_INFORMATION_FAILED: {
      return {
        ...state,
        updateCompanyInformationLoading: false,
      };
    }

    case GET_RETRIEVE_COMPANY_DETAIL:
    case GET_CLIENT_COMPANY: {
      return {
        ...state,
        getClientCompanyLoading: true,
      };
    }

    case GET_RETRIEVE_COMPANY_DETAIL_SUCCESS:
    case GET_CLIENT_COMPANY_SUCCESS: {
      return {
        ...state,
        getClientCompanyLoading: false,
        clientCompany: action.payload,
      };
    }

    case GET_RETRIEVE_COMPANY_DETAIL_FAILED:
    case CLEAR_GET_RETRIEVE_COMPANY_DETAIL:
    case GET_CLIENT_COMPANY_FAILED:
    case CLEAR_GET_CLIENT_COMPANY: {
      return {
        ...state,
        clientCompany: {},
        getClientCompanyLoading: false,
      };
    }

    case UPDATE_CLIENT_SHOW_SKY_X_INFO: {
      return {
        ...state,
        updateClientShowSkyXInfo: true,
      };
    }

    case UPDATE_CLIENT_SHOW_SKY_X_INFO_SUCCESS: {
      return {
        ...state,
        updateClientShowSkyXInfo: false,
        clientCompany: { ...state.clientCompany, ...action.payload },
      };
    }

    case UPDATE_CLIENT_SHOW_SKY_X_INFO_FAILED: {
      return {
        ...state,
        updateClientShowSkyXInfo: false,
      };
    }

    default: {
      return state;
    }
  }
};
