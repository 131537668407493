import { connect } from 'react-redux';

import { CLEAR_GET_CONFIG, GET_CONFIG, UPDATE_CONFIG } from '@/store/config/actionTypes';

import ConfigsPage from './ConfigsPage';

const mapStateToProps = (state) => ({
  config: state.config.config,
  getConfigLoading: state.config.getConfigLoading,

  updateConfigLoading: state.config.updateConfigLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetConfig: (payload) => dispatch({ type: GET_CONFIG, payload }),
  actionClearGetConfig: () => dispatch({ type: CLEAR_GET_CONFIG, payload: {} }),

  actionUpdateConfig: (payload) => dispatch({ type: UPDATE_CONFIG, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigsPage);
