import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import AppButton from '@/components/app/AppButton';
import AppLanguages from '@/components/app/AppLanguages';
// import AppDropdown from '@/components/app/AppDropdown';
import AppLink from '@/components/app/AppLink';
import AppLogo from '@/components/app/AppLogo';
// import ViewModeLabel from '@/components/ViewModeLabel';
import MobileNavigation from '@/components/MobileNavigation';

import { ReactComponent as ExpandIcon } from '@/assets/icons/expand.svg';
import { ReactComponent as LogOutIcon } from '@/assets/icons/log-out.svg';
import { LOCATIONS, USER_ROLES } from '@/constants';
import { t } from '@/languages';
import { classnames, emptyFn, get } from '@/utils';
import { useEventListener } from '@/utils/hooks';

import styles from './AppHeader.module.scss';

function AppHeader(props) {
  const { userRole, actionSignOut, clientCompany } = props;

  const [additionClass, setAdditionClass] = useState(null);

  const HeaderTitle = useMemo(() => {
    switch (true) {
      case USER_ROLES.ADMIN === userRole: {
        return t('Layout.Header.admin');
      }

      case userRole === USER_ROLES.MEMBER: {
        return t('Layout.Header.member');
      }

      case userRole === USER_ROLES.CLIENT: {
        return get(clientCompany, 'en.name', clientCompany.username);
      }

      default: {
        return null;
      }
    }
  }, [clientCompany, userRole]);

  // EX: Error when using useMemo, i'll render 'Layout.Header.btn...' when redirect viewMode to homepage
  const ButtonViewModeLabel = useMemo(
    () => t(`Layout.Header.btn.${userRole === USER_ROLES.CLIENT ? 'viewModeClient' : 'viewMode'}`),
    [userRole],
  );

  const viewModeRedirectUrl = useMemo(
    () =>
      USER_ROLES.CLIENT === userRole
        ? LOCATIONS.CLIENT_VIEW_MODE(clientCompany?._id ?? '')
        : LOCATIONS.ADMIN_VIEW_MODE,
    [clientCompany?._id, userRole],
  );

  const onScrolling = useCallback(() => {
    const currentTop = document.body.getBoundingClientRect().top * -1;

    if (currentTop > 0) {
      setAdditionClass(styles.Scrolling);
    } else {
      setAdditionClass(null);
    }
  }, []);

  useEffect(() => {
    onScrolling();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEventListener('scroll', onScrolling);

  return (
    <Layout.Header className={classnames(styles.Component, additionClass)}>
      <AppLink to={LOCATIONS.HOME}>
        <AppLogo className={styles.Logo} />
      </AppLink>

      <div className={styles.Title}>{HeaderTitle}</div>

      <div className="d-none d-lg-flex align-items-center">
        {/* <AppDropdown
          label={<ViewModeLabel text={t('Layout.Header.btn.viewMode')} />}
          items={VIEW_MODE_OPTIONS}
          onClickItem={onClickViewSkyX}
          className={styles.Dropdown}
          overlayClassName={styles.ViewModeBtn}
        /> */}

        <AppLink to={viewModeRedirectUrl}>
          <AppButton className={classnames(styles.ViewSkyXBtn, 'ant-btn-primary border-0')}>
            <ExpandIcon />

            {ButtonViewModeLabel}
          </AppButton>
        </AppLink>

        <AppLanguages className="mx-3" />

        <LogOutIcon onClick={actionSignOut} className={styles.LogOut} />
      </div>

      <div className="d-lg-none">
        <MobileNavigation
          userRole={userRole}
          actionSignOut={actionSignOut}
          viewModeRedirectUrl={viewModeRedirectUrl}
          buttonViewModeLabel={ButtonViewModeLabel}
        />
      </div>
    </Layout.Header>
  );
}

AppHeader.propTypes = {
  userRole: PropTypes.string,
  actionSignOut: PropTypes.func,

  clientCompany: PropTypes.instanceOf(Object),
};

AppHeader.defaultProps = {
  userRole: null,
  actionSignOut: emptyFn,

  clientCompany: {},
};

export default memo(AppHeader);
