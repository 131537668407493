import React, { memo, useCallback } from 'react';
import { Form, message } from 'antd';
import PropTypes from 'prop-types';

import AppButton from '@/components/app/AppButton';
import AppInput from '@/components/app/AppInput';

import { t } from '@/languages';
import { emptyFn } from '@/utils';

import validate from './validate';

function FormSignIn(props) {
  const { signInLoading, actionSignIn } = props;
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const onCallbackSuccess = useCallback(() => {
    messageApi.open({
      type: 'success',
      content: t('app.alert.sign_in_success'),
    });
  }, [messageApi]);

  const onCallbackError = useCallback(() => {
    form.setFields(validate.callbackError);
  }, [form]);

  const onFinish = useCallback(
    (values) => {
      actionSignIn({
        values,
        successCallback: onCallbackSuccess,
        errorCallback: onCallbackError,
      });
    },
    [actionSignIn, onCallbackError, onCallbackSuccess],
  );

  return (
    <>
      {contextHolder}

      <Form
        scrollToFirstError
        requiredMark={false}
        name="FormSignIn"
        layout="vertical"
        form={form}
        disabled={signInLoading}
        onFinish={onFinish}
        initialValues={validate.initial}>
        <AppInput
          label={t('SignInPage.form.username')}
          name="username"
          placeholder={t('SignInPage.form.usernamePlaceholder')}
          rules={validate.username}
        />

        <AppInput
          custom="Password"
          label={t('SignInPage.form.password')}
          name="password"
          placeholder={t('SignInPage.form.passwordPlaceholder')}
          rules={validate.password}
        />

        <Form.Item className="mb-0">
          <AppButton type="primary" className="w-100" htmlType="submit" loading={signInLoading}>
            {t('SignInPage.form.button')}
          </AppButton>
        </Form.Item>
      </Form>
    </>
  );
}

FormSignIn.propTypes = {
  signInLoading: PropTypes.bool,
  actionSignIn: PropTypes.func,
};

FormSignIn.defaultProps = {
  signInLoading: false,
  actionSignIn: emptyFn,
};

export default memo(FormSignIn);
