import React, { memo, useMemo } from 'react';
import { connect } from 'react-redux';
import { Column } from '@ant-design/plots';
import { Spin } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import { DATE_QUERY_KEY, TODAY } from '@/constants';
import { t } from '@/languages';
import { classnames, isEmpty, isHasData } from '@/utils';

// import { useElementSize } from '@/utils/hooks';
import ColumnChartTitle from '../ColumnChartTitle';

import styles from './ColumnChart.module.scss';

const FILTER_PLANT_CHART = {
  YIELD_INVERTER: 'YIELD_INVERTER',
  IRRADIATION_ACTUAL: 'IRRADIATION_ACTUAL',
};

const CURRENT_PLANT_CHART_FILTER = '';

const columnStyleConfig = { radius: [12, 12] };

function ColumnChart(props) {
  const {
    isDarkMode,

    type,

    data,

    scale,
  } = props;

  // const [columnChartWrapperRef, columnChartWrapperSize] = useElementSize();

  const colorPrimary = useMemo(() => (isDarkMode ? '#FFFFFF' : '#11235F'), [isDarkMode]);

  const convertData = useMemo(
    () =>
      data
        .map((item) => ({
          timestamp: item[0] ?? 0,
          indicators: item[1].map((i) => ({
            yieldInverter: i[0] ?? 0,
            irradiationActual: i[1] ?? 0,
          })),
        }))
        .filter((item, index) => {
          if (type === DATE_QUERY_KEY.DAYS_30) {
            return index % 2 === (new Date(TODAY).getDate() % 2 ? 0 : 1); // EX: Check even or odd
          }

          return item;
        })
        .map((item, index) => {
          let value = 0;

          switch (CURRENT_PLANT_CHART_FILTER) {
            case FILTER_PLANT_CHART.IRRADIATION_ACTUAL:
              value = item.indicators.reduce(
                (acc, indicator) => acc + indicator.irradiationActual,
                0,
              );
              break;

            case FILTER_PLANT_CHART.YIELD_INVERTER:
              value = item.indicators.reduce((acc, indicator) => acc + indicator.yieldInverter, 0);
              break;

            default:
              value = item.indicators.reduce(
                (acc, indicator) => acc + (indicator.yieldInverter + indicator.irradiationActual),
                0,
              );
              break;
          }

          let month = new Date(item.timestamp);

          if (type === DATE_QUERY_KEY.MONTHS_12) {
            month = moment(month).format('MMM');
          } else {
            month = month.getDate();
            month = `${month < 10 ? '0' : ''}${month}`;
          }

          if (type === DATE_QUERY_KEY.DAYS_30 && index === 0) {
            // EX: Because library of ant check key to render
            // in case 30 days => ex: days 1 => days 1 next month, so it will be same the key
            // so we trigger days 1 in first month add space => every item not same key more
            return {
              month: `${month} `,
              value: Math.floor(value),
            };
          }

          return {
            month,
            value: Math.floor(value),
          };
        }),
    [data, type],
  );

  const dataForecast = useMemo(() => [...convertData].reverse().slice(0, 3), [convertData]);

  const columnHighestValue = useMemo(() => {
    let columnDefault = { value: 0 };

    convertData.forEach((column) => {
      if (column.value > columnDefault.value) {
        columnDefault = column;
      }
    });

    return columnDefault;
  }, [convertData]);

  const xAxisConfig = useMemo(
    () => ({
      tickLine: null,
      line: {
        style: {
          strokeOpacity: 0,
        },
      },
      label: {
        style: {
          fill: colorPrimary,
          fontSize: 10 * scale,
        },
      },
    }),
    [colorPrimary, scale],
  );

  const yAxisConfig = useMemo(
    () => ({
      grid: {
        line: {
          style: {
            stroke: colorPrimary,
            strokeOpacity: 0.05,
          },
        },
      },
      label: {
        style: {
          fill: colorPrimary,
          fontSize: 10 * scale,
        },
        formatter: (text) => {
          const numberConvert = Number(text);
          let format = 0;
          const isLastItem = numberConvert >= columnHighestValue.value;

          switch (true) {
            case isLastItem:
              return 'kWh';

            case numberConvert >= 1000000:
              format = Math.floor(numberConvert / 1000000);
              return `${format}m`;

            case numberConvert >= 1000:
              format = Math.floor(numberConvert / 1000);
              return `${format}k`;

            default:
              return text;
          }
        },
      },
      tickInterval: Math.ceil(columnHighestValue.value / 3.8),
    }),
    [colorPrimary, columnHighestValue.value, scale],
  );

  const color = useMemo(
    () => (attr) => {
      const defaultColor = '#05A0F8';
      const foreCastColor = '#ECAE2F';

      if (attr && type === DATE_QUERY_KEY.DAYS_7 && convertData.length > 7) {
        const isForecast = dataForecast.find((item) => item.month === attr.month);

        return isForecast ? foreCastColor : defaultColor; // month is xField of data
      }

      return defaultColor;
    },
    [convertData.length, dataForecast, type],
  );

  return (
    <div className={classnames(styles.Component, isDarkMode && styles.DarkMode)}>
      <div className={styles.ChartColumn}>
        <div className={styles.Wrapper}>
          <div className={styles.Header}>
            <div className={styles.Title}>
              {t('ViewMode.chart.column_chart.title', { unit: 'kWh' })}
            </div>

            <div className={styles.Time}>
              <ColumnChartTitle type={type} />
            </div>
          </div>

          <div className={classnames(styles.Body, !isHasData(data) && styles.Loading)}>
            {!isHasData(data) && (
              <div className={styles.Spin}>
                <Spin size="large" />
              </div>
            )}

            {!isEmpty(data) && (
              <div className={styles.ChartWrapper} key={scale}>
                <Column
                  data={convertData}
                  xField="month"
                  yField="value"
                  renderer="svg"
                  // height={columnChartWrapperSize.height}
                  // width={columnChartWrapperSize.width}
                  columnStyle={columnStyleConfig}
                  maxColumnWidth={12 * scale}
                  minColumnWidth={12 * scale}
                  xAxis={xAxisConfig}
                  yAxis={yAxisConfig}
                  color={color}
                  tooltip={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

ColumnChart.propTypes = {
  isDarkMode: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  data: PropTypes.instanceOf(Array),

  scale: PropTypes.number,
};

ColumnChart.defaultProps = {
  data: [],

  scale: 1,
};

// Ex: Make component rerender when language has changed
export default memo(
  connect(
    (state) => ({
      viewModeLanguage: state.dataViewMode.viewModeLanguage,
    }),
    null,
  )(ColumnChart),
);
