import React, { memo, useCallback, useMemo } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import AppInput from '@/components/app/AppInput';

import { t } from '@/languages';
import { debounce, emptyFn } from '@/utils';

import validate from './validate';

import styles from './FormSearchCompany.module.scss';

function FormSearchCompany(props) {
  const { actionGetRetrieveCompanies } = props;

  const [form] = Form.useForm();

  const onFinish = useCallback(() => {
    actionGetRetrieveCompanies({
      query: {
        search: form.getFieldValue('search'),
      },
    });
  }, [actionGetRetrieveCompanies, form]);

  const inputOptions = useMemo(
    () => ({ allowClear: true, onSearch: debounce(onFinish, 300) }),
    [onFinish],
  ); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Form
      name="FormSearchCompany"
      form={form}
      onFinish={onFinish}
      initialValues={validate.initial}
      className={styles.Form}>
      <AppInput
        name="search"
        custom="Search"
        placeholder={t('HomePage.form.searchPlaceholder')}
        inputOptions={inputOptions}
        labelClass={styles.FormLabel}
        inputClass={styles.FormInput}
      />
    </Form>
  );
}

FormSearchCompany.propTypes = {
  actionGetRetrieveCompanies: PropTypes.func,
};

FormSearchCompany.defaultProps = {
  actionGetRetrieveCompanies: emptyFn,
};

export default memo(FormSearchCompany);
