import {
  CLEAR_GET_RETRIEVE_WEATHER_BY_LOCATION,
  GET_RETRIEVE_WEATHER_BY_LOCATION,
  GET_RETRIEVE_WEATHER_BY_LOCATION_FAILED,
  GET_RETRIEVE_WEATHER_BY_LOCATION_SUCCESS,
} from './actionTypes';

export const initialState = {
  retrieveWeatherByLocation: {},
  getRetrieveWeatherByLocationLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    // GET_SKY_X_INFORMATION
    case GET_RETRIEVE_WEATHER_BY_LOCATION:
      return {
        ...state,
        getRetrieveWeatherByLocationLoading: true,
      };
    case GET_RETRIEVE_WEATHER_BY_LOCATION_SUCCESS:
      return {
        ...state,
        getRetrieveWeatherByLocationLoading: false,
        retrieveWeatherByLocation: action.payload,
      };
    case CLEAR_GET_RETRIEVE_WEATHER_BY_LOCATION: {
      return {
        ...state,
        retrieveWeatherByLocation: {},
        getRetrieveWeatherByLocationLoading: false,
      };
    }
    case GET_RETRIEVE_WEATHER_BY_LOCATION_FAILED:
      return {
        ...state,
        getRetrieveWeatherByLocationLoading: false,
      };

    default: {
      return state;
    }
  }
};
