import React, { useCallback, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Layout } from 'antd';
import PropTypes from 'prop-types';

import FormInformation from '@/components/forms/FormInformation';

import { emptyFn } from '@/utils';

import styles from './CompanySettingPage.module.scss';

function CompanySettingPage(props) {
  const {
    companyInformation,
    companyInformationLoading,
    actionGetCompanyInformation,
    actionClearGetCompanyInformation,

    updateCompanyInformationLoading,
    actionUpdateCompanyInformation,
  } = props;

  const params = useParams();

  const onSubmitForm = useCallback(
    (formData, { successCallback, errorCallback }) => {
      const values = { ...formData, id: params.slug };

      actionUpdateCompanyInformation({
        values,
        successCallback,
        errorCallback,
      });
    },
    [actionUpdateCompanyInformation, params.slug],
  );

  useEffect(() => {
    actionGetCompanyInformation({ values: params.slug });

    return () => {
      actionClearGetCompanyInformation();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.slug]);

  return (
    <Layout.Content className={styles.Page}>
      <Helmet>
        <title>Company detail - SkyX’s Admin</title>
      </Helmet>

      <FormInformation
        information={companyInformation}
        loading={companyInformationLoading}
        onSubmitForm={onSubmitForm}
        updateLoading={updateCompanyInformationLoading}
      />
    </Layout.Content>
  );
}

CompanySettingPage.propTypes = {
  companyInformation: PropTypes.instanceOf(Object),
  companyInformationLoading: PropTypes.bool,
  actionGetCompanyInformation: PropTypes.func,
  actionClearGetCompanyInformation: PropTypes.func,

  updateCompanyInformationLoading: PropTypes.bool,
  actionUpdateCompanyInformation: PropTypes.func,
};

CompanySettingPage.defaultProps = {
  companyInformation: {},
  companyInformationLoading: false,
  actionGetCompanyInformation: emptyFn,
  actionClearGetCompanyInformation: emptyFn,

  updateCompanyInformationLoading: false,
  actionUpdateCompanyInformation: emptyFn,
};

export default CompanySettingPage;
