import { cancelled, fork, put, takeLeading } from 'redux-saga/effects';

import chartAPI from '@/apis/chartApi';
import weatherAPI from '@/apis/weatherAPI';
import { RETRIEVE_CHART_7_DAYS_PAYLOAD } from '@/constants';
import { watchingSagaCanceled } from '@/utils';

import { GET_RETRIEVE_CHART_PLANT_7_DAYS_SUCCESS } from '../chart/actionTypes';

import {
  CLEAR_GET_RETRIEVE_WEATHER_BY_LOCATION,
  GET_RETRIEVE_WEATHER_BY_LOCATION,
  GET_RETRIEVE_WEATHER_BY_LOCATION_FAILED,
  GET_RETRIEVE_WEATHER_BY_LOCATION_SUCCESS,
} from './actionTypes';

function* actionGetRetrieveWeatherByLocation({ payload = {} }) {
  const { refs, lat, lon } = payload;

  try {
    const response = yield weatherAPI.getRetrieveWeatherByLocation({ lat, lon });

    yield put({
      type: GET_RETRIEVE_WEATHER_BY_LOCATION_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    const response = yield chartAPI.getRetrieveChartPlants({
      ...RETRIEVE_CHART_7_DAYS_PAYLOAD,
      object: refs,
    });

    yield put({
      type: GET_RETRIEVE_CHART_PLANT_7_DAYS_SUCCESS,
      payload: response.payload,
    });

    yield put({ type: GET_RETRIEVE_WEATHER_BY_LOCATION_FAILED });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_RETRIEVE_WEATHER_BY_LOCATION });
    }
  }
}

export default function* weatherSaga() {
  yield takeLeading(
    GET_RETRIEVE_WEATHER_BY_LOCATION,
    function* getRetrieveWeatherByLocation(payload) {
      yield fork(
        watchingSagaCanceled,
        payload,
        actionGetRetrieveWeatherByLocation,
        CLEAR_GET_RETRIEVE_WEATHER_BY_LOCATION,
      );
    },
  );
}
