import { LOCATIONS } from '@/constants';
import { t } from '@/languages';

export const breadcrumb = [
  {
    label: t('CompanySettingPage.breadcrumb.companyManagement'),
    to: LOCATIONS.HOME,
  },
  {
    label: t('CompanySettingPage.breadcrumb.companyDetails'),
  },
];

export const layout = {
  information: {
    xl: {
      span: 12,
    },
  },
  video: {
    xl: {
      span: 12,
    },
  },
};

export const formConfig = {
  initial: {
    name_en: '',
    description_en: '',
    name_vi: '',
    description_vi: '',
    name_kr: '',
    description_kr: '',
    description_cntw: '',
    name_cntw: '',
    electricity_usage: '',
    beneficial_vnd: '',
    beneficial_usd: '',
  },

  rules: {
    // Company
    name_en: [
      {
        required: true,
        message: t('validate.required'),
      },
    ],
    description_en: [
      {
        required: true,
        message: t('validate.required'),
      },
    ],

    electricity_usage: [
      {
        required: true,
        message: t('validate.required'),
      },
    ],

    // Skyx
    headline_en: [
      {
        required: true,
        message: t('validate.required'),
      },
    ],
    contact_phone: [
      {
        validator: (_, value) => {
          if (value) {
            return /^[0-9]+$/.test(value)
              ? Promise.resolve()
              : Promise.reject(new Error(t('validate.phoneNumber')));
          }

          return Promise.reject(new Error(t('validate.required')));
        },
      },
    ],
    contact_email: [
      {
        required: true,
        message: t('validate.required'),
      },
      {
        type: 'email',
        message: t('validate.email'),
      },
    ],
    contact_website: [
      {
        required: true,
        message: t('validate.required'),
      },
    ],

    // Both
    beneficial_vnd: [
      {
        required: true,
        message: t('validate.required'),
      },
    ],
    beneficial_usd: [
      {
        required: true,
        message: t('validate.required'),
      },
    ],

    video: [
      {
        required: true,
        message: t('validate.required'),
      },
    ],
  },

  options: {
    name_en: { required: true },
    description_en: { required: true },

    textarea: { showCount: true, maxLength: 500, autoSize: { minRows: 3, maxRows: 10 } },

    headline_en: { required: true },
    contact_phone: { required: true },
    contact_email: { required: true },
    contact_website: { required: true },
  },
};
