/* eslint-disable react/jsx-props-no-spreading */
import React, { memo, useCallback } from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';

import { classnames, emptyFn } from '@/utils';

import styles from './AppButton.module.scss';

function AppButton(props) {
  const { isSmall, className, children, value, onClick: onClickProps, disabled, ...rest } = props;

  const onClick = useCallback(() => {
    onClickProps(value);
  }, [onClickProps, value]);

  return (
    <Button
      className={classnames(styles.Btn, isSmall && styles.BtnSmall, className)}
      disabled={disabled}
      {...rest}
      onClick={onClick}>
      {children}
    </Button>
  );
}

AppButton.propTypes = {
  isSmall: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
    PropTypes.array,
    PropTypes.func,
  ]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

AppButton.defaultProps = {
  isSmall: false,
  className: '',

  value: null,
  onClick: emptyFn,
  disabled: false,
};

export default memo(AppButton);
