import { removeEmptyDataInObject } from '@/utils';
import axiosClient from '@/utils/axios';

const endPoint = '/charts';

export default {
  async getRetrieveChartPlants({
    envelope,
    object,
    indicator,
    granularity,
    fromDate,
    timeZoneAlign,
    replyTz,
    toDate,
    ref,
  }) {
    const params = removeEmptyDataInObject({ envelope, ref });
    const searchParams = new URLSearchParams(params).toString();

    const path = `${endPoint}/plant?${searchParams}`;

    const response = await axiosClient.post(path, {
      object,
      indicator,
      granularity,
      from_date: fromDate,
      'time-zone-align': timeZoneAlign,
      'reply-tz': replyTz,
      to_date: toDate,
    });

    return response.data;
  },
};
