import React, { memo, useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import AppDropdown from '@/components/app/AppDropdown';

import { ReactComponent as LanguageIcon } from '@/assets/icons/language.svg';
import { ReactComponent as MoonIcon } from '@/assets/icons/moon.svg';
import { ReactComponent as PauseIcon } from '@/assets/icons/pause.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/play.svg';
import { ReactComponent as SunIcon } from '@/assets/icons/sun-1.svg';
import { LANGUAGES } from '@/constants';
import { t } from '@/languages';
import { classnames, debounce, emptyFn } from '@/utils';
import { useEventListener } from '@/utils/hooks';

import { languageButtonOptions, languageDropdownOptions, languageOptions } from './config';
import Fullscreen from './Fullscreen';

import styles from './OverlayToolbar.module.scss';

let pressing = false;

function OverlayToolbar(props) {
  const {
    isDarkMode,
    actionToggleDarkMode,

    isPlay,
    onTogglePlay,

    viewModeLanguage,
    actionSetViewModeLanguage,
  } = props;

  const wrapperRef = useRef(null);
  const overlayRef = useRef(null);

  const [isShow, setIsShow] = useState(false);

  const onToggleDarkMode = useCallback(() => {
    actionToggleDarkMode(!isDarkMode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionToggleDarkMode, isDarkMode]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onHideHeader = useCallback(
    debounce(() => {
      setIsShow(false);
    }, 2000),
    [],
  );

  const onShowHeader = useCallback(() => {
    setIsShow(true);

    onHideHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeLanguage = useCallback(
    (e) => {
      if (e.key !== viewModeLanguage) {
        actionSetViewModeLanguage(e.key);
      }
    },
    [viewModeLanguage, actionSetViewModeLanguage],
  );

  const onPointerDown = useCallback(
    (e) => {
      if (e.pointerType !== 'mouse') {
        // Ex: When user click, 1. Overlay, 2. Wrapper, 3. Children in wrapper
        // So we need just hidden when user click Overlay or Wrapper to good UX
        if (isShow && (e.target === overlayRef.current || e.target === wrapperRef.current)) {
          pressing = true;
          setIsShow(false);
        } else {
          // Ex: Children click and debounce wrapper hidden in 2s later
          onHideHeader();
        }
      }
    },
    [isShow, onHideHeader],
  );

  const onMouseMove = useCallback(() => {
    // Ex: Can't check by isShow because setIsShow is Async and not work to Await
    if (pressing) {
      pressing = false;
      setIsShow(false);
    } else {
      onShowHeader();
    }
  }, [onShowHeader]);

  useEventListener('mousemove', onMouseMove);
  useEventListener('pointerdown', onPointerDown);

  return (
    <div
      ref={overlayRef}
      className={classnames(
        styles.Component,
        isDarkMode && styles.DarkMode,
        isShow && styles.Show,
      )}>
      <div className={styles.Wrapper} ref={wrapperRef}>
        <div className={styles.Left}>
          <AppDropdown
            isNotShowIconArrow
            trigger="hover"
            className={styles.Language}
            overlayClassName={styles.LanguageOverlay}
            items={languageOptions}
            onClickItem={onChangeLanguage}
            label={<LanguageIcon />}
            buttonOptions={languageButtonOptions}
            dropdownOptions={languageDropdownOptions}
          />

          <div className={classnames(styles.Theme, 'd-none')}>
            <span className={classnames(styles.Text, !isDarkMode && styles.Dark)}>
              {t('app.dark_mode')}
            </span>

            <button type="button" className={styles.Switch} onClick={onToggleDarkMode}>
              <span className={styles.Icon}>{isDarkMode ? <MoonIcon /> : <SunIcon />}</span>
            </button>

            <span className={classnames(styles.Text, isDarkMode && styles.Dark)}>
              {t('app.light_mode')}
            </span>
          </div>
        </div>

        <div className={styles.Right}>
          <button type="button" onClick={onTogglePlay}>
            {isPlay ? <PauseIcon /> : <PlayIcon />}
          </button>

          {document.fullscreenEnabled && <Fullscreen />}
        </div>
      </div>
    </div>
  );
}

OverlayToolbar.propTypes = {
  isDarkMode: PropTypes.bool,
  actionToggleDarkMode: PropTypes.func,

  isPlay: PropTypes.bool,
  onTogglePlay: PropTypes.func,

  viewModeLanguage: PropTypes.string,
  actionSetViewModeLanguage: PropTypes.func,
};

OverlayToolbar.defaultProps = {
  isDarkMode: true,
  actionToggleDarkMode: emptyFn,

  isPlay: true,
  onTogglePlay: emptyFn,

  viewModeLanguage: LANGUAGES.DEFAULT,
  actionSetViewModeLanguage: emptyFn,
};

export default memo(OverlayToolbar);
