import debounce from 'lodash/debounce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import isObject from 'lodash/isObject';
import { cancel, fork, take } from 'redux-saga/effects';

export { debounce, get, isEmpty, isEqual, isNumber };

export function isHasData(value) {
  return !isEmpty(value);
}

export function classnames(...props) {
  return props.filter((className) => !!className).join(' ');
}

export function emptyFn() {
  return null;
}

export const emptyObject = {};

export function hexToRgba(hex, alpha = 1) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export const removeEmptyDataInObject = (object) =>
  // eslint-disable-next-line no-return-assign, no-param-reassign
  Object.entries(object).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {});

export function formatNumber(num, { maximumFractionDigits = 0 } = {}) {
  if (!num) {
    return 0;
  }

  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: true,
    maximumFractionDigits,
  }).format(num);
}

export function existedVal(value) {
  return value !== null && value !== undefined;
}

export async function toggleFullScreen() {
  if (!document.fullscreenElement) {
    await document.documentElement.requestFullscreen();
  } else if (document.exitFullscreen) {
    await document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    await document.webkitExitFullscreen();
  }
}

export const isDevelopment = process.env.REACT_APP_ENVIRONMENT === 'development';

export const changePropsToArray = (...path) => path;

export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    // eslint-disable-next-line no-restricted-syntax
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: target[key] || source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function* watchingSagaCanceled(payload, actionGetData, clearDataType) {
  // EX: Fork is non blocking, so we don't need actionGetData finished
  // if we using call in here, we can't go to next step ( take: CLEAR ) because call is blocking
  const bgSyncTask = yield fork(actionGetData, payload);
  yield take(clearDataType);
  yield cancel(bgSyncTask);
}
