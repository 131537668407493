import { t } from '@/languages';

export default {
  initial: {
    username: '',
    password: '',
  },

  username: [
    {
      required: true,
      message: t('validate.required'),
    },
    {
      validator: (_, value) =>
        !value.includes(' ')
          ? Promise.resolve()
          : Promise.reject(new Error(t('validate.noSpaceAllowed'))),
    },
  ],
  password: [
    {
      required: true,
      message: t('validate.required'),
    },
  ],

  callbackError: [
    {
      name: 'username',
      errors: [''],
    },
    {
      name: 'password',
      errors: [t('api.error.40101')],
    },
  ],
};
