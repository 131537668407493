import { connect } from 'react-redux';

import { GET_RETRIEVE_COMPANIES } from '@/store/manager/actionTypes';

import FormSearchCompany from './FormSearchCompany';

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  actionGetRetrieveCompanies: (payload) => ({ type: GET_RETRIEVE_COMPANIES, payload }),
};

export default connect(mapStateToProps, mapDispatchToProps)(FormSearchCompany);
