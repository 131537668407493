import { connect } from 'react-redux';

import DefaultLayout from './DefaultLayout';

const mapStateToProps = (state) => ({
  initApp: state.app.initApp,
  userInfo: state.user.userInfo,
  userRole: state.user.userRole,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
