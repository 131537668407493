import React, { memo, useCallback } from 'react';
import ReactPlayer from 'react-player/youtube';
import PropTypes from 'prop-types';

import { classnames, emptyFn } from '@/utils';
import { useBoolean } from '@/utils/hooks';

import styles from './VideoScreen.module.scss';

// TODO: make a loading screen when video is on load
function VideoScreen(props) {
  const {
    isDarkMode,
    isPlayingVideo,
    video,
    onVideoDone,
    isPlay,
    onTriggerPlayTrue,
    onTriggerPlayFalse,
  } = props;

  const onEnded = useCallback(() => {
    onVideoDone();
  }, [onVideoDone]);
  const { value: isVideoReady, setTrue: onSetVideoReady } = useBoolean(false);

  const onVideoReady = useCallback(() => onSetVideoReady(), [onSetVideoReady]);

  const onVideoPlay = useCallback(() => {
    // Handle user play => pause when video start
    if (!isPlay) {
      onTriggerPlayTrue();
      setTimeout(onTriggerPlayFalse, 100);
    }
  }, [isPlay, onTriggerPlayFalse, onTriggerPlayTrue]);

  if (!isPlayingVideo) {
    return <div className="w-100 h-100" />;
  }

  return (
    <div className={classnames(styles.Component, isDarkMode && styles.DarkMode)}>
      <ReactPlayer
        key={video}
        url={video}
        onEnded={onEnded}
        playing={isPlay && isVideoReady && isPlayingVideo}
        onPlay={onVideoPlay}
        onReady={onVideoReady}
        muted
      />
    </div>
  );
}

VideoScreen.propTypes = {
  isDarkMode: PropTypes.bool,
  isPlayingVideo: PropTypes.bool,
  isPlay: PropTypes.bool,
  video: PropTypes.string,
  onVideoDone: PropTypes.func,
  onTriggerPlayTrue: PropTypes.func,
  onTriggerPlayFalse: PropTypes.func,
};

VideoScreen.defaultProps = {
  isDarkMode: true,
  isPlayingVideo: false,
  isPlay: false,
  video: '',
  onVideoDone: emptyFn,
  onTriggerPlayTrue: emptyFn,
  onTriggerPlayFalse: emptyFn,
};

export default memo(VideoScreen);
