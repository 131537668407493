import { connect } from 'react-redux';

import ColumnChartWrapper from './ColumnChartWrapper';

const mapStateToProps = (state) => ({
  // isDarkMode: state.app.isDarkMode,

  retrieveChartPlant7Days: state.chart.retrieveChartPlant7Days,
  retrieveChartPlant10Days: state.chart.retrieveChartPlant10Days,
  retrieveChartPlant30Days: state.chart.retrieveChartPlant30Days,
  retrieveChartPlant12Months: state.chart.retrieveChartPlant12Months,
  // countdown: state.dataViewMode.countdown,
  rotationTime: state.config.rotationTime,
});

export default connect(mapStateToProps, null)(ColumnChartWrapper);
