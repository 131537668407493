import React, { memo, useMemo } from 'react';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Empty, Progress, Spin, Table } from 'antd';
import PropTypes from 'prop-types';

import AppButton from '@/components/app/AppButton';
import AppLink from '@/components/app/AppLink';

import { ReactComponent as ExpandIcon } from '@/assets/icons/expand.svg';
// import AppDropdown from '@/components/app/AppDropdown';
// import ViewModeLabel from '@/components/ViewModeLabel';
import { APP_COLORS, LOCATIONS, USER_ROLES } from '@/constants';
import { t } from '@/languages';
import { classnames, formatNumber, get } from '@/utils';

import styles from './CompaniesTable.module.scss';

const COMPANIES_PER_PAGE = 10;

function CompaniesTable(props) {
  const { getRetrieveCompaniesLoading, retrieveCompanies, userRole } = props;

  const tableWidthByUserRole = useMemo(() => {
    if (userRole === USER_ROLES.ADMIN) {
      return { x: 1136 };
    }

    return { x: 1108 };
  }, [userRole]);

  const EmptyData = useMemo(
    () => ({
      emptyText: (
        <Empty
          className={classnames(styles.Empty, getRetrieveCompaniesLoading && 'opacity-0')}
          description={t('HomePage.form.no_search_result')}
        />
      ),
    }),
    [getRetrieveCompaniesLoading],
  );

  const TABLE_INITIAL = useMemo(
    () => [
      {
        title: t('HomePage.table.name'),
        dataIndex: 'name',
        key: 'name',
        render: (_, item) => (
          <div className="d-flex align-items-center">
            {/* <Image
              src={item.avatar}
              width={40}
              height={40}
              alt={item.name}
              rootClassName={styles.ColAvatar}
            /> */}

            <span className={styles.ColName}>{get(item, 'en.name', item.username)}</span>
          </div>
        ),
      },
      {
        title: t('HomePage.table.information'),
        dataIndex: 'information',
        key: 'information',
        render: (_, item) => (
          <span className={styles.ColInformation}>{get(item, 'en.description', '----')}</span>
        ),
      },
      {
        title: t('HomePage.table.electricityUsage'),
        dataIndex: 'electricityUsage',
        key: 'electricityUsage',
        sorter: (a, b) => a.electricityUsage - b.electricityUsage,
        render: (_, item) => (
          <Progress
            percent={item.electricityUsage}
            trailColor={APP_COLORS.NEUTRAL_3}
            className={styles.ColProgress}
          />
        ),
      },
      // {
      //   title: t('HomePage.table.phone'),
      //   dataIndex: 'phone',
      //   key: 'phone',
      // },
      {
        title: t('HomePage.table.beneficial'),
        dataIndex: 'beneficial',
        key: 'beneficial',
        align: 'right',
        // FIXME: Update EN/VI by language
        render: (_, item) => <span>{formatNumber(get(item, 'beneficial.vnd.amount'))}</span>,
      },
      {
        title: t('HomePage.table.action.title'),
        dataIndex: 'action',
        key: 'action',
        render: (_, item) => (
          <div className="d-flex align-items-center">
            {/* <AppDropdown
              isSmall
              className={styles.ColActionView}
              buttonOptions={itemButtonOptions}
              label={<ViewModeLabel text={t('HomePage.table.action.view')} />}
              items={VIEW_MODE_OPTIONS}
              onClickItem={onSelectItemViewMode}
              callbackArg={item}
              isNotShowIconArrow
            /> */}
            <AppLink to={LOCATIONS.CLIENT_VIEW_MODE(item._id)} ghost>
              <AppButton isSmall className={styles.ColActionView} type="primary" ghost>
                <ExpandIcon className={styles.ColActionViewIcon} />

                <span>{t('HomePage.table.action.view')}</span>
              </AppButton>
            </AppLink>

            {userRole === USER_ROLES.ADMIN && (
              <AppLink to={LOCATIONS.COMPANY_SETTING(item._id)}>
                <AppButton isSmall className={styles.ColActionDetails} ghost>
                  {t('HomePage.table.action.details')}
                </AppButton>
              </AppLink>
            )}
          </div>
        ),
      },
    ],
    [userRole],
  );

  return (
    <div
      className={classnames(
        styles.Table,
        getRetrieveCompaniesLoading && styles.TableLoading,
        !retrieveCompanies.length && styles.TableEmpty,
        retrieveCompanies.length >= COMPANIES_PER_PAGE && styles.TableQualifies,
      )}>
      <Table
        dataSource={retrieveCompanies}
        columns={TABLE_INITIAL}
        sticky
        scroll={tableWidthByUserRole}
        pagination={{ showSizeChanger: false }}
        locale={EmptyData}
        loading={
          getRetrieveCompaniesLoading && {
            indicator: (
              <div className={styles.LoadingWrapper}>
                <div>
                  <Spin
                    className={styles.Loading}
                    indicator={<Loading3QuartersOutlined className={styles.LoadingSpin} />}
                  />
                  <div className={styles.LoadingText}>{t('loading')}</div>
                </div>
              </div>
            ),
          }
        }
        rowKey="_id"
      />
    </div>
  );
}

CompaniesTable.propTypes = {
  getRetrieveCompaniesLoading: PropTypes.bool.isRequired,
  retrieveCompanies: PropTypes.instanceOf(Array).isRequired,

  userRole: PropTypes.string,
};

CompaniesTable.defaultProps = {
  userRole: '',
};

export default memo(CompaniesTable);
