import { cancelled, put, takeLeading } from 'redux-saga/effects';

import dataViewModeAPI from '@/apis/dataViewModeAPI';
import { watchingSagaCanceled } from '@/utils';

import {
  CLEAR_GET_DATA_VIEW_MODE,
  GET_DATA_VIEW_MODE,
  GET_DATA_VIEW_MODE_FAILED,
  GET_DATA_VIEW_MODE_SUCCESS,
} from './actionTypes';

function* actionGetDataViewMode({ payload = {} }) {
  const { values, ref } = payload;
  try {
    const response = yield dataViewModeAPI.getDataViewMode({ values, ref });

    yield put({
      type: GET_DATA_VIEW_MODE_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({ type: GET_DATA_VIEW_MODE_FAILED });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_DATA_VIEW_MODE });
    }
  }
}

export default function* plantSaga() {
  yield takeLeading(GET_DATA_VIEW_MODE, function* getDataViewMode(payload) {
    yield watchingSagaCanceled(payload, actionGetDataViewMode, CLEAR_GET_DATA_VIEW_MODE);
  });
}
