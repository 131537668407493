import {
  REFRESH_TOKEN,
  REFRESH_TOKEN_FAILED,
  REFRESH_TOKEN_SUCCESS,
  SET_TOKEN,
  SIGN_IN,
  SIGN_IN_FAILED,
  SIGN_IN_SUCCESS,
} from './actionTypes';

export const initialState = {
  signInLoading: false,
  signInSuccess: false,
  token: null,

  refreshTokenLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        signInLoading: true,
      };
    }

    case SIGN_IN_SUCCESS: {
      return {
        ...state,
        ...state,
        signInLoading: false,
        signInSuccess: true,
        token: action.payload,
      };
    }

    case SIGN_IN_FAILED: {
      return {
        ...state,
        signInLoading: false,
        signInSuccess: false,
        token: null,
      };
    }

    case REFRESH_TOKEN: {
      return {
        ...state,
        refreshTokenLoading: true,
      };
    }

    case REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        refreshTokenLoading: false,
        signInSuccess: true,
        token: action.payload,
      };
    }

    case REFRESH_TOKEN_FAILED: {
      return {
        ...state,
        refreshTokenLoading: false,
        signInSuccess: false,
        token: null,
      };
    }

    case SET_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
