import { call, cancelled, put, select, takeLeading } from 'redux-saga/effects';

import plantAPI from '@/apis/plantAPI';
import { SET_VIEW_MODE_REFS } from '@/store/dataViewMode/actionTypes';
import { actionGetListAllGroup } from '@/store/group/saga';
import { watchingSagaCanceled } from '@/utils';

import {
  CLEAR_GET_LIST_OF_PLANT,
  GET_LIST_OF_PLANT,
  GET_LIST_OF_PLANT_FAILED,
  GET_LIST_OF_PLANT_SUCCESS,
  GET_PLANT_DETAIL,
  GET_PLANT_DETAIL_FAILED,
  GET_PLANT_DETAIL_SUCCESS,
  GET_PLANTS_OVERVIEW,
  GET_PLANTS_OVERVIEW_FAILED,
  GET_PLANTS_OVERVIEW_SUCCESS,
} from './actionTypes';

function* actionGetPlantsOverview() {
  try {
    const response = yield plantAPI.getPlantsOverview();

    yield put({
      type: GET_PLANTS_OVERVIEW_SUCCESS,
      payload: response.payload,
    });
  } catch (error) {
    yield put({ type: GET_PLANTS_OVERVIEW_FAILED });
  }
}

function* actionGetPlantDetail(action) {
  const { values: ids } = action.payload;

  try {
    const idsAsync = ids.map((id) => plantAPI.getPlantDetail(id));

    const listOfPlant = [];

    yield Promise.allSettled(idsAsync).then((results) =>
      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          listOfPlant.push(result.value.payload);
        }
      }),
    );

    let capacity_dc = 0;

    listOfPlant.forEach((plant) => {
      capacity_dc += plant.capacity_dc / 1000;
    });

    yield put({
      type: GET_PLANT_DETAIL_SUCCESS,
      payload: {
        listOfPlant,
        plants: {
          capacity_dc,
        },
      },
    });
  } catch (error) {
    yield put({ type: GET_PLANT_DETAIL_FAILED });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_LIST_OF_PLANT });
    }
  }
}

function* actionGetListOfPlant() {
  try {
    const response = yield plantAPI.getListOfPlant();

    const refs = [];
    let capacity_dc = 0;

    response.payload.forEach((plant) => {
      refs.push(plant.ref);
      capacity_dc += plant.capacity_dc / 1000;
    });

    if (response.total > 1) {
      yield call(actionGetListAllGroup, {});

      const allGroups = yield select((state) => state.group.allGroups);

      yield put({
        type: SET_VIEW_MODE_REFS,
        payload: allGroups.refs,
      });
    } else {
      yield put({
        type: SET_VIEW_MODE_REFS,
        payload: refs,
      });
    }

    yield put({
      type: GET_LIST_OF_PLANT_SUCCESS,
      payload: {
        listOfPlant: response.payload,
        plants: {
          refs,
          capacity_dc,
        },
      },
    });
  } catch (error) {
    yield put({ type: GET_LIST_OF_PLANT_FAILED });
  } finally {
    if (yield cancelled()) {
      yield put({ type: CLEAR_GET_LIST_OF_PLANT });
    }
  }
}

export default function* plantSaga() {
  yield takeLeading(GET_PLANTS_OVERVIEW, actionGetPlantsOverview);
  yield takeLeading(GET_PLANT_DETAIL, function* getPlantDetail(payload) {
    yield watchingSagaCanceled(payload, actionGetPlantDetail, CLEAR_GET_LIST_OF_PLANT);
  });
  yield takeLeading(GET_LIST_OF_PLANT, function* getListOfPlant(payload) {
    yield watchingSagaCanceled(payload, actionGetListOfPlant, CLEAR_GET_LIST_OF_PLANT);
  });
}
