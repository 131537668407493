import { hexToRgba } from '@/utils';

export default {
  layout: {
    data: {
      xl: { span: 6, order: 1 },
      sm: { span: 12, order: 1 },
    },
    chart: {
      xl: { span: 12, order: 2 },
      sm: { order: 3 },
    },
    map: {
      xl: { span: 6, order: 3 },
      sm: { span: 12, order: 2 },
    },
  },

  availabilityChart: {
    type: 'circle',
    strokeColor: '#05A0F8',
    trailColor: hexToRgba('#05A0F8', 0.2),
    strokeWidth: 6,
    // title: t('ViewMode.chart.availability'),
  },
  performanceChart: {
    type: 'circle',
    strokeColor: '#ECAE2F',
    trailColor: hexToRgba('#ECAE2F', 0.2),
    strokeWidth: 6,
    // title: t('ViewMode.chart.performance'),
  },
  availabilityLiquidChart: {
    // title: t('ViewMode.chart.percentage_usage'),
    strokeColor: '#3AC683',
    fillColor: hexToRgba('#3AC683', 0.85),
    // borderWidth: 10,
  },
};
