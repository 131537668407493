import { put, takeLeading } from 'redux-saga/effects';

import userAPI from '@/apis/userAPI';
import { SET_TOKEN } from '@/store/auth/actionTypes';

import { GET_USER_INFO, GET_USER_INFO_FAILED, GET_USER_INFO_SUCCESS } from './actionTypes';

export function* actionGetUserInfo({ payload = {} }) {
  const { token, values, successCallback, errorCallback, finallyCallback } = payload;

  try {
    const response = yield userAPI.getUserInfo(values);

    yield put({
      type: GET_USER_INFO_SUCCESS,
      payload: response.payload,
    });

    yield put({
      type: SET_TOKEN,
      payload: token,
    });

    successCallback?.();
  } catch (error) {
    yield put({
      type: GET_USER_INFO_FAILED,
    });

    errorCallback?.();
  } finally {
    finallyCallback?.();
  }
}

export default function* authSaga() {
  yield takeLeading(GET_USER_INFO, actionGetUserInfo);
}
