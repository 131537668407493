import { connect } from 'react-redux';

import {
  CLEAR_GET_COMPANY_INFORMATION,
  GET_COMPANY_INFORMATION,
  UPDATE_COMPANY_INFORMATION,
} from '@/store/manager/actionTypes';

import CompanySettingPage from './CompanySettingPage';

const mapStateToProps = (state) => ({
  companyInformation: state.manager.companyInformation,
  companyInformationLoading: state.manager.getCompanyInformationLoading,
  updateCompanyInformationLoading: state.manager.updateCompanyInformationLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actionGetCompanyInformation: (payload) =>
    dispatch({
      type: GET_COMPANY_INFORMATION,
      payload,
    }),
  actionClearGetCompanyInformation: () => dispatch({ type: CLEAR_GET_COMPANY_INFORMATION }),

  actionUpdateCompanyInformation: (payload) =>
    dispatch({
      type: UPDATE_COMPANY_INFORMATION,
      payload,
    }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySettingPage);
