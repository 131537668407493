// GET_SKY_X_INFORMATION
export const GET_SKY_X_INFORMATION = 'GET_SKY_X_INFORMATION';
export const GET_SKY_X_INFORMATION_SUCCESS = 'GET_SKY_X_INFORMATION_SUCCESS';
export const GET_SKY_X_INFORMATION_FAILED = 'GET_SKY_X_INFORMATION_FAILED';
export const CLEAR_GET_SKY_X_INFORMATION = 'CLEAR_GET_SKY_X_INFORMATION';

// UPDATE_SKY_X_INFORMATION
export const UPDATE_SKY_X_INFORMATION = 'UPDATE_SKY_X_INFORMATION';
export const UPDATE_SKY_X_INFORMATION_SUCCESS = 'UPDATE_SKY_X_INFORMATION_SUCCESS';
export const UPDATE_SKY_X_INFORMATION_FAILED = 'UPDATE_SKY_X_INFORMATION_FAILED';
