import {
  CLEAR_RETRIEVE_CHART_PLANT,
  GET_RETRIEVE_CHART_PLANT_7_DAYS,
  GET_RETRIEVE_CHART_PLANT_7_DAYS_FAILED,
  GET_RETRIEVE_CHART_PLANT_7_DAYS_SUCCESS,
  GET_RETRIEVE_CHART_PLANT_10_DAYS,
  GET_RETRIEVE_CHART_PLANT_10_DAYS_FAILED,
  GET_RETRIEVE_CHART_PLANT_10_DAYS_SUCCESS,
  GET_RETRIEVE_CHART_PLANT_12_MONTHS,
  GET_RETRIEVE_CHART_PLANT_12_MONTHS_FAILED,
  GET_RETRIEVE_CHART_PLANT_12_MONTHS_SUCCESS,
  GET_RETRIEVE_CHART_PLANT_30_DAYS,
  GET_RETRIEVE_CHART_PLANT_30_DAYS_FAILED,
  GET_RETRIEVE_CHART_PLANT_30_DAYS_SUCCESS,
} from './actionTypes';

const RETRIEVE_INITIAL_STATE = {
  getRetrieveChartPlant7DaysLoading: true,
  retrieveChartPlant7Days: {},
  getRetrieveChartPlant10DaysLoading: true,
  retrieveChartPlant10Days: {},
  getRetrieveChartPlant30DaysLoading: true,
  retrieveChartPlant30Days: {},
  getRetrieveChartPlant12MonthsLoading: true,
  retrieveChartPlant12Months: {},
};

export const initialState = {
  ...RETRIEVE_INITIAL_STATE,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_RETRIEVE_CHART_PLANT: {
      return {
        ...state,
        ...RETRIEVE_INITIAL_STATE,
      };
    }

    // GET_RETRIEVE_CHART_PLANT_7_DAYS
    case GET_RETRIEVE_CHART_PLANT_7_DAYS:
      return {
        ...state,
        getRetrieveChartPlant7DaysLoading: true,
      };
    case GET_RETRIEVE_CHART_PLANT_7_DAYS_SUCCESS:
      return {
        ...state,
        getRetrieveChartPlant7DaysLoading: false,
        retrieveChartPlant7Days: action.payload,
      };
    case GET_RETRIEVE_CHART_PLANT_7_DAYS_FAILED:
      return {
        ...state,
        getRetrieveChartPlant7DaysLoading: false,
      };

    // GET_RETRIEVE_CHART_PLANT_10_DAYS
    case GET_RETRIEVE_CHART_PLANT_10_DAYS:
      return {
        ...state,
        getRetrieveChartPlant10DaysLoading: true,
      };
    case GET_RETRIEVE_CHART_PLANT_10_DAYS_SUCCESS:
      return {
        ...state,
        getRetrieveChartPlant10DaysLoading: false,
        retrieveChartPlant10Days: action.payload,
      };
    case GET_RETRIEVE_CHART_PLANT_10_DAYS_FAILED:
      return {
        ...state,
        getRetrieveChartPlant10DaysLoading: false,
      };

    // GET_RETRIEVE_CHART_PLANT_30_DAYS
    case GET_RETRIEVE_CHART_PLANT_30_DAYS:
      return {
        ...state,
        getRetrieveChartPlant30DaysLoading: true,
      };
    case GET_RETRIEVE_CHART_PLANT_30_DAYS_SUCCESS:
      return {
        ...state,
        getRetrieveChartPlant30DaysLoading: false,
        retrieveChartPlant30Days: action.payload,
      };
    case GET_RETRIEVE_CHART_PLANT_30_DAYS_FAILED:
      return {
        ...state,
        getRetrieveChartPlant30DaysLoading: false,
      };

    // GET_RETRIEVE_CHART_PLANT_12_MONTHS
    case GET_RETRIEVE_CHART_PLANT_12_MONTHS:
      return {
        ...state,
        getRetrieveChartPlant12MonthsLoading: true,
      };
    case GET_RETRIEVE_CHART_PLANT_12_MONTHS_SUCCESS:
      return {
        ...state,
        getRetrieveChartPlant12MonthsLoading: false,
        retrieveChartPlant12Months: action.payload,
      };
    case GET_RETRIEVE_CHART_PLANT_12_MONTHS_FAILED:
      return {
        ...state,
        getRetrieveChartPlant12MonthsLoading: false,
      };

    default: {
      return state;
    }
  }
};
