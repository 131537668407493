import { connect } from 'react-redux';

import { SIGN_IN } from '@/store/auth/actionTypes';

import FormSignIn from './FormSignIn';

const mapStateToProps = (state) => ({
  signInLoading: state.auth.signInLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actionSignIn: (payload) => dispatch({ type: SIGN_IN, payload }),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSignIn);
