import { connect } from 'react-redux';

import { INIT_APP } from '@/store/app/actionTypes';

import App from './App';

const mapStateToProps = (state) => ({
  userRole: state.user.userRole,

  appLanguage: state.app.appLanguage,
  changeLanguageLoading: state.app.changeLanguageLoading,
});

const mapDispatchToProps = (dispatch) => ({
  actionInitApp: () => dispatch({ type: INIT_APP }),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
