import axiosClient from '@/utils/axios';

const endPoint = '/manager';

export default {
  async getRetrieveCompanyDetail(id) {
    const path = `${endPoint}/company/${id}`;

    const response = await axiosClient.get(path);

    return response.data;
  },

  async getRetrieveCompanies(query) {
    const path = `${endPoint}/company`;

    const response = await axiosClient.get(path, { params: { ...query } });

    return response.data;
  },

  async getCompanyInformation(payload) {
    const path = `${endPoint}/company/${payload}`;

    const response = await axiosClient.get(path);

    return response.data;
  },
  async updateCompanyInformation(payload) {
    const path = `${endPoint}/company/${payload.id}`;

    const response = await axiosClient.put(path, payload);

    return response.data;
  },

  async getClientCompany() {
    const path = `${endPoint}/my-company`;

    const response = await axiosClient.get(path);

    return response.data;
  },

  async updateClientShowSkyXInfo() {
    const path = `${endPoint}/my-company`;

    const response = await axiosClient.put(path);

    return response.data;
  },
};
