/* eslint-disable global-require */
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import { LANGUAGES } from '@/constants';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: require('./en.json'),
  },
  vi: {
    translation: require('./vi.json'),
  },
  kr: {
    translation: require('./kr.json'),
  },
  cn: {
    translation: require('./cn-tw.json'),
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: LANGUAGES.DEFAULT, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

export const t = i18n.t.bind(i18n);
export const changeLanguage = i18n.changeLanguage.bind(i18n);
export const getFixedT = i18n.getFixedT.bind(i18n);
